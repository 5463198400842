import {ADD_HISTORY_STEP} from '../actionTypes/actiontypes';
import {ADD_PATH_STEP} from '../actionTypes/actiontypes';
import {ADD_FILE} from '../actionTypes/actiontypes';

const initialState = {
    history: [],
    path: [],
    files:[]
}

const rootReducer = (state = initialState, action) => {
	switch(action.type){    
        case ADD_HISTORY_STEP:{
            var _history = state.history;
            _history.push(action.payload);
			return {...state,history:[..._history]};
        }
        case ADD_PATH_STEP:{
            var _path = state.path;
            _path.push(action.payload);
            return{...state,path:[..._path]};
        }
        case ADD_FILE:{
            var _files = state.files;
            var _file = {};

            _file.file_uid = action.payload.file_uid;
            _file.file_name = action.payload.file_name;
            _file.file_org = action.payload.file_org;
            _file.file_group = action.payload.file_group;
            _file.file_ui = action.payload.file_ui;
            _file.file_data = action.payload.file_data;
            _file.file_comments = action.payload.file_comments;
            _file.file_tags = action.payload.file_tags;
            _file.file_sku = action.payload.file_sku;
            _file.file_imageUrl = action.payload.file_imageUrl;
            _files.push(_file);
            return{...state,files:[..._files]};
        }
        default: return state;
    }
}

export default rootReducer;
