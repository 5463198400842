import React from 'react';

class Mode extends React.Component {
    render() {
        return (<div className="mode-container">
            {this.props.modes.map((mode,index) => (<div key={index}><span>{mode}</span></div>))}
        </div>)
    }
}

export default Mode;