import React from "react";
import Expander from "../Expander/expander";
import Spinner from "../Spinner/spinner";
//import ExtendedTabs from "../Tabs/tabs";
import Pricer from "../Pricer/pricer";
import Sku from "../Sku/sku";
//import Download from '../Controls/download';
import Controls from "../Controls/controls";
import ZoomAndPan from "../Controls/zoomPan";
import ThreeDRouter from "../3D/routing";
//import HeroImage from '../HeroImage/heroimage';
import HologramViewer from "../HologramViewer/hologramviewer";
import Title from "../Title/title";
import Slides from "../Slides/slides";
import { Information } from "../Information/information";
import Disclaimer from "../Disclaimer/disclaimer";
//import Breadcrumb from "react-bootstrap/Breadcrumb";
//import Reset from "../Controls/reset";
import ModelViewer from "../3D/modelViewer";
import "./slab.css";
//import Zoom from '../Controls/zoom';
// ENVIROMENT VARIABLES
//const ENV = require('../../env');

class Slab extends React.Component {
  loadSlab = (slab) => {
    const data = this.props.data;
    const config = this.props.config;
    const rules = this.props.rules;
    const update = this.props.update;
    const swatches = this.props.swatches;
    const images = this.props.images;
    const product = this.props.product;
    const title = this.props.title;
    const pricingData = this.props.pricingData;
    const sku = this.props.sku;
    const group = this.props.group;
    const onDownload = this.props.onDownload;
    const imageIndex = this.props.imageIndex;
    const mobileDevice = this.props.mobileDevice;
    const isMobile = this.props.isMobile;
    const setImageIndex = this.props.setImageIndex;
    const onZoom = this.props.onZoom;
    const host = this.props.host;
    const zoomed = this.props.zoomed;
    const io = this.props.io;
    const sessionId = this.props.sessionId;
    const librariesLoaded = this.props.librariesLoaded;
    const file = this.props.file;
    const hidePrice = this.props.hidePrice;
    const productObj = this.props.productObj;

    const props = {
      data,
      config,
      rules,
      update,
      swatches,
      images,
      title,
      product,
      pricingData,
      sku,
      group,
      onDownload,
      onZoom,
      host,
      zoomed,
      hidePrice,
      mobileDevice,
      isMobile,
      setImageIndex,
      imageIndex,
      io,
      sessionId,
      librariesLoaded,
      file,
      productObj,
    };

    switch (slab) {
      case "SLAB_PRESENTATION": {
        return SLAB_PRESENTATION(props);
      }
      case "SLAB_000001": {
        return SLAB_000001(props);
      }
      case "SLAB_000002": {
        return SLAB_000002(props);
      }
      case "SLAB_000003": {
        return SLAB_000003(props);
      }
      case "SLAB_000004": {
        return SLAB_000004(props);
      }
      case "SLAB_000005": {
        return SLAB_000005(props);
      }
      case "SLAB_000006": {
        return SLAB_000006(props);
      }
      case "SLAB_000007": {
        return SLAB_000007(props);
      }
      case "SLAB_000008": {
        return SLAB_000008(props);
      }
      case "SLAB_000009": {
        return SLAB_000009(props);
      }
      case "SLAB_000010": {
        return SLAB_000010(props);
      }
      case "SLAB_000011": {
        return SLAB_000011(props);
      }
      case "SLAB_000012": {
        return SLAB_000012(props);
      }
      case "SLAB_000013": {
        return SLAB_000013(props);
      }
      case "SLAB_MOBILE_01": {
        return SLAB_MOBILE_01(props);
      }
      default: {
        break;
      }
    }
  };

  render() {
    return <div>{this.loadSlab(this.props.slab)}</div>;
  }
}

// EVEN COLUMNS, SPINNER LEFT, EXPANDERS RIGHT
// ORGANIZATIONS: NONE --> RESERVED FOR TRADE SHOWS AND PRESENTATIONS
// SLAB_PRESENTATION ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const SLAB_PRESENTATION = (props) => {
  var spinnerCls = props.config.spinnerStickyTop
    ? "col-md-3 slab-spinner sticky-top"
    : "col-md-3 slab-spinner";

  return (
    <div className="row slab-main">
      <div className={spinnerCls}>
        <Spinner
          images={props.images}
          config={props.config}
          zoomed={props.zoomed}
          setImageIndex={props.imageIndex}
          imageIndex={props.imageIndex}
        />
        <Controls
          config={props.config}
          onDownload={props.onDownload}
          onZoom={props.onZoom}
          pricingData={props.pricingData}
          imageIndex={props.imageIndex}
          //sku={props.sku}
          product={props.product}
          images={props.images}
          update={props.update}
        />
      </div>

      <div className="col-md-3 slab-expander">
        <Expander
          config={props.config}
          initialSelections={props.config.initialSelections}
          uiGUID={props.config.guid}
          group={props.group}
          update={props.update}
          swatches={props.swatches}
          data={props.data}
          headerData={
            props.config.expanderShowHeader
              ? props.data.Product.Attrs[0].Val
              : ""
          }
          footerData={props.config.expanderShowFooter ? "" : ""}
        />
      </div>

      <div className="col-md-6 slab-viewer">
        <HologramViewer images={props.images} />
      </div>
    </div>
  );
};

// SLAB_000001 ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// EVEN COLUMNS, SPINNER LEFT, EXPANDERS RIGHT
// ORGANIZATIONS: KI
// SLAB_000001 ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const SLAB_000001 = (props) => {
  // READ ME ************************************************************************************
  // There are (2) disclaimer components because one needs to display on desktop in one column and
  // the other needs to display on mobile on the other column.
  // READ ME ************************************************************************************

  // Handle legacy formats --> this is limited to (1) image, and will not use the same config
  var imageIndex = 0;
  var config = props.config;

  if (props.images.length !== 1) {
    imageIndex = props.imageIndex;
  }
  if (props.images.length === 1) {
    config.controls.dragAndRotate = false;
  }

  return (
    <div className="row">
      <div className="col-md-6 order-md-1">
        <div className="slab-1 w-6">
          {props.config.pricer.show ? (
            <Pricer
              pricingData={props.pricingData}
              config={config}
              hidePrice={props.hidePrice}
            />
          ) : null}
          {/* 
          <Reset
            config={config}
            sessionId={props.sessionId}
            price={props.pricingData}
            sku={props.sku}
            product={props.product}
          /> */}

          <Spinner
            images={props.images}
            config={config}
            zoomed={props.zoomed}
            onZoom={props.onZoom}
            setImageIndex={props.setImageIndex}
            imageIndex={imageIndex}
            product={props.product}
            librariesLoaded={props.librariesLoaded}
          />
          {props.config.threeD.arEnabled ? (
            <ThreeDRouter
              config={config}
              price={props.pricingData}
              host={props.host}
              sku={props.sku}
              group={props.group}
              imageUrl={
                props.images.length > 0 && imageIndex
                  ? props.images[imageIndex].ImageURL
                  : ""
              }
              product={props.product}
              images={props.images}
              productObj={props.productObj}
            />
          ) : null}
          <Controls
            config={config}
            sessionId={props.sessionId}
            onDownload={props.onDownload}
            onZoom={props.onZoom}
            price={props.pricingData}
            host={props.host}
            imageIndex={props.imageIndex}
            sku={props.sku}
            group={props.group}
            imageUrl={
              props.images.length > 0 && imageIndex
                ? props.images[imageIndex].ImageURL
                : ""
            }
            product={props.product}
            images={props.images}
            update={props.update}
          />

          <div className="d-none d-md-block">
            {props.config.information.show ? (
              <div>
                <Information config={config} pricingData={props.pricingData} />
              </div>
            ) : null}
            <Disclaimer disclaimer={props.config.disclaimers.general} />
          </div>
          {props.config.threeD.modelViewerEnabled ? (
            <div className="d-none d-md-block">
              <ModelViewer
                className="model-viewer"
                config={config}
                images={props.images}
              ></ModelViewer>
            </div>
          ) : null}
        </div>
      </div>

      <div className="col-md-6 order-md-2">
        <div className="slab-2 scrollable">
          <Expander
            mobileDevice={props.mobileDevice}
            data={props.data}
            config={config}
            sessionId={props.sessionId}
            rules={props.rules}
            update={props.update}
            group={props.group}
            sku={props.sku}
            price={props.pricingData}
            imageUrl={
              props.images.length > 0 && imageIndex
                ? props.images[imageIndex].ImageURL
                : ""
            }
            io={props.io}
            file={props.file}
          />

          <div className="d-md-none">
            {props.config.information.show ? (
              <div>
                <Information config={config} pricingData={props.pricingData} />
              </div>
            ) : null}
            <Disclaimer disclaimer={props.config.disclaimers.general} />
          </div>
        </div>
      </div>
    </div>
  );
};

// SLAB_000002 ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// EVEN COLUMNS, SPINNER RIGHT, EXPANDERS LEFT
// SLAB_000002 ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const SLAB_000002 = (props) => {
  var spinnerCls = props.config.spinnerStickyTop
    ? "col-md-6 slab-spinner sticky-top"
    : "col-md-6 slab-spinner";

  return (
    <div className="row slab-main">
      <div className="col-md-6 slab-expander">
        <Expander
          config={props.config}
          initialSelections={props.config.initialSelections}
          uiGUID={props.config.guid}
          group={props.group}
          update={props.update}
          swatches={props.swatches}
          data={props.data}
          headerData={
            props.config.expanderShowHeader
              ? props.data.Product.Attrs[0].Val
              : ""
          }
          footerData={props.config.expanderShowFooter ? "" : ""}
        />
      </div>

      <div className={spinnerCls}>
        {props.config.showPricer ? (
          <Pricer
            pricingData={props.pricingData}
            config={props.config}
            hidePrice={props.hidePrice}
          />
        ) : null}
        <Spinner
          images={props.images}
          config={props.config}
          zoomed={props.zoomed}
          setImageIndex={props.setImageIndex}
          imageIndex={props.imageIndex}
        />
      </div>
    </div>
  );
};

// SLAB_000003 ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Basic layout, narrow expander column right spinner left expanders
// SLAB_000003 ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const SLAB_000003 = (props) => {
  var spinnerCls = props.config.spinnerStickyTop
    ? "col-md-8 slab-spinner sticky-top"
    : "col-md-8 slab-spinner";

  return (
    <div className="row slab-main">
      <div className="col-md-4 slab-expander">
        <Expander
          config={props.config}
          initialSelections={props.config.initialSelections}
          uiGUID={props.config.guid}
          group={props.group}
          update={props.update}
          swatches={props.swatches}
          data={props.data}
          headerData={
            props.config.expanderShowHeader
              ? props.data.Product.Attrs[0].Val
              : ""
          }
          footerData={props.config.expanderShowFooter ? "" : ""}
        />
      </div>

      <div className={spinnerCls}>
        {props.config.showPricer ? (
          <Pricer
            pricingData={props.pricingData}
            config={props.config}
            hidePrice={props.hidePrice}
          />
        ) : null}
        <Spinner
          images={props.images}
          config={props.config}
          zoomed={props.zoomed}
          setImageIndex={props.setImageIndex}
          imageIndex={props.imageIndex}
        />
      </div>
    </div>
  );
};

// SLAB_000004 ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Basic layout, narrow expander column left spinner right expanders
// SLAB_000004 ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const SLAB_000004 = (props) => {
  var spinnerCls = props.config.spinnerStickyTop
    ? "col-md-8 slab-spinner sticky-top"
    : "col-md-8 slab-spinner";

  return (
    <div className="row slab-main">
      <div className={spinnerCls}>
        {props.config.showPricer ? (
          <Pricer
            pricingData={props.pricingData}
            config={props.config}
            hidePrice={props.hidePrice}
          />
        ) : null}
        <Spinner
          images={props.images}
          config={props.config}
          zoomed={props.zoomed}
          setImageIndex={props.setImageIndex}
          imageIndex={props.imageIndex}
        />
      </div>

      <div className="col-md-4 slab-expander">
        <Expander
          config={props.config}
          initialSelections={props.config.initialSelections}
          uiGUID={props.config.guid}
          group={props.group}
          update={props.update}
          swatches={props.swatches}
          data={props.data}
          headerData={
            props.config.expanderShowHeader
              ? props.data.Product.Attrs[0].Val
              : ""
          }
          footerData={props.config.expanderShowFooter ? "" : ""}
        />
      </div>
    </div>
  );
};

// SLAB_000005 ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// NARROW EXPANDERS LEFT, SPINNER RIGHT, TABS, PRICER, HEADER
// ORGANIZATIONS: NATIONAL
// SLAB_000005 ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const SLAB_000005 = (props) => {
  //let entry = "/Merchandiser?id=" + props.config.cfgId;

  // READ ME ************************************************************************************
  // There are (2) disclaimer components because one needs to display on desktop in one column and
  // the other needs to display on mobile on the other column.
  // READ ME ************************************************************************************

  // Handle legacy formats --> this is limited to (1) image, and will not use the same config
  var imageIndex = 0;
  var config = props.config;
  var type;
  var header;

  if (props.images.length !== 1) {
    imageIndex = props.imageIndex;
  }
  if (props.images.length === 1) {
    config.controls.dragAndRotate = false;
  }

  //National
  // return (
  //   <div className="row">
  //     <div className="col-md-7 order-md-2">
  //       <div className="slab-1 w-7">
  //         {/*    <ExtendedTabs {...props} /> */}

  //         {props.config.pricer.show ? (
  //           <Pricer pricingData={props.pricingData} config={props.config} />
  //         ) : (
  //           ""
  //         )}
  //         {/*
  //         <Reset
  //           config={config}
  //           sessionId={props.sessionId}
  //           price={props.pricingData}
  //           sku={props.sku}
  //           product={props.product}
  //         /> */}

  //         <Spinner
  //           images={props.images}
  //           config={config}
  //           zoomed={props.zoomed}
  //           onZoom={props.onZoom}
  //           setImageIndex={props.setImageIndex}
  //           imageIndex={imageIndex}
  //           product={props.product}
  //           librariesLoaded={props.librariesLoaded}
  //         />

  //         <Controls
  //           config={config}
  //           sessionId={props.sessionId}
  //           onDownload={props.onDownload}
  //           onZoom={props.onZoom}
  //           price={props.pricingData}
  //           host={props.host}
  //           imageIndex={props.imageIndex}
  //           sku={props.sku}
  //           group={props.group}
  //           imageUrl={
  //             props.images.length > 0 && imageIndex
  //               ? props.images[imageIndex].ImageURL
  //               : ""
  //           }
  //           product={props.product}
  //           images={props.images}
  //           update={props.update}
  //         />
  //       </div>
  //     </div>

  //     <div className="col-md-5 order-md-1">
  //       <div className="slab-2">
  //         {/*           <Breadcrumb>
  //           <Breadcrumb.Item href="#">Personalize</Breadcrumb.Item>
  //           <Breadcrumb.Item href={entry}>Select a New Product</Breadcrumb.Item>
  //         </Breadcrumb> */}

  //         <Expander
  //           mobileDevice={props.mobileDevice}
  //           data={props.data}
  //           config={config}
  //           sessionId={props.sessionId}
  //           rules={props.rules}
  //           update={props.update}
  //           group={props.group}
  //           sku={props.sku}
  //           price={props.pricingData}
  //           imageUrl={
  //             props.images.length > 0 && imageIndex
  //               ? props.images[imageIndex].ImageURL
  //               : ""
  //           }
  //           io={props.io}
  //           file={props.file}
  //         />

  //         <div>
  //           {props.config.information.show ? (
  //             <div>
  //               <Information config={config} pricingData={props.pricingData} />
  //             </div>
  //           ) : null}
  //         </div>

  //         <div className="disclaimer">{props.config.disclaimers.expander}</div>
  //       </div>
  //     </div>
  //   </div>
  // );

  //<div className={"options order-md-1 order-sm-2 order-2 col-md-4 "}>
  //<div className={"images order-md-2 order-sm-1 order-1 col-md-8 "}>

  //MED Demo
  return (
    <div>
      <div className="row">
        <div className="demo-header">
          <img
            className="img-fluid"
            src="https://uibuilderqa.blob.core.windows.net/2caeee27-b5fa-4d1d-a795-68fb0da6c3e4/assets/popheader.PNG"
            width="980"
            height="177"
            alt=""
          />
        </div>
      </div>
      <div className="row demo-content">
        <div className={"options order-md-1 order-sm-2 order-2 col-md-3 "}>
          <h1 className={"blue"}>{header}</h1>

          <Expander
            mobileDevice={props.mobileDevice}
            data={props.data}
            config={props.config}
            rules={props.rules}
            update={props.update}
            group={props.group}
            io={props.io}
            sku={props.sku}
            price={props.pricingData}
            imageUrl={
              props.images.length > 0 && props.imageIndex
                ? props.images[props.imageIndex].ImageURL
                : ""
            }
            file={props.file}
          />

          <Information config={config} />
        </div>

        <div className={"images order-md-2 order-sm-1 order-1 col-md-6 "}>
          {type}

          {props.config.pricer.show ? (
            <Pricer pricingData={props.pricingData} config={props.config} />
          ) : (
            ""
          )}

          <Spinner
            images={props.images}
            config={config}
            zoomed={props.zoomed}
            onZoom={props.onZoom}
            setImageIndex={props.setImageIndex}
            imageIndex={imageIndex}
            product={props.product}
            librariesLoaded={props.librariesLoaded}
          />

          <Controls
            config={props.config}
            onDownload={props.onDownload}
            onZoom={props.onZoom}
            price={props.pricingData}
            imageIndex={props.imageIndex}
            sku={props.sku}
            group={props.group}
            host={props.host}
            imageUrl={
              props.images.length > 0 && props.imageIndex
                ? props.images[props.imageIndex].ImageURL
                : ""
            }
            product={props.product}
            images={props.images}
            update={props.update}
          />
        </div>

        <div className={"demo-cart order-md-3 order-sm-3 order-3 col-md-3 "}>
          <img
            className="img-fluid"
            src="https://uibuilderqa.blob.core.windows.net/2caeee27-b5fa-4d1d-a795-68fb0da6c3e4/assets/popcart.png"
            width="1263"
            height="373"
            alt=""
          />
        </div>
      </div>
      <div className="row">
        <div className="demo-details">
          <img
            className="img-fluid"
            src="https://uibuilderqa.blob.core.windows.net/2caeee27-b5fa-4d1d-a795-68fb0da6c3e4/assets/popdetails.png"
            width="1263"
            height="373"
            alt=""
          />
        </div>

        <div className="demo-footer">
          <img
            className="img-fluid"
            src="https://uibuilderqa.blob.core.windows.net/2caeee27-b5fa-4d1d-a795-68fb0da6c3e4/assets/popfooter.PNG"
            width="1263"
            height="373"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

// SLAB_000006 ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// BIASED COLUMNS, SPINNER LEFT, EXPANDERS RIGHT
// ORGANIZATIONS: HON
// SLAB_000006 ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const SLAB_000006 = (props) => {
  // READ ME ************************************************************************************
  // There are (2) disclaimer components because one needs to display on desktop in one column and
  // the other needs to display on mobile on the other column.
  // READ ME ************************************************************************************

  // Handle legacy formats --> this is limited to (1) image, and will not use the same config
  var imageIndex = 0;
  var config = props.config;

  if (props.images.length !== 1) {
    imageIndex = props.imageIndex;
  }
  if (props.images.length === 1) {
    config.controls.dragAndRotate = false;
  }
  return (
    <div className="row">
      <div className="col-md-7 order-md-1">
        <div className="d-md-none row text-center swipe-box">
          <span>Swipe up to show product options.</span>
        </div>

        <div className="slab-1 w-7">
          <Title title={props.title} />
          {/*           <Reset
            config={config}
            sessionId={props.sessionId}
            price={props.pricingData}
            sku={props.sku}
            product={props.product}
          /> */}
          <Spinner
            images={props.images}
            config={config}
            zoomed={props.zoomed}
            onZoom={props.onZoom}
            setImageIndex={props.setImageIndex}
            imageIndex={imageIndex}
            product={props.product}
            librariesLoaded={props.librariesLoaded}
            framecount={props.data[0].grouping_framecount}
          />
          {props.config.threeD.arEnabled ? (
            <ThreeDRouter
              config={config}
              price={props.pricingData}
              host={props.host}
              sku={props.sku}
              group={props.group}
              imageUrl={
                props.images.length > 0 && imageIndex
                  ? props.images[imageIndex].ImageURL
                  : ""
              }
              product={props.product}
              images={props.images}
              productObj={props.productObj}
            />
          ) : null}
          <Controls
            config={config}
            sessionId={props.sessionId}
            onDownload={props.onDownload}
            onZoom={props.onZoom}
            price={props.pricingData}
            host={props.host}
            imageIndex={props.imageIndex}
            sku={props.sku}
            group={props.group}
            imageUrl={
              props.images.length > 0 && imageIndex
                ? props.images[imageIndex].ImageURL
                : ""
            }
            product={props.product}
            images={props.images}
            update={props.update}
          />
          <div className="d-none d-md-block">
            {props.config.pricer.show ? (
              <Pricer pricingData={props.pricingData} config={props.config} />
            ) : (
              ""
            )}
            {props.config.information.show ? (
              <Information config={props.config} />
            ) : null}
          </div>
          {}{" "}
          {props.config.threeD.modelViewerEnabled ? (
            <div className="d-none d-md-block">
              <ModelViewer
                className="model-viewer"
                config={config}
                images={props.images}
              ></ModelViewer>
            </div>
          ) : null}
        </div>
      </div>

      <div className="col-md-5 order-md-2">
        <div className="slab-2 scrollable">
          <Expander
            mobileDevice={props.mobileDevice}
            data={props.data}
            config={config}
            sessionId={props.sessionId}
            rules={props.rules}
            update={props.update}
            group={props.group}
            sku={props.sku}
            price={props.pricingData}
            imageUrl={
              props.images.length > 0 && imageIndex
                ? props.images[imageIndex].ImageURL
                : ""
            }
            io={props.io}
            file={props.file}
          />

          <div className="d-md-none">
            {props.config.pricer.show ? (
              <Pricer
                pricingData={props.pricingData}
                config={props.config}
                hidePrice={props.hidePrice}
              />
            ) : (
              ""
            )}
            {props.config.information.show ? (
              <Information config={props.config} />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

// SLAB_000007 ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// EVEN COLUMNS, SPINNER LEFT, EXPANDERS RIGHT
// ORGANIZATIONS: KIMBALL OFFICE
// SLAB_000001 ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const SLAB_000007 = (props) => {
  // READ ME ************************************************************************************
  // There are (2) disclaimer components because one needs to display on desktop in one column and
  // the other needs to display on mobile on the other column.
  // READ ME ************************************************************************************

  // Handle legacy formats --> this is limited to (1) image, and will not use the same config
  var imageIndex = 0;
  var config = props.config;

  if (props.images.length !== 1) {
    imageIndex = props.imageIndex;
  }
  if (props.images.length === 1) {
    config.controls.dragAndRotate = false;
  }

  return (
    <div className="row">
      <div className="col-md-8 order-md-1">
        <div className="d-md-none row text-center swipe-box">
          <span>Swipe up to show product options.</span>
        </div>

        <div className="slab-1 w-8">
          <Spinner
            images={props.images}
            config={config}
            zoomed={props.zoomed}
            onZoom={props.onZoom}
            setImageIndex={props.setImageIndex}
            imageIndex={imageIndex}
            product={props.product}
            librariesLoaded={props.librariesLoaded}
          />
        </div>
      </div>

      <div className="col-md-4 order-md-2">
        <div className="slab-2 scrollable">
          {props.config.pricer.show ? (
            <Pricer
              pricingData={props.pricingData}
              config={props.config}
              hidePrice={props.hidePrice}
            />
          ) : (
            ""
          )}

          <Controls
            config={config}
            sessionId={props.sessionId}
            onDownload={props.onDownload}
            onZoom={props.onZoom}
            price={props.pricingData}
            host={props.host}
            imageIndex={props.imageIndex}
            sku={props.sku}
            group={props.group}
            imageUrl={
              props.images.length > 0 && imageIndex
                ? props.images[imageIndex].ImageURL
                : ""
            }
            product={props.product}
            images={props.images}
            update={props.update}
          />

          <Expander
            mobileDevice={props.mobileDevice}
            data={props.data}
            sessionId={props.sessionId}
            config={config}
            rules={props.rules}
            update={props.update}
            group={props.group}
            sku={props.sku}
            price={props.pricingData}
            imageUrl={
              props.images.length > 0 && imageIndex
                ? props.images[imageIndex].ImageURL
                : ""
            }
            io={props.io}
            file={props.file}
          />
        </div>
      </div>
    </div>
  );
};

// SLAB_000008 ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// BOTTOM EXPANDERS, FRAME CONTROL RIGHT
// ORGANIZATIONS: CHRIS CRAFT
// SLAB_000001 ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const SLAB_000008 = (props) => {
  var spinnerCls = props.config.spinnerStickyTop
    ? "col-md-9 slab-spinner sticky-top"
    : "col-md-9 slab-spinner";

  return (
    <div className="container slab-main">
      <div className="row">
        <div className="col-md-12 slab-expander">
          <Expander
            config={props.config}
            isCollapsible={true}
            initialSelections={props.config.initialSelections}
            uiGUID={props.config.guid}
            group={props.group}
            update={props.update}
            swatches={props.swatches}
            data={props.data}
            headerData={
              props.config.expanderShowHeader
                ? props.data.Product.Attrs[0].Val
                : ""
            }
            footerData={props.config.expanderShowFooter ? "" : ""}
          />
          ƒ
        </div>
      </div>

      <div className="row">
        <div className={spinnerCls}>
          <Slides images={props.images} />
        </div>

        <div className="col-md-3">
          {props.config.showInformation ? (
            <Information config={props.config} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

// SLAB_000009 ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// BIASED COLUMNS, SPINNER LEFT, EXPANDERS RIGHT
// ORGANIZATIONS: TBPM
// SLAB_000006 ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const SLAB_000009 = (props) => {
  var spinnerCls = props.config.spinnerStickyTop
    ? "col-md-7 slab-spinner sticky-top"
    : "col-md-7 slab-spinner";

  return (
    <div>
      <div className="row slab-header">
        <div className="col-md-12 branding"></div>
      </div>
      <div className="row slab-main">
        <div className="col-md-5 slab-expander">
          <Expander
            data={props.data}
            config={props.config}
            update={props.update}
            group={props.group}
            sku={props.sku}
            imageUrl={
              props.images.length > 0
                ? props.images[props.imageIndex].ImageURL
                : ""
            }
            io={props.io}
            file={props.file}
          />
        </div>
        <div className={spinnerCls}>
          {props.config.showInformation ? (
            <Information config={props.config} />
          ) : null}
          <Spinner
            images={props.images}
            config={props.config}
            zoomed={props.zoomed}
            setImageIndex={props.setImageIndex}
            imageIndex={props.imageIndex}
          />
          <Controls
            config={props.config}
            onDownload={props.onDownload}
            onZoom={props.onZoom}
            price={props.pricingData}
            imageIndex={props.imageIndex}
            sku={props.sku}
            product={props.product}
            images={props.images}
            update={props.update}
          />
          <Title title={props.title} />
          {props.config.showSku ? <Sku sku={props.sku} /> : null}
          {props.config.showPricer ? (
            <Pricer
              pricingData={props.pricingData}
              config={props.config}
              hidePrice={props.hidePrice}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

// SLAB_000010 ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// BIASED COLUMNS, SPINNER LEFT, EXPANDERS RIGHT
// ORGANIZATIONS: ALLSTEEL
// SLAB_000010 ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const SLAB_000010 = (props) => {
  return (
    <div>
      <div className="row slab-header"></div>
      <div className="row slab-main no-gutters">
        <div className="col-md-4 order-1">
          <Title title={props.title} />
          <Expander
            data={props.data}
            config={props.config}
            update={props.update}
            group={props.group}
            sku={props.sku}
            imageUrl={
              props.images.length > 0
                ? props.images[props.imageIndex].ImageURL
                : ""
            }
            io={props.io}
            file={props.file}
          />
        </div>
        <div className={"col-md-8 order-md-1 p-4"}>
          {
            <Spinner
              images={props.images}
              config={props.config}
              zoomed={props.zoomed}
              setImageIndex={props.setImageIndex}
              imageIndex={props.imageIndex}
            />
          }
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {props.config.showSku ? <Sku sku={props.sku} /> : null}
            <ZoomAndPan onZoom={props.onZoom} />
          </div>
          <div className="disclaimer">{props.config.disclaimer}</div>
          <div className="d-none d-xl-block d-lg-block d-md-block">
            <Slides
              images={props.images}
              config={props.config}
              setImageIndex={props.setImageIndex}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// SLAB_000006 ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// BIASED COLUMNS, SPINNER LEFT, EXPANDERS RIGHT
// ORGANIZATIONS: SEDIA, HUMANSCALE
// SLAB_000011 ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const SLAB_000011 = (props) => {
  // READ ME ************************************************************************************
  // There are (2) disclaimer components because one needs to display on desktop in one column and
  // the other needs to display on mobile on the other column.
  // READ ME ************************************************************************************

  // Handle legacy formats --> this is limited to (1) image, and will not use the same config
  var imageIndex = 0;
  var config = props.config;

  if (props.images.length !== 1) {
    imageIndex = props.imageIndex;
  }
  if (props.images.length === 1) {
    config.controls.dragAndRotate = false;
  }

  return (
    <div className="row">
      <div className="col-md-8 order-md-1">
        <div className="d-md-none row text-center swipe-box">
          <span>Swipe up to show product options.</span>
        </div>

        <div className="slab-1 w-8">
          {/*           <Reset
            config={config}
            sessionId={props.sessionId}
            price={props.pricingData}
            sku={props.sku}
            product={props.product}
          /> */}

          <Spinner
            images={props.images}
            config={config}
            zoomed={props.zoomed}
            onZoom={props.onZoom}
            setImageIndex={props.setImageIndex}
            imageIndex={imageIndex}
            product={props.product}
            librariesLoaded={props.librariesLoaded}
          />
        </div>
      </div>

      <div className="col-md-4 order-md-2 scrollable">
        <div className="slab-2">
          <Title title={props.title + " Configurator"} />

          <Controls
            config={config}
            onDownload={props.onDownload}
            onZoom={props.onZoom}
            price={props.pricingData}
            host={props.host}
            imageIndex={props.imageIndex}
            sku={props.sku}
            group={props.group}
            imageUrl={
              props.images.length > 0 && imageIndex
                ? props.images[imageIndex].ImageURL
                : ""
            }
            product={props.product}
            images={props.images}
            update={props.update}
          />

          <Expander
            mobileDevice={props.mobileDevice}
            data={props.data}
            config={config}
            rules={props.rules}
            update={props.update}
            group={props.group}
            sku={props.sku}
            price={props.pricingData}
            imageUrl={
              props.images.length > 0 && imageIndex
                ? props.images[imageIndex].ImageURL
                : ""
            }
            io={props.io}
            file={props.file}
          />
        </div>
      </div>
    </div>
  );
};

// SLAB_000006 ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// BIASED COLUMNS, SPINNER LEFT, EXPANDERS RIGHT
// ORGANIZATIONS: FLEXSTEEL
// SLAB_000012 ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const SLAB_000012 = (props) => {
  return (
    <div className="row">
      <div className={"col-md-8"}>
        <div className="d-lg-none row text-center swipe-box">
          <span>Swipe up to show product options.</span>
        </div>

        <Spinner
          images={props.images}
          config={props.config}
          zoomed={props.zoomed}
          onZoom={props.onZoom}
          setImageIndex={props.setImageIndex}
          imageIndex={props.imageIndex}
          product={props.product}
          librariesLoaded={props.librariesLoaded}
        />

        <Controls
          config={props.config}
          onDownload={props.onDownload}
          onZoom={props.onZoom}
          price={props.pricingData}
          imageIndex={props.imageIndex}
          sku={props.sku}
          group={props.group}
          host={props.host}
          imageUrl={
            props.images.length > 0 && props.imageIndex
              ? props.images[props.imageIndex].ImageURL
              : ""
          }
          product={props.product}
          images={props.images}
          update={props.update}
        />
      </div>

      <div className={"col-md-4"}>
        <div className="p-3">
          <img
            width="200"
            src={
              window.TBPM.BLOB_URL +
              "/" +
              props.config.cfgId +
              "/assets/logo.png"
            }
            alt=""
          />
        </div>
        <Expander
          mobileDevice={props.mobileDevice}
          data={props.data}
          config={props.config}
          rules={props.rules}
          update={props.update}
          group={props.group}
          sku={props.sku}
          price={props.pricingData}
          imageUrl={
            props.images.length > 0 && props.imageIndex
              ? props.images[props.imageIndex].ImageURL
              : ""
          }
          io={props.admin}
          file={props.file}
        />
      </div>
    </div>
  );
};

const SLAB_000013 = (props) => {
  return (
    <div className="row">
      <div className={"col-md-8"}>
        <Slides
          images={props.images}
          setImageIndex={props.setImageIndex}
          imageIndex={props.imageIndex}
        />

        <Controls
          config={props.config}
          onDownload={props.onDownload}
          onZoom={props.onZoom}
          price={props.pricingData}
          imageIndex={props.imageIndex}
          sku={props.sku}
          group={props.group}
          host={props.host}
          imageUrl={
            props.images.length > 0 && props.imageIndex
              ? props.images[props.imageIndex].ImageURL
              : ""
          }
          product={props.product}
          images={props.images}
          update={props.update}
        />
      </div>

      <div className={"col-md-4"}>
        <Expander
          mobileDevice={props.mobileDevice}
          data={props.data}
          config={props.config}
          rules={props.rules}
          update={props.update}
          group={props.group}
          io={props.io}
          sku={props.sku}
          price={props.pricingData}
          imageUrl={
            props.images.length > 0 && props.imageIndex
              ? props.images[props.imageIndex].ImageURL
              : ""
          }
          file={props.file}
        />
      </div>
    </div>
  );
};

// SLAB_MOBILE_01 ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// ONE COLUMN
// PRICING ON TOP OF SPINNER
// ORGANIZATIONS: KI
const SLAB_MOBILE_01 = (props) => {
  // READ ME ************************************************************************************
  // There are (2) disclaimer components because one needs to display on desktop in one column and
  // the other needs to display on mobile on the other column.
  // READ ME ************************************************************************************

  // Handle legacy formats --> this is limited to (1) image, and will not use the same config
  var imageIndex = 0;
  var config = props.config;

  if (props.images.length !== 1) {
    imageIndex = props.imageIndex;
  }
  if (props.images.length === 1) {
    config.controls.dragAndRotate = false;
  }

  return (
    <div>
      {props.config.mobile.showTitle ? <Title title={props.title} /> : null}

      {props.config.pricer.show ? (
        <Pricer
          pricingData={props.pricingData}
          config={config}
          hidePrice={props.hidePrice}
        />
      ) : null}

      {/*       <Reset
        config={config}
        sessionId={props.sessionId}
        price={props.pricingData}
        sku={props.sku}
        product={props.product}
      /> */}

      <Spinner
        images={props.images}
        config={config}
        zoomed={props.zoomed}
        onZoom={props.onZoom}
        setImageIndex={props.setImageIndex}
        imageIndex={imageIndex}
        product={props.product}
        librariesLoaded={props.librariesLoaded}
      />

      <Controls
        config={config}
        sessionId={props.sessionId}
        onDownload={props.onDownload}
        onZoom={props.onZoom}
        price={props.pricingData}
        host={props.host}
        imageIndex={props.imageIndex}
        sku={props.sku}
        group={props.group}
        imageUrl={
          props.images.length > 0 && imageIndex
            ? props.images[imageIndex].ImageURL
            : ""
        }
        product={props.product}
        images={props.images}
        update={props.update}
      />

      <Expander
        mobileDevice={props.mobileDevice}
        data={props.data}
        config={config}
        sessionId={props.sessionId}
        rules={props.rules}
        update={props.update}
        group={props.group}
        sku={props.sku}
        price={props.pricingData}
        imageUrl={
          props.images.length > 0 && imageIndex
            ? props.images[imageIndex].ImageURL
            : ""
        }
        io={props.io}
        file={props.file}
      />

      {props.config.information.show ? (
        <div>
          <Information config={config} pricingData={props.pricingData} />
        </div>
      ) : null}

      <Disclaimer disclaimer={props.config.disclaimers.general} />
    </div>
  );
};

export default Slab;
