import React, { Component } from "react";
import ls from "local-storage";
//import Header from './header';
import Main from "./main";
//import Footer from './footer';
import "./style.css";
import "font-awesome/css/font-awesome.min.css";
// IE SUPPORT
import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import { polyfill } from "es6-promise";
import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";

polyfill();
const { v1: uuidv1 } = require("uuid");

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShow: false,
      height: 0,
    };

    this.Element = React.createRef();

    // set ID to track unique configurator session
    //this.SessionId = uuidv1();
    ls.set("tbpmCfgSessionId", uuidv1());
  }

  componentDidMount = () => {
    // record the new dimension and update the host
    window.addEventListener("resize", this.updateWindowDimensions.bind(this));
    // test every 250ms until the window has been properly resized.
    this.Ping = setInterval(this.updateWindowDimensions.bind(this), 1000);
  };

  updateWindowDimensions = () => {
    this.setState(
      {
        height: this.Element.current.offsetHeight,
      },
      () => {
        let data = {};
        data.height = this.state.height;

        // catch irregular heights --> don't return back to the parent.
        if (data.height > 2000) return;

        window.parent.postMessage(data, "*");
        // stop the interval -> size has been updated, no need to keep testing
        //clearInterval(this.Ping);
      }
    );
  };

  render() {
    return (
      <div className="container-fluid" ref={this.Element}>
        <Main sessionId={this.SessionId} />
        <NotificationContainer />
      </div>
    );
  }
}

export default App;
