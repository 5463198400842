import React from "react";
import MachineCoreAPI from "../MachineCore/machinecoreAPI";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  FacebookShareButton,
  TwitterShareButton,
  PinterestShareButton,
  FacebookIcon,
  TwitterIcon,
  PinterestIcon,
} from "react-share";
import axios from "axios";
import Analytics from "../../classes/analytics";
import ls from "local-storage";
import { NotificationManager } from "react-notifications";

const { v1: uuidv1 } = require("uuid");

const FACEBOOK = "facebook";
const TWITTER = "twitter";
const PINTEREST = "pinterest";
const EMAIL = "email";
const LINK = "link";

class Share extends React.Component {
  constructor(props) {
    super(props);
    this.shareUrl = null;
    this.mailTo = null;
    this.initSharing = this.initSharing.bind(this);
    this.handleLinkCopy = this.handleLinkCopy.bind(this);
    this.MCORE = new MachineCoreAPI();
  }

  handleLinkCopy = () => {
    this.logLinkShare();
    NotificationManager.success("Product Link Copied to Clipboard!", "", 2000);
  };

  componentDidMount = () => {
    this.initSharing();
  };

  initSharing = () => {
    //  formulate and save the share URL and formulate the mailTo link
    const fileData = ls.get("tbpmFileData");
    var headers = {
      "Content-Type": "application/json",
    };
    axios.post(window.TBPM.UIB_API_URL + "/share/save", fileData).then(() => {
      this.shareUrl =
        this.props.host +
        "?file=/group/" +
        fileData.file_ui +
        "/" +
        fileData.file_group +
        "/share/" +
        fileData.file_uid;
      var req = {};
      req.Product = this.props.product;
      req.URL = this.shareUrl;
      req.Image = this.props.image;
      req.guid = this.props.config.cfgId;
      req.cfgId = this.props.config.cfgId;
      axios
        .post(
          window.TBPM.UIB_API_URL + "/emailtemplate/share/",
          JSON.stringify(req),
          {
            headers: headers,
          }
        )
        .then((response) => {
          let subject = encodeURI(response.data.subject);
          let body = response.data.body;
          var mailTo = "mailto:?subject=" + subject + "&body=" + body;
          this.mailTo = mailTo;
          return null;
        });
    });
  };

  /* initEmail = () => {
    // var data = store.getState().files.splice(-1)[0];
    var req = {};
    req.Product = this.props.product;
    req.URL = this.props.host;
    req.Image = this.props.image;
    req.guid = this.props.config.guid;
    var headers = {
      "Content-Type": "application/json",
    };
    axios
      .post(
        window.TBPM.UIB_API_URL + "/emailtemplate/share/",
        JSON.stringify(req),
        {
          headers: headers,
        }
      )
      .then((response) => {
        if (this.shareUrl) {
          let subject = encodeURI(response.data.subject);
          let body =
            "<p>" +
            response.data.body +
            "</p><p>" +
            encodeURI(this.shareUrl) +
            "</p>";
          var mailTo = "mailto:?subject=" + subject + "&body=" + body;
          return mailTo;
        }
      });
  };
 */
  /*   buildEmail = () => {
    const path = store.getState().path.slice(-1)[0];
    var req = {};
    req.Product = this.props.product;
    req.URL = this.props.host;
    req.Image = this.props.image;
    req.guid = this.props.config.guid;

    var headers = {
      "Content-Type": "application/json",
    };

    path.forEach((p) => {
      if (p instanceof Root) {
        req.orgId = p.OrgId;
        req.cfgId = p.CfgId;

        // tell the parent to close the submenu
        var data = store.getState().files.splice(-1)[0];
        //data.product = this.props.product;
        //axios.post(ENV.service.path + '/share/save', data)
        axios.post(window.TBPM.UIB_API_URL + "/share/save", data).then(() => {
          // once the data has been written, send the email to the client so they
          // can retrieve it.
          //axios.post(ENV.service.path + '/emailtemplate/share/', JSON.stringify(req), {
          axios
            .post(
              window.TBPM.UIB_API_URL + "/emailtemplate/share/",
              JSON.stringify(req),
              {
                headers: headers,
              }
            )
            .then((response) => {
              if (data) {
                this.setState({
                  subject: response.data.subject,
                  body:
                    response.data.body +
                    "?file=/group/" +
                    data.file_ui +
                    "/" +
                    data.file_group +
                    "/share/" +
                    data.file_uid,
                  url:
                    req.URL +
                    "?file=/group/" +
                    data.file_ui +
                    "/" +
                    data.file_group +
                    "/share/" +
                    data.file_uid,
                });
                //Woopra
                try {
                  //loop through skus and combining them with comma delim
                  let sku = "";

                  this.props.sku.forEach((s) => {
                    if (sku === "") sku = s;
                    else sku += "," + s;
                  });

                  this.woopra
                    .identify("shareTracker")
                    .track("configurator-sharebutton", {
                      sku,
                    });
                } catch {}
              }
            });
        });
      }
    });
  }; */

  logEmailShare = () => {
    const _mCoreData = this.MCORE.addNodes(this.props.config, null);
    this.Analytics = new Analytics(uuidv1());
    this.Analytics.log(ShareAnalytics(this.props, _mCoreData, "EMAIL"));
  };

  logLinkShare = () => {
    const _mCoreData = this.MCORE.addNodes(this.props.config, null);
    this.Analytics = new Analytics(uuidv1());
    this.Analytics.log(ShareAnalytics(this.props, _mCoreData, "LINK"));
  };

  logFacebookShare = () => {
    const _mCoreData = this.MCORE.addNodes(this.props.config, null);
    this.Analytics = new Analytics(uuidv1());
    this.Analytics.log(ShareAnalytics(this.props, _mCoreData, "FACEBOOK"));
  };

  logPinterestShare = () => {
    const _mCoreData = this.MCORE.addNodes(this.props.config, null);
    this.Analytics = new Analytics(uuidv1());
    this.Analytics.log(ShareAnalytics(this.props, _mCoreData, "PINTEREST"));
  };

  logTwitterShare = () => {
    const _mCoreData = this.MCORE.addNodes(this.props.config, null);
    this.Analytics = new Analytics(uuidv1());
    this.Analytics.log(ShareAnalytics(this.props, _mCoreData, "TWITTER"));
  };

  render() {
    if (!this.shareUrl) return null;
    return (
      <>
        <div className="share-container">
          {this.props.shares.includes(LINK) ? (
            <CopyToClipboard text={this.shareUrl}>
              <span onClick={this.handleLinkCopy}>Copy Link</span>
            </CopyToClipboard>
          ) : null}

          {this.props.shares.includes(EMAIL) ? (
            <a target="_top" href={this.mailTo} onClick={this.logEmailShare}>
              <span>Email</span>
            </a>
          ) : null}

          {this.props.shares.includes(FACEBOOK) ? (
            <FacebookShareButton
              url={this.shareUrl}
              onShareWindowClose={this.logFacebookShare}
              children={<FacebookIcon size={32} />}
            >
              <span>Facebook</span>
            </FacebookShareButton>
          ) : null}

          {this.props.shares.includes(PINTEREST) ? (
            <PinterestShareButton
              url={this.shareUrl}
              onShareWindowClose={this.logPinterestShare}
              media={this.props.image}
              children={<PinterestIcon size={32} />}
            >
              <span>Pinterest</span>
            </PinterestShareButton>
          ) : null}

          {this.props.shares.includes(TWITTER) ? (
            <TwitterShareButton
              url={this.shareUrl}
              onShareWindowClose={this.logTwitterShare}
              children={<TwitterIcon size={32} />}
            >
              <span>Twitter</span>
            </TwitterShareButton>
          ) : null}
        </div>
      </>
    );
  }
}

const ShareAnalytics = (props, data, logItem) => {
  let item = {};
  item.sessionId = props.sessionId;
  item.logContext = "CONTROL";
  item.logType = "SHARE";
  item.logItem = logItem;
  item.org = props.config.org;
  item.orgId = props.config.orgId;
  item.cfgId = props.config.cfgId;
  item.product = props.product;
  item.plid = data.plid;
  item.spid = data.spid;
  item.sku = props.sku;
  item.price = props.price ? props.price.grandTotal : null;
  item.currency = "USD";
  return item;
};

export default Share;
