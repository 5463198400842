import { loadAttributes } from "../Utils/utils";

class Forest {
  constructor(data, cfgId) {
    this.Text = data[0].grouping_name;
    var trees = [];
    data.forEach((subproduct) => {
      var tree = new Tree(
        subproduct.grouping_title,
        subproduct.grouping_subproduct_data[0],
        subproduct.grouping_subproducts.orgId,
        cfgId
      );
      trees.push(tree);
    });
    this.Trees = trees;
  }
}

class Tree {
  constructor(title, root, orgId, cfgId) {
    this.Root = new Root(root, orgId, cfgId);
    this.Title = title;
    this.Product = root.product;
    this.Plid = root.plid;
    this.Spid = root.spid;
    this.Text = loadAttributes("text", root.Attrs);
    this.OrgId = orgId;
    this.Name = loadAttributes("name", root.Attrs);
    this.RootNode = loadAttributes("rn", root.Attrs);
    this.Sku = loadAttributes("sku", root.Attrs);
    this.Pi = loadAttributes("pi", root.Attrs); //product index
    this.PiArr = loadAttributes("piArr", root.Attrs); // all possible pi's
  }
}

class Root {
  constructor(_obj, orgId, cfgId) {
    this.Text = loadAttributes("text", _obj.Attrs);
    this.RootNode = loadAttributes("rn", _obj.Attrs);
    this.OrgId = orgId;
    this.CfgId = cfgId;
    this.Product = _obj.product;
    this.Plid = loadAttributes("plid", _obj.Attrs);
    this.Spid = loadAttributes("spid", _obj.Attrs);
    this.Plid = _obj.plid;
    this.Spid = _obj.spid;
    this.Id = loadAttributes("id", _obj.Attrs);
    this.Name = loadAttributes("name", _obj.Attrs);
    this.Sku = loadAttributes("sku", _obj.Attrs);
    this.Type = loadAttributes("type", _obj.Attrs);
    this.Value = loadAttributes("value", _obj.Attrs);
    this.UIType = loadAttributes("uitype", _obj.Attrs);
    this.UIValue = loadAttributes("uivalue", _obj.Attrs);
    this.InfoId = loadAttributes("infoid", _obj.Attrs);
    this.Flex1 = loadAttributes("flex1", _obj.Attrs);
    this.Flex2 = loadAttributes("flex2", _obj.Attrs);
    this.Flex3 = loadAttributes("flex3", _obj.Attrs);
    this.Pi = loadAttributes("pi", _obj.Attrs); //product index
    this.PiArr = loadAttributes("piArr", _obj.Attrs); //all possible product index
    this.Hideprice = loadAttributes("hideprice", _obj.Attrs);
    this.EnableAR = loadAttributes("enablear", _obj.Attrs); //enable AR
    this.Geo = loadAttributes("geo", _obj.Attrs); //node includes stat file
    this.Mgroup = loadAttributes("mgroup", _obj.Attrs); //mat group that node points to
    this.Class = "ROOT";

    this.addBranches(this, _obj);
  }

  addBranches = (input, obj) => {
    this.Limbs = [];
    obj.NGs.forEach((ng) => {
      var Limb = {};
      var Branches = [];

      Branches.Text = loadAttributes("text", ng.Attrs);
      Branches.Id = loadAttributes("id", ng.Attrs);
      Branches.Hidden = loadAttributes("hidden", ng.Attrs);
      Branches.HiddenFilter = loadAttributes("hiddenfilter", ng.Attrs);
      Branches.Group = loadAttributes("group", ng.Attrs);
      Branches.HSX = loadAttributes("hsx", ng.Attrs);
      Branches.HSY = loadAttributes("hsy", ng.Attrs);
      Branches.Pi = loadAttributes("pi", ng.Attrs); //product index for multi product
      Branches.Order = ng.Ord;

      ng.Ns.forEach((n) => {
        var output = new Node(n, ng, "NODE");
        Branches.push(new Branch(input, output));
      });

      Limb.Branches = Branches;
      this.Limbs.push(Limb);
    });

    obj.MGs.forEach((mg) => {
      var Limb = {};
      var Branches = [];

      Branches.Text = loadAttributes("text", mg.Attrs);
      Branches.Id = loadAttributes("id", mg.Attrs);
      Branches.Match = loadAttributes("match", mg.Attrs);
      Branches.Name = loadAttributes("name", mg.Attrs);
      Branches.Library = loadAttributes("lib", mg.Attrs);
      Branches.Hidden = loadAttributes("hidden", mg.Attrs);
      Branches.Type = loadAttributes("type", mg.Attrs);
      Branches.Group = loadAttributes("group", mg.Attrs);
      // Branches.Location = loadAttributes("location", mg.Attrs);
      Branches.Moi = loadAttributes("moi", mg.Attrs);
      Branches.HiddenFilter = loadAttributes("hiddenfilter", mg.Attrs);
      Branches.Pi = loadAttributes("pi", mg.Attrs); //product index for multi product
      Branches.Order = mg.Ord;

      mg.Ms.forEach((m) => {
        var output = new Node(m, mg, "MATERIAL");
        Branches.push(new Branch(input, output));
      });

      Limb.Branches = Branches;
      this.Limbs.push(Limb);
    });
  };
}

// Branches connect the nodes via socket.
class Branch {
  constructor(fromNode, toNode) {
    this.Nodes = { fromNode: fromNode, toNode: toNode };
  }
}

class Node {
  constructor(_obj, _group, _class) {
    // OBJECT BASED PROPERTIES
    this.Text = loadAttributes("text", _obj.Attrs);
    this.Name = loadAttributes("name", _obj.Attrs);
    this.Id = loadAttributes("id", _obj.Attrs);
    this.Layer = loadAttributes("layer", _obj.Attrs);
    this.Sku = loadAttributes("sku", _obj.Attrs);
    this.RootNode = loadAttributes("rn", _obj.Attrs);
    this.Parent = loadAttributes("parent", _obj.Attrs);
    this.ParentName = loadAttributes("parentname", _obj.Attrs);
    this.Zindex = loadAttributes("zindex", _obj.Attrs);
    this.ParentText = loadAttributes("text", _group.Attrs);
    this.Type = loadAttributes("type", _obj.Attrs);
    this.Value = loadAttributes("value", _obj.Attrs);
    this.UIType = loadAttributes("uitype", _obj.Attrs);
    this.UIValue = loadAttributes("uivalue", _obj.Attrs);
    this.InfoId = loadAttributes("infoid", _obj.Attrs);
    this.Flex1 = loadAttributes("flex1", _obj.Attrs);
    this.Flex2 = loadAttributes("flex2", _obj.Attrs);
    this.Flex3 = loadAttributes("flex3", _obj.Attrs);
    this.Pi = loadAttributes("pi", _obj.Attrs); //product index
    this.diffuseType = loadAttributes("diffuseType", _obj.Attrs);
    this.shader = loadAttributes("shader", _obj.Attrs);
    this.Geo = loadAttributes("geo", _obj.Attrs);
    this.gltfMaterial = loadAttributes("gltfMaterial", _obj.Attrs);
    // GROUP BASED PROPERTIES
    this.Hidden = loadAttributes("hidden", _group.Attrs);
    this.HiddenFilter = loadAttributes("hiddenfilter", _group.Attrs);
    //this.Pi = loadAttributes("pi", _group.Attrs) //product index
    this.Class = _class;
    this.Order = _group.Ord;

    // Type specific properties
    if (_class === "MATERIAL") {
      // OBJECT BASED PROPERTIES
      this.SwatchType = loadAttributes("type", _obj.Attrs);
      this.Layer = loadAttributes("layer", _obj.Attrs);
      this.Filter = loadAttributes("filter", _obj.Attrs);
      this.layerType = loadAttributes("layerType", _obj.Attrs);
      this.Zindex = loadAttributes("zindex", _obj.Attrs);
      this.Sku = loadAttributes("sku", _obj.Attrs);
      this.Library = loadAttributes("library", _obj.Attrs);
      this.UVSize = loadAttributes("uvsize", _obj.Attrs); //uv size for decal 3/2/2020
      this.MatType = loadAttributes("mattype", _obj.Attrs);
      this.Type = loadAttributes("type", _obj.Attrs);
      this.mF = loadAttributes("mF", _obj.Attrs); //metallic factor
      this.rF = loadAttributes("rF", _obj.Attrs); //roughness factor
      this.gltfMaterial = loadAttributes("gltfMaterial", _obj.Attrs); //gltfMaterial for hex
      this.Flex1 = loadAttributes("flex1", _obj.Attrs);
      this.Categorize = loadAttributes("categorize", _obj.Attrs);
      // GROUP BASED PROPERTIES
      this.Match = loadAttributes("match", _group.Attrs);
      this.Moi = loadAttributes("moi", _group.Attrs);
      this.HiddenFilter = loadAttributes("hiddenfilter", _group.Attrs);
      this.Pi = loadAttributes("pi", _group.Attrs); //product index
    }

    this.Branches = [];
    this.Branches.Text = null;
    this.Branches.Id = null;
    this.Branches.Order = null;

    if (_class === "NODE") this.addBranches(this, _obj);
  }

  addBranches = (input, _obj) => {
    this.Limbs = [];

    _obj.NGs.forEach((ng) => {
      var Limb = {};
      var Branches = [];

      Branches.Text = loadAttributes("text", ng.Attrs);
      Branches.Id = loadAttributes("id", ng.Attrs);
      Branches.Name = loadAttributes("name", ng.Attrs);
      Branches.Hidden = loadAttributes("hidden", ng.Attrs);
      Branches.HiddenFilter = loadAttributes("hiddenfilter", ng.Attrs);
      Branches.Zindex = loadAttributes("zindex", ng.Attrs);
      Branches.Group = loadAttributes("group", ng.Attrs);
      Branches.HSX = loadAttributes("hsx", ng.Attrs);
      Branches.HSY = loadAttributes("hsy", ng.Attrs);
      Branches.Pi = loadAttributes("pi", ng.Attrs); //product index for multi product
      Branches.Order = ng.Ord;

      ng.Ns.forEach((n) => {
        var output = new Node(n, ng, "NODE");
        Branches.push(new Branch(input, output));
      });

      Limb.Branches = Branches;
      this.Limbs.push(Limb);
    });

    _obj.MGs.forEach((mg) => {
      var Limb = {};
      var Branches = [];

      Branches.Text = loadAttributes("text", mg.Attrs);
      Branches.Id = loadAttributes("id", mg.Attrs);
      Branches.Match = loadAttributes("match", mg.Attrs);
      Branches.Name = loadAttributes("name", mg.Attrs);
      Branches.Library = loadAttributes("lib", mg.Attrs);
      Branches.Hidden = loadAttributes("hidden", mg.Attrs);
      Branches.Type = loadAttributes("type", mg.Attrs);
      Branches.Zindex = loadAttributes("zindex", mg.Attrs);
      Branches.Group = loadAttributes("group", mg.Attrs);
      Branches.Moi = loadAttributes("moi", mg.Attrs);
      Branches.HiddenFilter = loadAttributes("hiddenfilter", mg.Attrs);
      Branches.Pi = loadAttributes("pi", mg.Attrs);

      Branches.Order = mg.Ord;

      mg.Ms.forEach((m) => {
        var output = new Node(m, mg, "MATERIAL");
        Branches.push(new Branch(input, output));
      });

      Limb.Branches = Branches;
      this.Limbs.push(Limb);
    });
  };
}

export { Forest, Branch, Root };
