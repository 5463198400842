import React from "react";
import Share from "./share";
import Download from "./download";
import Mode from "./mode";
import LeadCreator from "../LeadCreator/leadcreator";
import Reset from "./reset";
import Analytics from "../../classes/analytics";
import "./controls.css";
import MachineCoreAPI from "../MachineCore/machinecoreAPI";

//const uuidv1 = require('uuid/v1');
const { v1: uuidv1 } = require("uuid");

class Controls extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      sharing: false,
      zooming: false,
      downloading: false,
      changingMode: false,
      loaded: false,
    };
    this.zoom = this.zoom.bind(this);
    this.download = this.download.bind(this);
    this.share = this.share.bind(this);
    this.MCORE = new MachineCoreAPI();
  }

  componentDidMount = () => {
    if (this.props.images.length > 0) this.setState({ show: true });
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.images !== this.props.images) {
      this.setState({ show: true });
    }
  };

  zoom() {
    const _mCoreData = this.MCORE.addNodes(this.props.config, null);
    this.Analytics = new Analytics(uuidv1());
    this.Analytics.log(ZoomAnalytics(this.props, _mCoreData));
    this.setState({ zooming: !this.state.zooming });
    // Tell the product application what's going on.
    this.props.onZoom();
  }

  download() {
    this.setState({ downloading: !this.state.downloading });
  }

  share() {
    this.setState({ sharing: !this.state.sharing });
  }

  mode() {
    this.setState({ changingMode: !this.state.changingMode });
  }

  render() {
    // console.log(this.props.config);
    // console.log("rendering");
    // if (this.props.images.length === 0) return null;
    //currentIndex checks for single frame configurators. Single frame configurators are set to index of 1 in config, but need to be [0] as there is only 1 frame.
    //this fixes legacy products crashing on share / downloads - MG 01/15/2020
    var currentIndex =
      this.props.images.length === 1 ? 0 : this.props.imageIndex;

    var largeImageUrl =
      this.props.images.length > 0
        ? this.props.images[currentIndex].LargeImageURL
        : null;

    if (this.state.show)
      return (
        <div className="controls">
          <div className="row removeMarginsAndPadding control-options-container">
            {
              <div
                className="control-option button zoom"
                onClick={this.zoom.bind(this)}
                title="zoom"
              >
                <img
                  className="control-icon"
                  width="25"
                  height="25"
                  src="/assets/zoom.svg"
                  alt=""
                />
                {this.state.zooming ? (
                  <span className="zoom-active control-text">Zoom</span>
                ) : (
                  <span className="zoom control-text">Zoom</span>
                )}
              </div>
            }
            {this.props.config.controls.reset ? (
              <Reset {...this.props} />
            ) : null}
            {this.props.config.leadcreator.active ? (
              <LeadCreator {...this.props} />
            ) : null}
            {this.props.config.downloads.length > 0 &&
            this.props.config.controls.download ? (
              <div
                className="control-option button download"
                onClick={this.download.bind(this)}
                title="download"
              >
                <img
                  className="control-icon"
                  width="25"
                  height="25"
                  src="/assets/download.svg"
                  alt=""
                />
                {this.state.downloading ? (
                  <span className="download-active control-text">Download</span>
                ) : (
                  <span className="download control-text">Download</span>
                )}
                {this.state.downloading ? (
                  <Download
                    // onDownload tells the product that a download has been initiated.
                    // this will freeze the screen until the function has been completed.
                    onDownload={this.props.onDownload}
                    // download tells the caller it's been clicked and to toggle the button.
                    download={this.download.bind(this)}
                    // image index tells the images state what image to pull
                    image={largeImageUrl} //currentIndex is defined above. was [this.props.imageIndex] -MG
                    product={this.props.product}
                    path={this.props.path}
                    sessionId={this.props.sessionId}
                    config={this.props.config}
                    text={null}
                    images={this.props.images}
                    price={this.props.price}
                    sku={this.props.sku}
                    lowresimage={this.props.images[currentIndex].ImageURL}
                  />
                ) : null}
              </div>
            ) : null}

            {this.props.config.controls.shares.length > 0 ? (
              <div
                className="control-option button"
                onClick={this.share.bind(this)}
                title="share"
              >
                <img
                  className="control-icon"
                  width="25"
                  height="25"
                  src="/assets/share.svg"
                  alt=""
                />
                {this.state.sharing ? (
                  <span className="share-active control-text">Share</span>
                ) : (
                  <span className="share control-text">Share</span>
                )}
                {this.state.sharing ? (
                  <Share
                    hide={this.share.bind(this)}
                    shares={this.props.config.controls.shares}
                    product={this.props.product}
                    org={this.props.org}
                    sku={this.props.sku}
                    ui={this.props.ui}
                    group={this.props.group}
                    imageUrl={this.props.imageUrl}
                    config={this.props.config}
                    image={largeImageUrl} //currentIndex is defined above. was [this.props.imageIndex] -MG
                    host={this.props.host}
                    pricing={this.props.price}
                  />
                ) : null}
              </div>
            ) : null}

            {this.props.config.modes.length > 0 &&
            this.props.config.controls.mode ? (
              <div
                className="control-option button"
                onClick={this.mode.bind(this)}
              >
                <img
                  className="control-icon"
                  width="25"
                  height="25"
                  src="../../assets/cube.svg"
                  alt=""
                />
                {this.state.changingMode ? (
                  <span className="mode-active control-text">display mode</span>
                ) : (
                  <span className="control-text">display mode</span>
                )}
                {this.state.changingMode ? (
                  <Mode modes={this.props.config.modes} />
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      );
    return null;
  }
}

const ZoomAnalytics = (props, data) => {
  let item = {};
  item.sessionId = props.sessionId;
  item.logContext = "CONTROL";
  item.logType = "ZOOM";
  item.logItem = "ZOOM";
  item.org = props.config.org;
  item.orgId = props.config.orgId;
  item.cfgId = props.config.cfgId;
  item.product = props.product;
  item.plid = data.plid;
  item.spid = data.spid;
  item.sku = props.sku;
  item.price = !props.price ? null : props.price.grandTotal;
  item.currency = !props.price ? null : props.price.currency;
  return item;
};

export default Controls;
