import React from 'react';
import './hologramviewer.css';

class HologramViewer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeImage: 0,
            rotateSpeed: 1000
        }
    }

    componentDidUpdate = (prevProps) => {
    }

    componentDidMount = () => {
        this.rotateInterval = setInterval(() => {

            var img;
            if (this.state.activeImage === this.props.images.length - 1)
                img = 0;
            else
                img = this.state.activeImage + 1;

            this.setState({ activeImage: img });
        }, this.state.rotateSpeed);
    }

    render() {
        if (this.props.images.length > 0)
            return (
                <div id="tricon">
                    <div id="tri1" className="trd">
                        <img className="tri" alt="" src={this.props.images[this.state.activeImage].ImageURL} />
                    </div>
                    <div id="tri2" className="trd">
                        <img className="tri" alt="" src={this.props.images[this.state.activeImage].ImageURL} />
                    </div>
                    <div id="tri3" className="trd">
                        <img className="tri" alt="" src={this.props.images[this.state.activeImage].ImageURL} />
                    </div>
                    <div id="tri4" className="trd">
                        <img className="tri" alt="" src={this.props.images[this.state.activeImage].ImageURL} />
                    </div>
                </div>
            )

            return null;
    }

}

export default HologramViewer;