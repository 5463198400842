import React from "react";
import Axios from "axios";
import Analytics from "../../classes/analytics";
import MachineCoreAPI from "../MachineCore/machinecoreAPI";
const { v1: uuidv1 } = require("uuid");
const vm = require("vm");

class QuickLook extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      generating: false,
    };
    this.MCORE = new MachineCoreAPI();
    this.logAnalytics = this.logAnalytics.bind(this);
    this.viewInRoom = this.viewInRoom.bind(this);
    this.openConfig = this.openConfig.bind(this);
    //   this.launchViewer = false;
  }

  componentDidMount = () => {
    let href = window.location.href;
    if (href.includes("arview=true")) {
      this.launchViewer = true;
    }
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.imageUrl && this.launchViewer) {
      this.launchViewer = false;
      this.viewInRoom();
    }
  };

  viewInRoom() {
    this.setState({
      message: null,
      generating: true,
      success: true,
      modelUrl: null,
    });

    // GET POST DATA
    this.MCORE = new MachineCoreAPI();
    // this.ThreeDReducer = new ThreeDReducer();
    const postData = this.MCORE.addNodes(
      this.props.config,
      this.props.productObj
    );

    Axios.get(
      `${window.TBPM.BLOB_URL}/${this.props.config.cfgId}/rules3d.js`,
      {}
    ).then((response) => {
      const rules3d = new vm.Script(response.data);
      rules3d.runInNewContext(postData);
      var imageUrl =
        this.props.images.length > 0 ? this.props.images[0].ImageURL : null;
      let objUrl = imageUrl
        ? imageUrl.replace("req=render", "req=3djsonkv")
        : null;

      postData.objUrl = objUrl;
      postData.outputFormat = "usdz";
      //   console.log(postData);
      Axios.post(window.TBPM.THREE_D_ENDPOINT, {
        data: postData,
        responseType: "json",
      })
        .then((response) => {
          //  console.log(response);
          let link = document.createElement("a");
          // generate a new link ID for each config change since react doesn't reload the doc and clear out previous AR links
          let linkId = uuidv1();
          link.id = linkId;
          link.rel = "ar";
          link.className = "ar-link";
          link.href =
            response.data.url +
            "?" +
            window.TBPM.SAS_TOKEN +
            "#allowsContentScaling=0";

          let img = document.createElement("img");
          img.src = "/assets/arkit.png";
          img.alt = "click me";
          img.className = "ar-img";
          link.appendChild(img);
          document.body.appendChild(link);
          document.getElementById(linkId).click();
          this.setState({ generating: false });
          this.logAnalytics();
        })
        .catch((err) => {
          // console.log(err);
          this.setState({ message: "Error!!", generating: false });
        });
    });
  }

  logAnalytics = () => {
    const _mCoreData = this.MCORE.addNodes(this.props.config, null);
    this.Analytics = new Analytics(uuidv1());
    this.Analytics.log(ARAnalytics(this.props, _mCoreData));
  };

  openConfig() {
    return null;
  }

  render() {
    return (
      <div className="view-in-room">
        <div
          className="view-in-room-button"
          onClick={this.viewInRoom.bind(this)}
          title="View in your space"
        >
          <img className="quicklook-icon" src="/assets/arkit.png" alt="AR" />
          &nbsp;&nbsp;View in your space
          {this.state.generating ? <span>&nbsp;&nbsp;Loading...</span> : ""}
        </div>
      </div>
    );
  }
}

const ARAnalytics = (props, data) => {
  let item = {};
  item.sessionId = props.sessionId;
  item.logContext = "CONTROL";
  item.logType = "AR";
  item.logItem = "AR";
  item.deviceType = "mobile";
  item.osName = props.ua.os.name;
  item.osVersion = props.ua.os.version;
  item.org = props.config.org;
  item.orgId = props.config.orgId;
  item.cfgId = props.config.cfgId;
  item.product = props.product;
  item.plid = data.plid;
  item.spid = data.spid;
  item.sku = props.sku;
  item.price = !props.price ? null : props.price.grandTotal;
  item.currency = !props.price ? null : props.price.currency;
  return item;
};

export default QuickLook;
