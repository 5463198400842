import { Material } from "../Libraries/material";
import { Root, Branch } from "../App/forest";
// REDUX
import store from "../../Redux/store";
import { runInNewContext } from "vm";

class MachineCore {
  material = () => {};

  addNodes = (obj, _rules) => {
    let output = {};
    let _nodes = [];
    let _materials = [];
    let _path = store.getState().path.slice(-1)[0];

    //console.clear();
    // console.log(_path);

    // console.log(obj);

    output.backgroundcolor = obj.header.backgroundcolor;
    output.cropalpha = obj.header.cropalpha;
    output.croppadding = obj.header.croppadding;
    output.dropshadow = obj.header.dropshadow;
    output.format = obj.header.format;
    output.guid = obj.header.guid;
    output.largesize = obj.header.largesize;
    output.manufacturer = obj.header.manufacturer;
    output.size = obj.header.size;
    output.type = obj.header.type;
    output.modelMaterials = obj.modelMaterials;

    // Need to keep track of static and swatch materials to merge and blend post build.
    var staticMaterials = [];
    var currentProduct; //reference to current product -mg

    _path.forEach((p) => {
      if (p instanceof Root || p instanceof Branch) {
        var _node;

        if (p instanceof Root) {
          _node = p;
          currentProduct = p.Product; //setting current product -mg
        }

        if (p instanceof Branch) _node = p.Nodes.toNode;

        //console.log(_node.Type);

        if (_node.Class === "ROOT") {
          output.rootnode = _node.RootNode;
          output.sku = _node.Sku;
          output.text = _node.Text;
          output.name = _node.Name;
          output.orgId = _node.OrgId;
          output.plid = _node.Plid;
          output.spid = _node.Spid;
          output.product = _node.Product;
          output.hideprice =
            _node.Hideprice && _node.Hideprice === "true" ? true : false;
          output.pi = _node.Pi; //product index
          if (output.pi === "" || output.pi === undefined) {
            output.pi = "0";
          }
          output.piArr = _node.PiArr;
        }

        if (_node.Class === "NODE") {
          //console.log(_node.Geo);
          var node = {};
          node.node = _node.Layer; //need to be layer because name does not always = layer. layer is what renders -MG
          node.sku = _node.Sku;
          node.nodegroup = _node.ParentName;
          node.flex1 = _node.Flex1; //capitalized -MG
          node.flex2 = _node.Flex2;
          node.flex3 = _node.Flex3;
          node.geo = _node.Geo;
          node.mgroup = _node.Mgroup;
          node.pi = _node.Pi; //prodcut index for multi product
          if (node.pi === "" || node.pi === undefined) {
            node.pi = "0";
          }
          node.parentText = _node.ParentText;
          node.text = _node.Text;
          // Modify the rootnode if needed.
          if (_node.RootNode !== "") output.rootnode = _node.RootNode;
          _nodes.push(node);
        }

        if (_node.Class === "MATERIAL") {
          var material = {};
          material.matched = false;
          material.layer = _node.Layer;
          material.matgroup = _node.ParentName;
          material.material = _node.Value;
          material.sku = _node.Sku;
          material.moi = _node.Moi;
          material.hiddenfilter = _node.HiddenFilter;
          material.pi = _node.Pi; //product index for multi product
          material.product = currentProduct; //giving material reference to product from root -mg
          material.mattype = _node.MatType;
          material.diffuseType = _node.diffuseType;
          material.shader = _node.shader;
          material.gltfScale = _node.gltfScale;
          material.parentText = _node.ParentText;
          material.text = _node.Text;
          material.type = _node.Type;
          material.gltfMaterial = _node.gltfMaterial;
          var mat = _node.Value;
          //Set PI to 0 when it is undefined. This is for all single configurator products
          if (material.pi === "" || material.pi === undefined) {
            material.pi = "0";
          }
          //Set the UV size for decal (image upload)
          if (_node.UVSize !== "" && _node.UVSize !== undefined) {
            material.uvsize = _node.UVSize;
          }

          var matches = _node.Match.split("-");

          matches.forEach((match) => {
            if (match !== "" && _node.Type !== "dynamic") {
              var material1 = {};
              material1.matched = true;
              material1.layer = _node.Layer;
              material1.matgroup = match;
              material1.material = mat;
              material1.text = _node.Text;
              material1.parentText = null;
              material1.type = _node.Type; // was null;
              material1.diffuseType = _node.diffuseType;
              material1.shader = _node.shader;
              material1.gltfMaterial = _node.gltfMaterial;
              material1.gltfScale = _node.gltfScale;
              material1.gltfMaterial = _node.gltfMaterial;
              material1.mattype = _node.MatType;
              //material1.sku = _node.Sku; //Don't need this because we already have the sku in the injected matgroup / matgroup being matched with - MG 1/9/2020
              _materials.push(material1);
            }
          });
          // pickers override statics so if there's a picker in the array don't
          // add the static material.
          var add = true;
          _materials.forEach((m, index) => {
            if (m.matgroup === material.matgroup) {
              add = false;
            }
          });

          if (add) {
            //   console.log(material);
            _materials.push(material);
            staticMaterials.push(material);
          }
        }
      }

      if (p instanceof Material) {
        //console.log(p)
        material = {};
        material.matched = false;
        material.layer = p.Layer;
        material.matgroup = p.MatGroup;
        material.material = p.Value;
        //material.zones = p.Zones;
        material.moi = p.Moi;
        material.pi = p.Pi;
        material.sku = p.Sku; //mg assigning sku to current sku (was material.sku = p.props.sku + p.props.clientCode;)
        material.mattype = p.MatType;
        material.parentText = p.ParentText;
        material.diffuseType = p.diffuseType;
        material.shader = p.shader;
        material.gltfScale = p.gltfScale;
        material.type = p.Type;
        material.gltfMaterial = p.gltfMaterial;
        if (material.pi === "" || material.pi === undefined) {
          material.pi = "0";
        }
        if (material.flex1 === "" || material.flex1 === undefined) {
          material.flex1 = p.Flex1;
        }

        material.categorize = p.Categorize;
        if (material.categorize === "" || material.categorize === undefined) {
          material.categorize = "false";
        }

        _materials.push(material);
        // need to create a new material for every match.  The match attribute
        // will come in hyphenated which needs to be split into an array first
        // before iteration.
        matches = p.Match.split("-");

        matches.forEach((match) => {
          if (match !== "") {
            var material1 = {};
            material1.matched = true;
            material1.material = p.Value;
            material1.layer = p.Layer;
            material1.matgroup = match;
            material1.text = p.Text;
            material1.parentText = null;
            //material1.type = null;
            material1.type = p.Type;
            material1.mattype = p.MatType;
            material1.diffuseType = p.diffuseType;
            material1.shader = p.shader;
            material1.gltfMaterial = p.gltfMaterial;
            material1.gltfScale = p.gltfScale;
            _materials.push(material1);
          }
        });

        // A material branch and a swatch can contain the same or even merge materials for
        // rendering so we need to merge or remove by parent group.
        staticMaterials.forEach((staticMaterial) => {
          //console.log(material.sku)
          if (staticMaterial.matgroup === material.matgroup) {
            material.sku = staticMaterial.sku + p.props.clientCode;
          }
        });
      }
    });

    // Modify the materials based on the rules.
    if (_rules) {
      runInNewContext(_rules, { _materials });
    }

    output.nodes = _nodes;
    output.materials = _materials;
    return output;
  };
}

export default MachineCore;
