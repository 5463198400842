import axios from "axios";
// ENVIROMENT VARIABLES
//const ENV = require('../../env');

class IO {
  getLogic(logic) {
    return axios.get(logic).then((response) => {
      return response.data;
    });
  }

  getImages(obj, config) {
    // PREP
    // remove the model materials from the images API call...these are
    // only used for retrieval of materials.
    delete obj.modelMaterials;
    //console.log(JSON.stringify(obj))

    //return axios.get(ENV.utils.images, {
    return axios
      .get(window.TBPM.GET_RENDER_URLS_ENDPOINT, {
        params: {
          Json: obj,
        },
      })
      .then((response) => {
        if (window.TBPM.ENV !== "PROD") {
          for (let el of response.data) {
            let url1 = el.ImageURL;
            el.ImageURL = url1.replace(
              "https://machinecore.thebigpicturemachine.com",
              window.TBPM.MC_URL
            );
            let url2 = el.LargeImageURL;
            el.LargeImageURL = url2.replace(
              "https://machinecore.thebigpicturemachine.com",
              window.TBPM.MC_URL
            );
          }
        }

        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getPrice(obj, config) {
    // Create a new data object to post that removes irrelevant data from the object.

    //console.log(obj.moi)

    // for(var i = 0; i < obj.materials.length; i++){
    //     console.log(obj.materials[i])
    // }

    var data = {};
    data.guid = obj.guid;
    data.rootnode = obj.rootnode;
    data.nodes = obj.nodes;
    data.materials = obj.materials;
    data.sku = obj.sku;
    data.text = obj.text;
    data.name = obj.name;
    data.orgId = obj.orgId;
    data.zindex = obj.zindex; //MG adding zindex to post
    data.moi = obj.moi;
    data.pi = obj.pi; //product index
    data.piArr = []; //all possible product index
    data.mattype = obj.mattype;
    data.apiParams = config.apiParams;

    if (obj.piArr.split(",").length > 1) {
      obj.piArr.split(",").forEach((p) => {
        data.piArr.push(p);
      });
    } else {
      data.piArr.push("0");
    }

    //console.log(data.piArr)

    var headers = {
      "Content-Type": "application/json",
    };

    // Post
    //return axios.post(ENV.api.pricing, data, { headers: headers })
    return axios
      .post(window.TBPM.PRICING_ENDPOINT, data, { headers: headers })
      .then((response) => response.data)
      .catch((error) => {
        // console.log(error);
        return { total: "XXXX.XX" };
      });
  }

  getSwatches(caller, groups, obj, config) {
    // console.log(caller);
    // console.log(groups)
    // console.log(obj);
    // console.log(config)

    if (!obj) return;

    // Create a new data object to post that removes irrelevant data from the object.
    var data = {};

    data.guid = obj.guid;
    data.rootnode = obj.rootnode;
    data.nodes = obj.nodes;
    data.materials = obj.materials;
    data.sku = obj.sku;
    data.text = obj.text;
    data.name = obj.name;
    data.orgId = obj.orgId;
    data.moi = caller.moi;
    data.mattype = obj.mattype;
    data.groups = groups;
    data.pi = caller.pi;
    data.piArr = []; //all possible product index
    data.apiParams = config.apiParams;
    data.type = caller.type;
    data.parentText = obj.parentText;
    data.categorize = caller.categorize;

    if (obj.piArr.split(",").length > 1) {
      obj.piArr.split(",").forEach((p) => {
        data.piArr.push(p);
      });
    } else {
      data.piArr.push("0");
    }

    if (caller.flex1 !== "" || caller.flex1 !== undefined) {
      data.flex1 = caller.flex1;
    }

    data.modelMaterials = [];
    // prep the model material --> little hacky...need to build
    // model materials out to be property specific.
    obj.modelMaterials.forEach((mm) => {
      mm.props = [];
      data.modelMaterials.push(mm);
    });

    // Aggregate the mois if there are groups by comparing the matgroup to the first zone entry (parent).  If there's a
    // match then add the MOI to moi array.
    let moiArray = [];
    data.groups.forEach((group) => {
      group.zones.forEach((zone) => {
        if (group.zones[0].name === caller.parent) {
          moiArray.push(zone.moi);
        }
      });
    });

    // add parent text to caller
    const material = obj.materials.filter(
      (material) => material.matgroup === caller.parent
    );
    if (material[0]) caller.parentText = material[0].parentText;

    if (moiArray) {
      caller.moi = moiArray.filter(
        (item, index) => moiArray.indexOf(item) === index
      ); // remove dupes.
    }

    if (caller.moi.length === 0) {
      caller.moi = data.moi;
    }

    data.caller = caller;

    var headers = {
      "Content-Type": "application/json",
    };
    // Post
    //console.log(JSON.stringify(data))
    // Handle the material post differently if the config has been set to fragment.
    // This will only load the materials required for that page.
    data.groups = null; //quick fix for dual fabric bug -mg 9/3/2020
    if (!config.materials.fragmented) {
      //return axios.post(ENV.api.materials, data, { headers: headers })
      return axios
        .post(window.TBPM.MATERIALS_ENDPOINT, data, { headers: headers })
        .then((response) => {
          //console.log(response.data)
          return response.data;
        })
        .catch((error) => {
          //console.log(error)
          return "Error retrieving the materials.";
        });
    } else {
      //return axios.post(ENV.api.paginatedMaterials, data, { headers: headers })
      return axios
        .post(window.TBPM.PAGINATED_MATERIALS_ENDPOINT, data, {
          headers: headers,
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return "Error retrieving the materials.";
        });
    }
    // Rebuild the swatches.
  }

  /*     getExistingSwatchesByManufacturer = (manufacturer) => {
            //return axios.post(ENV.api.publishedMaterials + manufacturer)
            return axios.post(window.TBPM.PUBLISHED_MATERIALS_ENDPOINT + manufacturer)
                .then(response => response.data)
                .catch(error => {
                    console.log(error);
                })
        } */

  createLog = (data) => {
    //axios.post(ENV.api.analytics, {
    axios
      .post(window.TBPM.ANALYTICS_ENDPOINT, {
        data: data,
      })
      .then((response) => {
        //   console.log(response);
      });
  };
}

export default IO;
