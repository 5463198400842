import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "./organizations.css";

class AllOrganizations extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
    };
  }

  componentWillMount() {
    //  axios.get(ENV.api.path + this.props.match.url)
    axios
      .get(window.TBPM.UIB_API_URL + this.props.match.url)
      .then((response) => {
        this.setState({ data: response.data.organizations });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    return (
      <div>
        {this.state.data.map((p, index) => (
          <div className="organization" key={index}>
            <div className="organization-name">{p.name}</div>
            <div className="organization-item">ORG ID:&nbsp;{p.orgId}</div>
            {p.configurators.map((config) => (
              <div className="organization-item" key={config.id}>
                {config.name} Configurator:&nbsp;
                <Link to={"/groupings/ui/" + config.id}>{config.id}</Link>&nbsp;
                <Link to={"/groupings/ui/" + config.id + "/admin"}>
                  (Admin)
                </Link>
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  }
}

export default AllOrganizations;
