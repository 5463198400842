import React from "react";
import axios from "axios";
import ls from "local-storage";
import Modal from "react-bootstrap/Modal";
import QRCode from "qrcode.react";
import Analytics from "../../classes/analytics";
import MachineCoreAPI from "../MachineCore/machinecoreAPI";
const { v1: uuidv1 } = require("uuid");

class Desktop extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showQRModal: false,
      qrUrl: null,
    };
    this.MCORE = new MachineCoreAPI();
    this.logAnalytics = this.logAnalytics.bind(this);
    //  this.showObject = this.showObject.bind(this);
  }

  componentDidMount = () => {
    /*     const fileData = ls.get("tbpmFileData");
    let qrUrl =
      this.props.host +
      "?file=/group/" +
      fileData.file_ui +
      "/" +
      fileData.file_group +
      "/share/" +
      fileData.file_uid;
    this.setState({ qrUrl: qrUrl }); */
  };

  componentDidUpdate = (prevProps) => {
    /*     if (prevProps.images !== this.props.images) {
      this.setState({ show: true });
    } */
  };

  toggleModal = () => {
    const fileData = ls.get("tbpmFileData");
    fileData.arview = true;
    let qrUrl =
      this.props.host +
      "?file=/group/" +
      fileData.file_ui +
      "/" +
      fileData.file_group +
      "/share/" +
      fileData.file_uid;
    /*       +
      "?launch=" +
      fileData.launch; */
    axios.post(window.TBPM.UIB_API_URL + "/share/save", fileData).then(() => {
      this.setState({
        showQRModal: !this.state.showQRModal,
        qrUrl: qrUrl,
      });
      if (this.state.showQRModal) this.logAnalytics();
    });
  };

  /*   showObject = () => {
    const _ThreeDReducerData = this.ThreeDReducer.addNodes(
      this.props.config,
      null
    );
    console.log(_ThreeDReducerData);
  }; */

  logAnalytics = () => {
    const _mCoreData = this.MCORE.addNodes(this.props.config, null);
    this.Analytics = new Analytics(uuidv1());
    this.Analytics.log(ARAnalytics(this.props, _mCoreData));
  };

  render() {
    return (
      <div className="viewInRoom">
        {/* <div className="row removeMarginsAndPadding control-options-container"> */}
        <div
          className="button viewinroom"
          onClick={this.toggleModal.bind(this)}
          title="view in room"
        >
          <img
            className="control-icon-zz"
            width="15"
            height="15"
            src="/assets/arkit.png"
            alt=""
          />
          &nbsp;&nbsp;View in your space
          <div>
            <QRModal
              qrurl={this.state.qrUrl}
              show={this.state.showQRModal}
              onHide={this.toggleModal.bind(this)}
              voffset={this.props.hostY}
              qrcode={this.props.config.threeD.qrCode}
              cfgid={this.props.config.cfgId}
            />
          </div>
        </div>
        {/* </div> */}
      </div>
    );
  }
}

function QRModal(props) {
  return (
    <Modal {...props} show={props.show}>
      <Modal.Header>
        <div className="qrcode-header">SCAN QR CODE</div>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <center>
          <div className="qrcode-code">
            {props.qrcode && props.qrcode.logo.display ? (
              <QRCode
                value={props.qrurl + "?arview=true"}
                renderAs={"svg"}
                //  size={400}
                level={"L"}
                includeMargin={false}
                imageSettings={{
                  src:
                    window.TBPM.BLOB_URL +
                    "/" +
                    props.cfgid +
                    "/" +
                    props.qrcode.logo.blobPath,
                  excavate: true,
                  width: props.qrcode.logo.width,
                  height: props.qrcode.logo.height,
                }}
              />
            ) : (
              <QRCode
                value={props.qrurl}
                renderAs={"svg"}
                // size={400}
                level={"L"}
                includeMargin={false}
              />
            )}
          </div>
          <div className="qrcode-text">
            * supported on iPhone or iPad running iOS 12+, or devices running
            Andriod 7+&nbsp;
            <a
              className="ar-device-link"
              target="_blank"
              rel="noopener noreferrer"
              s
              href="https://developers.google.com/ar/devices"
            >
              that support AR
            </a>
          </div>
          <div class="qrcode-branding">
            <img src="/assets/QRModalTBPMBranding.svg" alt="branding"></img>
          </div>
        </center>
      </Modal.Body>
      {/*   <Modal.Body>
        <center>
          <div className="qrcode-text">
            Point your mobile device camera at the QR code below to view this
            product in your space in augmented reality
          </div>
          <div className="qrcode-text">
            When the product opens on your mobile device, click the "View in
            your space" link again
          </div>
          <div className="qrcode-code">
            <QRCode value={props.qrurl} renderAs="svg" />
          </div>
          <div className="qrcode-text">
            * supported on the following mobile devices: iPhone on iOS 12+ or
            Android 8.0+ with ARCore 1.9+
          </div>
        </center>
      </Modal.Body> */}
    </Modal>
  );
}

const ARAnalytics = (props, data) => {
  let item = {};
  item.sessionId = props.sessionId;
  item.logContext = "CONTROL";
  item.logType = "AR";
  item.logItem = "QRCODE";
  item.deviceType = "desktop";
  item.osName = props.ua.os.name;
  item.osVersion = props.ua.os.version;
  item.org = props.config.org;
  item.orgId = props.config.orgId;
  item.cfgId = props.config.cfgId;
  item.product = props.product;
  item.plid = data.plid;
  item.spid = data.spid;
  item.sku = props.sku;
  item.price = !props.price ? null : props.price.grandTotal;
  item.currency = !props.price ? null : props.price.currency;
  return item;
};

export default Desktop;
