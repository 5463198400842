import React from "react";
import { Accordion, AccordionItem } from "react-sanfona";
import { Root, Branch } from "../App/forest";
import { Material } from "../Libraries/material";
// STYLE
import "./information.css";
// REDUX
import store from "../../Redux/store";

class Information extends React.Component {
  sortByOrderNumber(a, b) {
    var x = a.order;
    var y = b.order;
    return x < y ? -1 : x > y ? 1 : 0;
  }

  materials() {
    let _materials = [];
    let _swatches = [];
    let _path = store.getState().path.slice(-1)[0];

    if (_path)
      _path.forEach((item) => {
        if (item instanceof Root || item instanceof Branch) {
          var _node;

          if (item instanceof Root) _node = item;

          if (item instanceof Branch) _node = item.Nodes.toNode;

          if (_node.Class === "MATERIAL") {
            let _material = {};
            _material.name = _node.Text;
            _material.category = _node.ParentName;
            _material.parent = _node.ParentName;
            _material.parenttext = _node.ParentText;
            _material.order = _node.Order;
            _material.hidden = _node.Hidden; //defining the hidden flag

            if (!_material.hidden)
              //do not push if hidden
              _materials.push(_material);
          }
        }

        if (item instanceof Material) {
          let _swatch = {};
          _swatch.name = item.Text + " " + item.Name; // added + " " + item.Name in order to show material code after the name -mg 4/21
          _swatch.parent = item.MatGroup;
          _swatches.push(_swatch);
          //console.log(item)
        }
      });

    _materials.forEach((material) => {
      _swatches.forEach((swatch) => {
        if (material.parent === swatch.parent) {
          material.name = swatch.name;
          //console.log(swatch)
        }
      });
    });

    _materials.sort(this.sortByOrderNumber);

    return (
      <div>
        {_materials.map((item, index) => (
          <div key={index} className="information-lineItem">
            <span className="information-lineItem-parentTitle">
              {item.parenttext}:
            </span>
            <span className="information-lineItem-title">{item.name}</span>
          </div>
        ))}
      </div>
    );
  }

  nodes() {
    let _nodes = [];
    let _path = store.getState().path.slice(-1)[0];

    if (_path)
      _path.forEach((item) => {
        if (item instanceof Root || item instanceof Branch) {
          var _node;
          if (item instanceof Root) _node = item;

          if (item instanceof Branch) _node = item.Nodes.toNode;

          if (_node.Class === "NODE" || _node.Class === "ROOT") {
            _nodes.push(_node);
          }
        }
      });

    _nodes.sort(this.sortByOrderNumber);

    return <div>{_nodes.map((node, index) => this.lineItem(node, index))}</div>;
  }

  lineItem = (node, index) => {
    if (!node.Hidden)
      return (
        <div key={index} className="information-lineItem">
          <span className="information-lineItem-parentTitle">
            {node.Class === "ROOT" ? `${node.Product}:` : `${node.ParentText}:`}
          </span>
          <span className="information-lineItem-title">{node.Text}</span>
        </div>
      );

    return null;
  };

  render() {
    var columns;
    //console.log(this.props.config.informationExpander);
    var nodes = this.nodes();
    var materials = this.materials();
    var info = this.props.config.information;
    var baseModel = this.props.pricingData
      ? this.props.pricingData.prices.map((p, i) => (
          <span key={i}>{i > 0 ? "," + p.baseModel : p.baseModel}</span>
        ))
      : null;

    if (this.props.config.information.columns === 1)
      columns = "col-md-12 options-container";

    if (this.props.config.information.columns === 2) columns = "col-md-6";

    if (this.props.config.information.expander)
      return (
        <Accordion>
          <AccordionItem title="Specifications">
            <div className="row information">
              {materials.props.length === 0
                ? informationNoMats(columns, nodes, materials, info, baseModel)
                : information(columns, nodes, materials, info, baseModel)}
            </div>
          </AccordionItem>
        </Accordion>
      );
    else
      return (
        <div>
          <div className="col-md-12 specs-container">
            <h2 className="information-title">{info.title}</h2>
          </div>
          <div>
            {materials.props.children.length === 0
              ? informationNoMats(columns, nodes, materials, info, baseModel)
              : information(columns, nodes, materials, info, baseModel)}
          </div>
        </div>
      );
  }
}

var information = (columns, nodes, materials, info, baseModel) => (
  <div>
    <div className={columns}>
      <h3 className="information-subtitle">{info.options}</h3>
      <div className="information-baseModel">
        <span className="information-lineItem-parentTitle">Base Model:</span>
        <span className="information-lineItem-title">{baseModel}</span>
      </div>
      <div>{nodes}</div>
    </div>
    <div className={columns}>
      <h3 className="information-subtitle">{info.materials}</h3>
      <div>{materials}</div>
    </div>
  </div>
);

var informationNoMats = (columns, nodes, materials, info, baseModel) => (
  <div>
    <div className={columns}>
      <h3 className="information-subtitle">{info.options}</h3>
      <div className="information-baseModel">
        <span className="information-lineItem-parentTitle">Base Model:</span>
        <span className="information-lineItem-title">{baseModel}</span>
      </div>
      <div>{nodes}</div>
    </div>
  </div>
);

class MaterialInformation extends React.Component {
  render() {
    return <div>material</div>;
  }
}

export { MaterialInformation, Information };
