import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoffee } from "@fortawesome/pro-regular-svg-icons";
import axios from "axios";
import "./leadcreator.css";
import Analytics from "../../classes/analytics";
const { v1: uuidv1 } = require("uuid");

// ENVIROMENT VARIABLES
//const ENV = require('../../env');

export default class LeadCreator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      commentChars: 250,
      show: false,
      validated: false,
      setValidated: false,
      // External
      comments: "",
      firstName: "",
      lastName: "",
      email: "",
      organization: "",
      zipcode: "",
      date: "",
      image: "",
      // Internal
      product: "",
      cfgId: "",
      sku: "",
      orgId: "",
      link: "",
    };

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSend = this.handleSend.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.updateCommentCount = this.updateCommentCount.bind(this);
    this.handleRedirect = this.handleRedirect.bind(this);
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps !== this.props) {
      this.setState({
        product: this.props.product,
        cfgId: this.props.config.cfgId,
        orgId: this.props.config.orgId,
        image: this.props.imageUrl,
        sku: this.props.sku,
        date: Date.now(),
      });
    }
  };

  handleInput = (e) => {
    switch (e.target.title) {
      case "email":
        this.setState({ email: e.target.value });
        break;
      case "first":
        this.setState({ firstName: e.target.value });
        break;
      case "last":
        this.setState({ lastName: e.target.value });
        break;
      case "organization":
        this.setState({ organization: e.target.value });
        break;
      case "zip code":
        this.setState({ zipcode: e.target.value });
        break;
      case "comments":
        this.setState({
          comments: e.target.value,
          commentChars: this.updateCommentCount(e, 250),
        });
        break;

      default:
    }
  };

  handleShow = () => {
    this.setState({
      show: true,
      commentChars: 250,
    });
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  handleSend = (e) => {
    // post to database here...
    //axios.post(ENV.api.leads, {
    axios.post(window.TBPM.LEADS_ENDPOINT, {
      data: this.state,
    });

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }

    this.setState({ validated: true });
    this.Analytics = new Analytics(uuidv1());
    this.Analytics.log(LeadAnalytics(this.props));
  };

  handleRedirect = () => {
    // do the redirect
    let sku = this.state.sku.toString().replace(",", ";");
    let imageurl = this.props.images[this.props.imageIndex].ImageURL;
    let url = this.props.config.leadcreator.redirectUrl;
    url = url.replace("[sku]", sku);
    url = url.replace("[imageurl]", imageurl);
    window.open(url);
    this.Analytics = new Analytics(uuidv1());
    this.Analytics.log(LeadAnalytics(this.props, "LEAD"));
  };

  updateCommentCount = (e, max) => {
    return max - e.target.value.length;
  };

  render() {
    if (this.props.config.leadcreator.redirect) {
      return (
        <div
          className="control-option button inquire"
          // onClick={this.handleShow}
          onClick={this.handleRedirect}
          title={this.props.config.leadcreator.text}
        >
          <FontAwesomeIcon
            className="leadcreator-icon"
            icon={faCoffee}
            onClick={this.handleRedirect}
          />
          <span className="control-text">
            {this.props.config.leadcreator.text}
          </span>
        </div>
      );
    }

    return (
      <>
        <div
          className="control-option button inquire"
          onClick={this.handleShow}
          title={this.props.config.leadcreator.text}
        >
          <FontAwesomeIcon className="leadcreator-icon" icon={faCoffee} />
          <span className="control-text">
            {this.props.config.leadcreator.text}
          </span>
        </div>

        <Modal show={this.state.show} onHide={this.handleClose}>
          <Form
            noValidate
            validated={this.state.validated}
            onSubmit={this.handleSend}
          >
            <Modal.Header closeButton>
              <Modal.Title>Inquiring about {this.state.product}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.props.images[1] ? (
                <Image src={this.props.images[1].ImageURL} rounded fluid />
              ) : null}
              <Form.Label>{this.state.sku}</Form.Label>

              <Form.Group controlId="validationCustom01">
                <Row>
                  <Col>
                    <Form.Control
                      required
                      onChange={this.handleInput}
                      placeholder="first name"
                      title="first"
                    />
                  </Col>
                  <Col>
                    <Form.Control
                      onChange={this.handleInput}
                      placeholder="last name"
                      title="last"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Control
                      onChange={this.handleInput}
                      placeholder="organization"
                      title="organization"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Control
                      required
                      onChange={this.handleInput}
                      placeholder="zip code"
                      title="zip code"
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group controlId="validationCustom02">
                <Form.Control
                  required
                  onChange={this.handleInput}
                  type="email"
                  title="email"
                  placeholder="email"
                />
                <Form.Text className="text-muted">
                  We'll never share your email with anyone else.
                </Form.Text>
              </Form.Group>

              <Form.Group controlId="validationCustom03">
                <Form.Text className="text-muted">
                  <b>{this.state.commentChars}</b> characters remaining.
                </Form.Text>
                <Form.Control
                  onChange={this.handleInput}
                  maxLength="250"
                  as="textarea"
                  rows="3"
                  title="comments"
                  placeholder="comments..."
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleClose}>
                Close
              </Button>
              <Button type="submit" variant="primary">
                Send
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}

const LeadAnalytics = (props) => {
  let item = {};
  item.sessionId = props.sessionId;
  item.logContext = "CONTROL";
  item.logType = "INQUIRY";
  item.logItem = "INQUIRY";
  item.org = props.config.org;
  item.orgId = props.config.orgId;
  item.cfgId = props.config.cfgId;
  item.product = props.product;
  item.sku = props.sku;
  item.price = props.price.grandTotal;
  item.currency = props.price.currency;
  return item;
};
