import React from "react";
import "./zoom.css";

class Zoom extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      width: 0,
      height: 0,
    };
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.imageA !== this.props.imageA) {
      this.doZoom();
    }
  };

  componentDidMount() {
    this.setState(
      {
        width: this.props.width,
        height: this.props.height,
      },
      () => {
        this.doZoom();
      }
    );
  }

  doZoom = () => {
    let canvas = document.getElementById("zoomed-image");
    let ctx = canvas.getContext("2d");

    // keep box proporitions...match the height to the width.
    canvas.width = this.state.width;
    canvas.height = this.state.width;
    canvas.style.width = this.state.width.toString() + "px";
    canvas.style.height = this.state.width.toString() + "px";

    let imgA = new Image();
    let imageA = this.props.imageA;

    imgA.onload = () => {
      ctx.drawImage(imgA, 0, 0, this.state.width, this.state.width);
      let script = document.createElement("script");
      script.onload = () => {
        var instance = window.panzoom(canvas, {
          maxZoom: 7,
          minZoom: 1,
          zoomSpeed: 0.3,
        });

        instance.zoomAbs(
          this.state.width / 2, //initial x position
          this.state.height / 2, // initial y position
          3 // initial zoom
        );

        instance.on("panstart", function (e) {
          canvas.style.transitionDuration = "0s";
        });

        instance.on("zoom", function (e) {
          canvas.style.transitionDuration = ".5s";
        });

        this.DRstart(ctx, canvas, imgA);
      };
      script.src = "https://unpkg.com/panzoom@7.1.2/dist/panzoom.min.js";
      document.body.appendChild(script);
    };
    imgA.src = imageA;
  };

  DRstart = (ctx, canvas, orginalImage) => {
    canvas.width = 4096;
    canvas.height = 4096;
    var order = [
      "1,1",
      "2,1",
      "2,2",
      "1,2",
      "0,2",
      "0,3",
      "1,3",
      "2,3",
      "3,3",
      "3,2",
      "3,1",
      "3,0",
      "2,0",
      "1,0",
      "0,0",
      "0,1",
    ];

    ctx.drawImage(orginalImage, 0, 0, 4096, 4096);

    for (let i1 = 0; i1 < order.length; i1++) {
      var x1 = parseInt(order[i1].split(",")[0]);
      var y1 = parseInt(order[i1].split(",")[1]);
      let DrImage = new Image();
      DrImage.myCustomData = { x: x1 * 1024, y: y1 * 1024 };
      DrImage.onload = function () {
        ctx.drawImage(
          DrImage,
          this.myCustomData.x,
          this.myCustomData.y,
          1024,
          1024
        );
      };
      DrImage.src =
        this.props.imageB + "&dr=" + x1.toString() + "," + (3 - y1).toString();
    }
  };

  render() {
    var viewportDims = {
      width: this.state.width,
      height: this.state.height,
    };

    //console.log(viewportDims)

    return (
      <div className="zoom-content" style={viewportDims}>
        <canvas id="zoomed-image" ref="canvas" />
      </div>
    );
  }
}

export default Zoom;
