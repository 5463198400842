import React from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/pro-light-svg-icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Root, Branch } from "../App/forest";
import { Material } from "../Libraries/material";
import "./file.css";

// ENVIROMENT VARIABLES
//const ENV = require('../../env');

//const uuidv1 = require('uuid/v1');
const { v1: uuidv1 } = require("uuid");

class SaveFile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file_uid: "",
      file_name: "",
      file_comments: "",
      file_tags: "",
      file_sku: "",
      file_price: "",
      file_imageUrl: "Image URL",
    };

    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeTags = this.onChangeTags.bind(this);
    this.onChangeComments = this.onChangeComments.bind(this);
  }

  componentDidMount = () => {
    this.setState({
      file_uid: uuidv1(),
    });
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.sku !== this.props.sku) {
      let sku = "";
      this.props.sku.forEach((s) => {
        if (sku === "") sku = s;
        else sku += "," + s;
      });
      this.setState({
        file_sku: sku, //this.props.sku
      });
    }
    if (prevProps.price !== this.props.price) {
      this.setState({
        file_price: this.props.price,
      });
    }
    if (prevProps.imageUrl !== this.props.imageUrl) {
      this.setState({
        file_imageUrl: this.props.imageUrl,
      });
    }
  };

  formatFile = (data) => {
    // Format the incoming data for storage.
    // Main array to hold the sub arrays.
    var dataObject = {};
    // store all of the material objects.
    var materialArray = [];
    // store all of the node (expander) objects.
    var nodeArray = [];

    data.forEach((element) => {
      if (element instanceof Material) {
        // store material data as-is.  It's not recursively built.
        materialArray.push(element);
      }
      if (element instanceof Root) {
        // root data needs to be deconstructed to main elements.imageUrl
        let item = {};
        item.Name = element.Name;
        nodeArray.push(item);
      }
      if (element instanceof Branch) {
        let item = {};
        item.ParentName = element.Nodes.toNode.ParentName;
        item.Name = element.Nodes.toNode.Name;
        nodeArray.push(item);
      }
    });

    dataObject.materials = materialArray;
    dataObject.nodes = nodeArray;

    const newFile = {
      file_uid: this.state.file_uid,
      file_name: this.state.file_name,
      file_org: this.props.org,
      file_group: this.props.group,
      file_ui: this.props.ui,
      file_data: dataObject,
      file_comments: this.state.file_comments,
      file_tags: this.state.file_tags,
      file_sku: this.state.file_sku,
      file_price: this.state.file_price,
      file_imageUrl: this.state.file_imageUrl,
    };

    //console.log(newFile)
    return newFile;
  };

  save = (e) => {
    e.preventDefault();

    //axios.post(ENV.service.path + '/file/save', this.formatFile(this.props.file))
    axios
      .post(
        window.TBPM.UIB_API_URL + "/file/save",
        this.formatFile(this.props.file)
      )
      .then((res) => console.log(res.data));
  };

  onChangeName = (e) => {
    this.setState({ file_name: e.target.value });
  };

  onChangeComments = (e) => {
    this.setState({ file_comments: e.target.value });
  };

  onChangeTags = (e) => {
    this.setState({ file_tags: e.target.value });
  };

  render() {
    let fullLink = window.location.pathname + "/file/" + this.state.file_uid;
    // REMOVE THE IO BIT
    let savedLink = fullLink.replace("/io", "");

    return (
      <form onSubmit={this.save} className="file-save">
        <div className="form-group">
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              value={savedLink}
              disabled={true}
              placeholder="UID"
            />
            <span className="input-group-btn">
              <CopyToClipboard text={savedLink}>
                <button
                  className="btn btn-default"
                  type="button"
                  as="input"
                  id="copy-button"
                  data-toggle="tooltip"
                  data-placement="button"
                  title="Copy to Clipboard"
                >
                  <FontAwesomeIcon icon={faCopy} size="1x" />
                </button>
              </CopyToClipboard>
            </span>
          </div>
        </div>
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            value={this.state.file_sku}
            disabled={true}
            placeholder="Sku"
          />
        </div>
        <div className="form-group">
          <div className="input-group">
            <input
              id="imageURL"
              type="text"
              className="form-control"
              value={this.state.file_imageUrl}
              disabled={true}
              placeholder={this.state.file_imageUrl}
            />
            <span className="input-group-btn">
              <CopyToClipboard text={this.state.file_imageUrl}>
                <button
                  className="btn btn-default"
                  type="button"
                  as="input"
                  id="copy-button"
                  data-toggle="tooltip"
                  data-placement="button"
                  title="Copy to Clipboard"
                >
                  <FontAwesomeIcon icon={faCopy} size="1x" />
                </button>
              </CopyToClipboard>
            </span>
          </div>
        </div>
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            value={this.state.file_name}
            onChange={this.onChangeName}
            placeholder={"Name"}
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            value={this.state.file_tags}
            onChange={this.onChangeTags}
            placeholder={"Tags"}
          />
        </div>
        <div className="form-group">
          <textarea
            type="text"
            className="form-control"
            value={this.state.file_comments}
            onChange={this.onChangeComments}
            placeholder={"Comments"}
          />
        </div>
        <input
          className="btn btn-primary"
          type="submit"
          value="Save Configuration"
        />
      </form>
    );
  }
}

class LoadFile extends React.Component {
  render() {
    return <div></div>;
  }
}

export { SaveFile, LoadFile };
