import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "./product.css";

class AllGroupingsByUI extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
    };
  }

  componentWillMount = () => {
    axios
      .get(window.TBPM.UIB_API_URL + this.props.match.url)
      .then((response) => {
        this.setState({ data: response.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    if (this.state.data === []) return null;

    return (
      <ul className="groupings">
        {this.state.data.map((p) => (
          <li key={p.grouping_uid}>
            <Link to={"/group/" + p.grouping_uiGUID + "/" + p.grouping_uid}>
              {p.grouping_name}
            </Link>
          </li>
        ))}
      </ul>
    );
  }
}

class AllGroupingsByUIAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
    };
  }

  componentWillMount = () => {
    axios
      .get(window.TBPM.UIB_API_URL + this.props.match.url.replace("/admin", ""))
      .then((response) => {
        this.setState({ data: response.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    if (this.state.data === []) return null;

    return (
      <ul className="groupings">
        {this.state.data.map((p) => (
          <li key={p.grouping_uid}>
            <Link to={"/group/" + p.grouping_uiGUID + "/" + p.grouping_uid}>
              <b>{p.grouping_name}</b>
            </Link>
            &nbsp; | &nbsp;
            <a
              href={
                window.TBPM.UIB_API_URL + "/grouping/publish/" + p.grouping_uid
              }
            >
              <i>publish JSON</i>
            </a>
            &nbsp; | &nbsp;
            <a
              href={
                "/host.html?tbpmurl=" +
                window.TBPM.ENV_URL +
                "&entry=/group/" +
                p.grouping_uiGUID +
                "/" +
                p.grouping_uid
              }
            >
              <i>view in object link</i>
            </a>
          </li>
        ))}
      </ul>
    );
  }
}

class AllProductsByUI extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
    };
  }

  componentWillMount() {
    axios
      .get(window.TBPM.UIB_API_URL + this.props.match.url)
      .then((response) => {
        this.setState({ data: response.data.products });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    if (this.state.data === []) return null;

    return (
      <ul className="products">
        {this.state.data.map((p) => (
          <li key={p.id}>
            <Link
              to={"/product/" + this.props.match.params.uiGUID + "/" + p.id}
            >
              {p.name}
            </Link>
          </li>
        ))}
      </ul>
    );
  }
}

export { AllProductsByUI, AllGroupingsByUI, AllGroupingsByUIAdmin };
