let getSkuArray = function getSkuArray(data) {
  //console.log(data);
  var finalSkuArray = [];

  // default for models without piArr
  if (!data.piArr || data.piArr === "") data.piArr = "0";

  let piArr = data.piArr.split(",");
  var validSku = true;

  // loop at each pi in the array
  piArr.forEach((pi) => {
    var productCodeArray = [];
    var newProductCodeArray = [];
    var optionsArray = [];
    var tempOptionsArray = [];
    var skuArray = [];
    var tmpArray = [];
    var nodes;
    var materials;

    // add sku at root only for pi=0
    if (pi === "0") {
      skuArray.push(data.sku);
      tmpArray = data.sku.split("_");
      tmpArray.forEach((item) => {
        addSKUtoArr(item);
      });
    }

    // filter the corresponding nodes and materials
    nodes = data.nodes.filter((node) => node.pi === pi);
    materials = data.materials.filter((material) => material.pi === pi);

    //LOOK FOR SKUS IN NODE ARRAY
    if (nodes.length > 0) {
      nodes.forEach((item) => {
        if (item.hasOwnProperty("sku")) {
          if (item.sku.length > 0) {
            tmpArray = item.sku.split("_");
            tmpArray.forEach((item) => {
              addSKUtoArr(item);
            });
          }
        }
      });
    }

    //LOOK FOR SKUS IN MATERIALS ARRAY
    if (materials.length > 0) {
      materials.forEach((item) => {
        if (item.hasOwnProperty("sku")) {
          if (item.sku.length > 0) {
            // check for skus missing code after dash
            var checkArr = [];
            checkArr = item.sku.split("-");
            if (!checkArr[1]) validSku = false;
            tmpArray = item.sku.split("_");
            tmpArray.forEach((item) => {
              item = item.replace(/\./g, "^"); //workaround for Pallas codes that contain periods
              addSKUtoArr(item);
            });
          }
        }
      });
    }

    //REMOVE P From Product Code ARR
    for (let i = 0; i < productCodeArray.length; i++) {
      productCodeArray[i] = productCodeArray[i].substr(1);
    }

    //REMOVE NP From New Product Code ARR
    for (let i = 0; i < newProductCodeArray.length; i++) {
      newProductCodeArray[i] = newProductCodeArray[i].substr(2);
    }

    //add the sub options together
    tempOptionsArray.sort(alphaNumericSort);

    var tempOptionsArrayCut = [];
    for (let i = 0; i < tempOptionsArray.length; i++) {
      var oString = tempOptionsArray[i];
      for (let j = i + 1; j < tempOptionsArray.length; j++) {
        if (
          tempOptionsArray[i].split("-")[0].trimRight(trimCharacters) ===
          tempOptionsArray[j].split("-")[0].trimRight(trimCharacters)
        ) {
          i++;
          for (let k = 1; k < tempOptionsArray[j].split("-").length; k++) {
            if (k === 1) {
              oString = oString + tempOptionsArray[j].split("-")[k];
            } else {
              oString = oString + "-" + tempOptionsArray[j].split("-")[k];
            }
          }
        }
      }
      tempOptionsArrayCut.push(oString);
    }

    //REMOVE empty space  From Options ARR
    for (let i = 0; i < tempOptionsArrayCut.length; i++) {
      if (tempOptionsArrayCut[i].split("-")[1] !== "") {
        optionsArray.push(tempOptionsArrayCut[i]);
      }
    }

    //sort all
    productCodeArray.sort(alphaNumericSort);
    newProductCodeArray.sort(alphaNumericSort);
    optionsArray.sort(alphaNumericSort);

    //build product code
    var productCode = "";
    for (let i = 0; i < productCodeArray.length; i++) {
      for (let k = 1; k < productCodeArray[i].split("-").length; k++) {
        if (k === 1) {
          productCode = productCode + productCodeArray[i].split("-")[k];
        } else {
          productCode = productCode + "-" + productCodeArray[i].split("-")[k];
        }
      }
    }

    //build options
    for (let i = 0; i < optionsArray.length; i++) {
      var TOption = "";
      for (let k = 1; k < optionsArray[i].split("-").length; k++) {
        if (k === 1) {
          TOption = TOption + optionsArray[i].split("-")[k];
        } else {
          TOption = TOption + "-" + optionsArray[i].split("-")[k];
        }
      }
      productCode = productCode.toUpperCase() + "." + TOption.toUpperCase();
    }

    // add main product sku to output array
    finalSkuArray.push(productCode.toUpperCase());

    //build new product codes
    var newProductCodes = [];
    for (let i = 0; i < newProductCodeArray.length; i++) {
      for (let k = 1; k < newProductCodeArray[i].split("-").length; k++) {
        if (k === 1) {
          newProductCodes.push(
            newProductCodeArray[i].split("-")[k].toUpperCase()
          );
        } else {
          newProductCodes.push(
            newProductCodeArray[i].split("-")[k].toUpperCase()
          );
        }
      }
    }

    // add additional product skus to output array
    for (let i = 0; i < newProductCodes.length; i++) {
      finalSkuArray.push(newProductCodes[i]);
    }

    function addSKUtoArr(item) {
      var item2 = item;
      if (item2.charAt(0).toLowerCase() === "p") {
        productCodeArray.push(item2.replace("~", "_"));
      } else if (
        item2.charAt(0).toLowerCase() === "n" &&
        item2.charAt(1).toLowerCase() === "p"
      ) {
        newProductCodeArray.push(item2.replace("~", "_"));
      } else {
        tempOptionsArray.push(item2.replace("~", "_"));
      }
    }
  }); // end of pi loop

  var tempArr = [];
  for (let n = 0; n < data.nodes.length; n++) {
    if (data.nodes[n] !== "") tempArr.push(parseInt(data.nodes[n].flex2));
  }
  var ignoreArr = tempArr.filter(Boolean);

  for (let i = 0; i < ignoreArr.length; i++) {
    finalSkuArray.splice(ignoreArr[i], 1);
  }

  let response = { validSku: validSku };
  response.skuArray = finalSkuArray.filter(Boolean);
  //console.log(response);
  return response;
};

// eslint-disable-next-line no-extend-native
String.prototype.trimRight = function (charlist) {
  if (charlist === undefined) charlist = "s";
  return this.replace(new RegExp("[" + charlist + "]+$"), "");
};

var trimCharacters = "abcdefghijklmnopqrstuvwxyz";

//ALPHANUMERIC SORTING
function alphaNumericSort(a, b) {
  if (a !== b)
    for (var i, ca, cb = 1, ia = 0, ib = 0; cb; ) {
      ca = a.charCodeAt(ia++) || 0;
      cb = b.charCodeAt(ib++) || 0;
      if (ca < 58 && ca > 47 && cb < 58 && cb > 47) {
        for (i = ia; (ca = a.charCodeAt(ia)), ca < 58 && ca > 47; ia++);
        ca = (a.slice(i - 1, ia) | 0) + 1;
        for (i = ib; (cb = b.charCodeAt(ib)), cb < 58 && cb > 47; ib++);
        cb = (b.slice(i - 1, ib) | 0) + 1;
      }
      if (ca !== cb) return ca < cb ? -1 : 1;
    }
  return 0;
}

export default getSkuArray;
