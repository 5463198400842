import React from "react";
import "./pricer.css";

class Pricer extends React.Component {
  handleQuantityLine = (p) => {
    if (
      this.props.config.pricer.showQuantityWhenGreaterThanOne &&
      p.quantity > 1
    ) {
      return true;
    }

    return false;
  };

  numberWithCommas = (x) => {
    if (this.props.config.pricer.showCommasForThousands)
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    else return x;
  };

  render() {
    // do not render component if hideprice flag in model is true
    if (this.props.hidePrice) return null;

    // display error if pricing response is not successful
    if (this.props.pricingData && !this.props.pricingData.success) {
      return (
        <div className="pricer-grand-total">
          <div className="pricer-lineitem">
            <div className="pricer-caption">
              {this.props.config.pricer.caption3}
            </div>
            <div className="pricer-item">
              <span className="pricer-currency">
                {this.props.config.pricer.currency}
              </span>
              <span className="pricer-value">
                {this.props.config.pricer.error}
              </span>
            </div>
          </div>
        </div>
      );
    }

    if (this.props.pricingData && !this.props.config.pricer.summarize)
      return (
        <div className="pricer">
          {this.props.pricingData.prices.map((p, index) => (
            <div className="pricer-product" key={index}>
              {this.props.config.sku.show ? (
                <div className="pricer-sku">{p.sku}</div>
              ) : null}
              {this.props.config.pricer.caption1 !== "" ? (
                <div className="pricer-lineitem">
                  <div className="pricer-caption">
                    {this.props.config.pricer.caption1}
                  </div>
                  <div className="pricer-item">
                    <span className="pricer-currency">
                      {this.props.config.pricer.currency}
                    </span>
                    <span className="pricer-value">
                      {p.basePrice
                        ? this.numberWithCommas(p.basePrice)
                        : this.props.config.pricer.error}
                    </span>
                  </div>
                </div>
              ) : null}
              {this.props.config.pricer.caption2 !== "" ? (
                <div className="pricer-lineitem">
                  <div className="pricer-caption">
                    {this.props.config.pricer.caption2}
                  </div>
                  <div className="pricer-item">
                    <span className="pricer-currency">
                      {this.props.config.pricer.currency}
                    </span>
                    <span className="pricer-value">
                      {p.asConfiguredPrice
                        ? this.numberWithCommas(p.asConfiguredPrice)
                        : this.props.config.pricer.error}
                    </span>
                  </div>
                </div>
              ) : null}
              {/*                             {(this.props.config.pricer.caption3 !== "") ?
                                <div className="pricer-lineitem">
                                    <div className="pricer-caption">
                                        {this.props.config.pricer.caption3}
                                    </div>
                                    <div className="pricer-item">
                                        <span className="pricer-currency">{this.props.config.pricer.currency}</span>
                                        <span className="pricer-value">{(p.asConfiguredPrice) ? p.asConfiguredPrice : this.props.config.pricer.error}</span>

                                    </div>
                                </div> : null
                            } */}

              {
                // Quantity feature
                this.props.config.pricer.caption4 !== "" &&
                this.handleQuantityLine(p) ? (
                  <div className="pricer-lineitem">
                    <div className="pricer-caption">
                      {this.props.config.pricer.caption4}
                    </div>
                    <div className="pricer-item">
                      <span>{p.quantity}</span>
                    </div>
                  </div>
                ) : null
              }
            </div>
          ))}
          {this.props.pricingData.prices.length > 1 ? (
            <div className="pricer-grand-total">
              <div className="pricer-lineitem">
                <div className="pricer-caption">
                  {this.props.config.pricer.caption3}
                </div>
                <div className="pricer-item">
                  <span className="pricer-currency">
                    {this.props.config.pricer.currency}
                  </span>
                  <span className="pricer-value">
                    {this.props.pricingData.grandTotal
                      ? this.numberWithCommas(this.props.pricingData.grandTotal)
                      : this.props.config.pricer.error}
                  </span>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      );

    if (this.props.pricingData && this.props.config.pricer.summarize)
      return (
        <div className="pricer-grand-total">
          <div className="pricer-lineitem">
            <div className="pricer-caption">
              {this.props.config.pricer.caption3}
            </div>
            <div className="pricer-item">
              <span className="pricer-currency">
                {this.props.config.pricer.currency}
              </span>
              <span className="pricer-value">
                {this.props.pricingData.grandTotal
                  ? this.numberWithCommas(this.props.pricingData.grandTotal)
                  : this.props.config.pricer.error}
              </span>
            </div>
          </div>
        </div>
      );
    return null;
  }
}

export default Pricer;
