import React from 'react';
import './title.css';

class Title extends React.Component {
    render() {
        return (
            <div className="ui-title">
                <h3>{this.props.title}</h3>
            </div>
        );
    }
}

export default Title;