import React from "react";
import axios from "axios";
import { Branch } from "../App/forest";
import { Material } from "../Libraries/material";
import IO from "../IO/io";
import MachineCoreAPI from "../MachineCore/machinecoreAPI";
import Analytics from "../../classes/analytics";
// REDUX
import store from "../../Redux/store";

// ENVIROMENT VARIABLES
//const ENV = require('../../env');

//const uuidv1 = require('uuid/v1');
const { v1: uuidv1 } = require("uuid");

class Download extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
    };

    // Downloadable Formats.  TODO --> These need to be moved to a different place and
    // register themselves to the download class.  Additionally, the download class probably
    // should be separate from the control because it is not necessarily bound to the
    // control class.
    this.pdf = this.pdf.bind(this);
    this.jpg = this.jpg.bind(this);
    this.png = this.png.bind(this);
    this.sif = this.sif.bind(this);
    this.tiff = this.tiff.bind(this);
    // app functions.
    this.IO = new IO();
    this.MCORE = new MachineCoreAPI();
  }

  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  download = (e, option) => {
    e.stopPropagation();
    // tell the parent i've been clicked.
    this.props.download();
    option = option.toUpperCase();
    switch (option) {
      case "PDF": {
        this.pdf();
        break;
      }
      case "JPG": {
        this.jpg(this.props.image);
        break;
      }
      case "PNG": {
        this.png(this.props.image);
        break;
      }
      case "TIFF": {
        this.tiff(this.props.image);
        break;
      }
      case "SIF": {
        this.sif();
        break;
      }
      case "JPG (LOW RES)": {
        //low res jpg download MG 1/28/2020
        this.jpg(this.props.lowresimage);
        break;
      }
      case "JPG (HIGH RES)": {
        //high res jpg download
        this.jpg(this.props.image);
        break;
      }
      case "PNG (HIGH RES)": {
        this.png(this.props.lowresimage);
        break;
      }
      case "PNG (HIGH RES)": {
        this.png(this.props.image);
        break;
      }
      default:
        break;
    }
  };

  pdf = () => {
    let _path = store.getState().path.slice(-1)[0];

    // Inform the product app that we're downloading...
    this.props.onDownload("PDF");

    var pdfData = {};

    pdfData.selections = {};
    pdfData.selections.nodes = [];
    pdfData.selections.materials = [];
    pdfData.images = [];
    pdfData.pricing = this.props.price;

    /*         this.props.images.forEach((src, i) => {
                    this.props.config.PDF.images.forEach((dest) => {
                        if (i === dest.frame) // match
                            pdfData.images.push(src);
                    });
                }); */

    pdfData.images = this.props.images;

    /*         this.props.images.forEach((src, i) => {
                    this.props.config.PDF.images.forEach((dest) => {
                        if (src.View === dest.view) // match
                            pdfData.images.push(src);
                    });
                }); */

    // empty array to store the incoming materials.
    var tMaterials = [];
    _path.forEach((item) => {
      // Materials are always first, so the array will be built prior to branches.
      if (item instanceof Material) {
        var material = {};
        material.Text = item.Text;
        material.MatGroup = item.MatGroup;
        tMaterials.push(material);

        //console.log(tMaterials)
      }

      if (item instanceof Branch) {
        //Messy code, but will give the pdf a reference to pi at the root
        var pi = item.Nodes.toNode.Pi;

        if (item.Nodes.toNode.Pi === "" || item.Nodes.toNode.Pi === undefined) {
          pi = "0";
        }

        //MG - Changed toNode.Type to toNode.Class. Class is either Node or Material, Type can be static, renderable, etc
        if (
          item.Nodes.toNode.Class === "NODE" &&
          item.Nodes.toNode.Hidden !== "true"
        ) {
          //MG don't show options in PDF if they have the hidden tag

          pdfData.selections.nodes.push({
            parentTitle: item.Nodes.toNode.ParentText,
            title: item.Nodes.toNode.Text,
            name: item.Nodes.toNode.Name,
            pi: pi,
          });
        }

        if (
          item.Nodes.toNode.Class === "MATERIAL" &&
          item.Nodes.toNode.Hidden !== "true"
        ) {
          //MG don't show options in PDF if they have the hidden tag
          //MG - Added a check to treat dynamic (DB) materials different from model materials
          //TODO Need to add support for matgroups that contrain both renderable and dynamic materials
          //console.log(item.Nodes.toNode)

          //Checking for DB Materials instead of material of Dynamic type.
          if (item.Nodes.toNode.Type === "dynamic") {
            tMaterials.forEach((m) => {
              if (m.MatGroup === item.Nodes.toNode.ParentName) {
                pdfData.selections.materials.push({
                  parentTitle: item.Nodes.toNode.ParentText,
                  title: m.Text,
                  pi: pi,
                });
              }
            });
          }

          //check if it's renderable or static
          else if (
            item.Nodes.toNode.Type === "renderable" ||
            item.Nodes.toNode.Type === "static"
          ) {
            //dynamics exist
            if (tMaterials.length > 0) {
              var tempMat;

              //check if the renderable matches a dynamic
              tMaterials.forEach((m) => {
                if (m.MatGroup === item.Nodes.toNode.ParentName) {
                  tempMat = m;
                }
              });

              //match (push info from dynamic)
              if (tempMat !== undefined) {
                pdfData.selections.materials.push({
                  parentTitle: item.Nodes.toNode.ParentText,
                  title: tempMat.Text,
                  pi: pi,
                });
              }

              //no match (push info from model)
              else {
                pdfData.selections.materials.push({
                  parentTitle: item.Nodes.toNode.ParentText,
                  title: item.Nodes.toNode.Text,
                  pi: pi,
                });
              }
            }

            //renderable but no dynamics exists. Get info from model
            else {
              pdfData.selections.materials.push({
                parentTitle: item.Nodes.toNode.ParentText,
                title: item.Nodes.toNode.Text,
                pi: pi,
              });
            }
          }

          //everything else, (static)
          else {
            pdfData.selections.materials.push({
              parentTitle: item.Nodes.toNode.ParentText,
              title: item.Nodes.toNode.Text,
              pi: pi,
            });
          }

          // //ORIGINAL CODE
          // if (item.Nodes.toNode.Type === "dynamic") {
          //     tMaterials.forEach(m => {

          //         if (m.MatGroup === item.Nodes.toNode.ParentName) {
          //             pdfData.selections.materials.push({
          //                 parentTitle: item.Nodes.toNode.ParentText,
          //                 title: m.Text,
          //                 pi: pi
          //             });
          //         }
          //     })
          // }

          // else {
          //     pdfData.selections.materials.push({
          //         parentTitle: item.Nodes.toNode.ParentText,
          //         title: item.Nodes.toNode.Text,
          //         pi: pi
          //     });
          // }

          // //ORIGINAL CODE
        }
      }
    });

    // Get the data from the machineCore assembly to pass into the service
    //const _mCoreData = this.MCORE.addNodes(this.props.config, this.props.product);
    pdfData.config = this.MCORE.addNodes(this.props.config);

    //MT - Convert piArr attribute to array or set as a one element array with zero value if empty
    if (pdfData.config.piArr.length === 0) {
      pdfData.config.piArr = ["0"];
    } else {
      pdfData.config.piArr = pdfData.config.piArr.split(",");
    }

    this.Analytics = new Analytics(uuidv1());
    this.Analytics.log(DownloadAnalytics(this.props, pdfData.config, "PDF"));

    axios({
      method: "post",
      // url: ENV.service.download + '?type=PDF',
      url: window.TBPM.DOWNLOAD_ENDPOINT + "?type=PDF",
      data: pdfData,
      responseType: "text",
    }).then((response) => {
      // return the download URL to the product.js
      this.props.onDownload("PDF", response.data.url);
    });
  };

  jpg = (imageRes) => {
    this.props.onDownload("JPG");
    var jpgData = {
      imageUrl: imageRes, //low or high res image? //WAS this.props.image, MG 1/28/2020
      images: this.props.images,
      product: this.props.product,
      config: this.MCORE.addNodes(this.props.config, null),
      pricing: this.props.price,
    };

    this.Analytics = new Analytics(uuidv1());
    this.Analytics.log(DownloadAnalytics(this.props, jpgData.config, "JPG"));

    axios({
      method: "post",
      //url: ENV.service.download + '?type=JPG',
      url: window.TBPM.DOWNLOAD_ENDPOINT + "?type=JPG",
      data: jpgData,
      responseType: "text",
    }).then((response) => {
      this.props.onDownload("JPG", response.data.url);
    });
  };

  png = (imageRes) => {
    this.props.onDownload("PNG");

    var data = {
      imageUrl: imageRes, //low or high res image? //WAS this.props.image, MG 1/28/2020
      images: this.props.images,
      product: this.props.product,
      config: this.MCORE.addNodes(this.props.config, null),
      pricing: this.props.price,
    };

    this.Analytics = new Analytics(uuidv1());
    this.Analytics.log(DownloadAnalytics(this.props, data.config, "PNG"));

    axios({
      method: "post",
      //url: ENV.service.download + '?type=png',
      url: window.TBPM.DOWNLOAD_ENDPOINT + "?type=png",
      data: data,
      responseType: "text",
    }).then((response) => {
      this.props.onDownload("PNG", response.data.url);
    });
  };

  tiff = (imageRes) => {
    this.props.onDownload("TIFF");

    var data = {
      imageUrl: imageRes, //low or high res image? //WAS this.props.image, MG 1/28/2020
      images: this.props.images,
      product: this.props.product,
      config: this.MCORE.addNodes(this.props.config, null),
      pricing: this.props.price,
    };

    this.Analytics = new Analytics(uuidv1());
    this.Analytics.log(DownloadAnalytics(this.props, data.config, "TIF"));

    axios({
      method: "post",
      //url: ENV.service.download + '?type=tiff',
      url: window.TBPM.DOWNLOAD_ENDPOINT + "?type=tiff",
      data: data,
      responseType: "text",
    }).then((response) => {
      this.props.onDownload("TIFF", response.data.url);
    });
  };

  sif = () => {
    // callback to the parent --> download has been actuated.
    this.props.onDownload("SIF");
    // Get the data from the machineCore assembly to pass into the service
    var data = {};
    //   console.log(this.props.config);
    //  console.log(this.props.product);
    const _mCoreData = this.MCORE.addNodes(this.props.config, null);
    //  console.log(_mCoreData);
    data.cfgId = _mCoreData.guid;
    data.rootnode = _mCoreData.rootnode;
    data.nodes = _mCoreData.nodes;
    data.materials = _mCoreData.materials;
    data.sku = _mCoreData.sku;
    data.text = _mCoreData.text;
    data.name = _mCoreData.name;
    data.orgId = _mCoreData.orgId;
    data.piArr = ["0"];
    data.product = this.props.product;
    data.plid = _mCoreData.plid;
    data.spid = _mCoreData.spid;
    data.pricing = this.props.price;

    this.Analytics = new Analytics(uuidv1());
    this.Analytics.log(DownloadAnalytics(this.props, data, "SIF"));

    axios({
      method: "post",
      //url: ENV.service.download + '?type=SIF',
      url: window.TBPM.DOWNLOAD_ENDPOINT + "?type=SIF",
      data: data,
      responseType: "text",
    }).then((response) => {
      var text = new Blob([response.data.sif], { type: "octet/stream" }),
        url = window.URL.createObjectURL(text);
      var link = document.createElement("a");
      document.body.appendChild(link);
      link.href = url;
      link.target = "_blank";
      //target filename
      link.download = response.data.sku + ".SIF";
      link.click();
      link.remove();
      // toggle download screen...this removes it.
      this.props.onDownload();
    });
  };
  render() {
    return (
      <div className="download-container">
        {this.props.config.downloads.map((download) => (
          <div
            key={download}
            title={download}
            className={download}
            onClick={(e) => this.download(e, download)}
          >
            <span>
              {this.props.text} {download}
            </span>
          </div>
        ))}
      </div>
    );
  }
}

const DownloadAnalytics = (props, data, logItem) => {
  let item = {};
  item.sessionId = props.sessionId;
  item.logContext = "CONTROL";
  item.logType = "DOWNLOAD";
  item.logItem = logItem;
  item.org = props.config.org;
  item.orgId = props.config.orgId;
  item.cfgId = props.config.cfgId;
  item.product = props.product;
  item.plid = data.plid;
  item.spid = data.spid;
  item.sku = props.sku;
  item.price = props.price ? props.price.grandTotal : null;
  item.currency = "USD";
  return item;
};

export default Download;
