import React from 'react';
import './filter.css';

class Filters extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            filter: null,
            filters: [],
            activeFilters: []
        }
    }

    // This controls what filters are shown and actuated by UI
    handleFilter = (filter) => {

        let filters = Object.assign([], this.state.activeFilters);

        if (filter.state.selected) {
            var index = filters.indexOf(filter);
            if (index > -1)
                filters.splice(index, 1);
        }
        else
            filters.push(filter);

        this.setState({
            filter: filter,
            activeFilters: filters
        });

        this.props.updateSwatches(filter);
    }

    render() {

        let filters = [];

        this.props.filterData.values.forEach((value, i) => {

            // if searchArray is undefined, default to the str.
            var visible = false;
            if (this.props.currentMaterials === []) {
                visible = true;
            }
            else {
                this.props.currentMaterials.forEach(material => {

                    if (material.props.filters !== null) { //checking that filters exist -MG 12/19/2019

                        material.props.filters.forEach(f => {
                            if (f.value !== null) {
                                if (f.value[0] === value) {
                                    visible = true;
                                }
                            }
                        })
                    }
                })




            }

            filters.push(
                <Filter
                    key={i}
                    title={this.props.filterData.title}
                    filterKey={this.props.filterData.key}
                    parent={this.props.title}
                    text={value}
                    data={this.props.filterData}
                    search={value}
                    handleFilter={this.handleFilter.bind(this)}
                    active={(this.state.activeFilters.length === 0)}
                    visible={visible}
                />
            )
        })

        return (
            <ul className="swatch-filters">
                {filters}
            </ul>
        );
    };
}

class Filter extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: false
        };

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick = (e) => {
        // toggle
        this.setState({ selected: !this.state.selected })
        this.props.handleFilter(this);
    }

    render() {

        // if (!this.props.visible)
        //return null;
        let visible = (this.props.visible) ? "" : "hidden";

        // Change the CSS depending on state
        const cls = (this.state.selected) ? "swatch-filter active" : "swatch-filter " + visible;
        // render each filter

        if (!this.state.selected && !this.props.visible)
            return (
                <li
                    className={"swatch-filter in-active"}>
                    {this.props.text}
                </li>
            )

        return (
            <li
                className={cls}
                onClick={this.handleClick}>
                {this.props.text}
            </li>
        )

    }
}

export { Filters, Filter };