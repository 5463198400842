import React from "react";
import Axios from "axios";
//import "@google/model-viewer";
//import * as BabylonViewer from "@babylonjs/viewer";
import MachineCoreAPI from "../MachineCore/machinecoreAPI";
//import store from "../../Redux/store";
//import { useGLTF } from "@react-three/drei";
//import { Canvas } from "@react-three/fiber";
//import ShowModel from "./showModel";
//const { v1: uuidv1 } = require("uuid");
import Bowser from "bowser";
import "./model-viewer.css";
const vm = require("vm");

class Viewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ua: Bowser.parse(window.navigator.userAgent),
      generating: false,
      //  modelUrl:
      //    "https://uibuilderqa.blob.core.windows.net/temp/34585662-b618-42e8-acd1-06cde6a0fd9f.glb",

      modelUrl: "",
      message: "",
      error: null,
      success: false,
      linkName: "",
    };

    this.MCORE = new MachineCoreAPI();
    this.generateModel = this.generateModel.bind(this);
  }

  componentDidMount = () => {
    /*     console.log(this.props.config);
    const _ThreeDReducerData = this.Reducer.addNodes(this.props.config, null);
    console.log(_ThreeDReducerData); */
  };

  componentDidUpdate = (prevProps) => {};

  generateModel = () => {
    this.setState({
      message: "Please wait..",
      generating: true,
      linkName: "",
      modelUrl: "",
    });

    // GET POST DATA
    this.MCORE = new MachineCoreAPI();
    const postData = this.MCORE.addNodes(
      this.props.config,
      this.props.productObj
    );
    Axios.get(
      `${window.TBPM.BLOB_URL}/${this.props.config.cfgId}/rules3d.js`,
      {}
    ).then((response) => {
      const rules3d = new vm.Script(response.data);
      rules3d.runInNewContext(postData);
      var imageUrl =
        this.props.images.length > 0 ? this.props.images[0].ImageURL : null;
      let objUrl = imageUrl
        ? imageUrl.replace("req=render", "req=3djsonkv")
        : null;
      postData.objUrl = objUrl;
      postData.outputFormat = "glb";
      postData.modelView = true;

      Axios.post(window.TBPM.THREE_D_ENDPOINT, {
        data: postData,
        responseType: "json",
      })
        .then((response) => {
          //   console.log(response);
          if (response.data.success) {
            this.setState({
              generating: false,
              success: true,
              modelUrl: response.data.url,
              message: "",
              linkName: "View Model",
            });
          } else {
            this.setState({
              generating: false,
              success: false,
              message: "ERROR",
              linkName: "",
            });
          }
        })
        .catch((err) => {
          this.setState({
            generating: false,
            success: false,
            message: "ERROR",
            linkName: "",
          });
        });
    });
  };

  render() {
    return (
      <div className="model-viewer-container">
        <div className="model-viewer-button">
          <button
            className="three-d-button"
            slot="ar-button"
            id="ar-button"
            onClick={this.generateModel}
          >
            Generate 3D Model
          </button>
          {this.state.message}{" "}
          <div className="model-viewer-link">
            <a
              href={this.state.modelUrl}
              rel="noopener noreferrer"
              target="_blank"
            >
              {this.state.linkName}
            </a>
          </div>
        </div>
      </div>
    );
  }
}

/* const analytics = (props, data) => {
  let item = {};
  item.sessionId = props.sessionId;
  item.logContext = "CONTROL";
  item.logType = "AR";
  item.logItem = "AR";
  item.deviceType = "mobile";
  item.osName = this.props.ua.os.name;
  item.osVersion = this.props.ua.os.version;
  item.org = props.config.org;
  item.orgId = props.config.orgId;
  item.cfgId = props.config.cfgId;
  item.product = props.product;
  item.plid = data.plid;
  item.spid = data.spid;
  item.sku = props.sku;
  item.price = !props.price ? null : props.price.grandTotal;
  item.currency = !props.price ? null : props.price.currency;
  return item;
}; */

export default Viewer;
