import React from 'react';

export default class ZoomAndPan extends React.Component {

    zoom = () => {
        this.props.onZoom();
    }

    render() {
        return (
            <div className="control-option button zoom" onClick={this.zoom.bind(this)} title="zoom">
                <img className="control-icon" width="25" height="25" src="/assets/zoom.svg" alt="" />
            </div>
        )
    }
}