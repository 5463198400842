import React from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import './merchandiser.css';
// ENVIROMENT VARIABLES
//const ENV = require('../../env');

const Items = (props) => {
    return (<div className="merchandiser-items">{props.items.map((item, index) =>
        <Item
            key={index}
            select={props.select}
            item={item}
        />)}
    </div>);
}

class Item extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            price: 'Price Not Found.'
        }
    }

    componentDidMount = () => {
        //axios.get(ENV.api.skuPricer, {
        axios.get(window.TBPM.SKU_PRICER_ENDPOINT, {
            "params" : {
                orgId: 'b288a4a8-2ab4-11e7-93ae-92361f002671',
                sku:'ABC.123.456'
            }
        })
        .then(response => {
            this.setState({
                price: '$' + response.data.asConfiguredPrice    
            })
        })
    }

    select = () => {
        this.props.select(this);
    }

    render() {
        return (
            <Link to={this.props.item.path}>
                <div className="merchandiser-item-container">
                    <div className="merchandiser-item" onClick={this.select.bind(this)}>
                        <div className="merchandiser-item-image"><img src={this.props.item.image.url} alt="" /></div>
                        <div className="merchandiser-item-title">{this.props.item.title}</div>
                        <div className="merchandiser-item-subtitle">{this.props.item.subtitle}</div>
                        <div className="merchandiser-item-description">{this.props.item.description}</div>
                        {(this.props.item.showPrice) ? <div className="merchandiser-item-price">{this.state.price}</div> : null}
                    </div>
                </div>
            </Link>
        )
    }
}

export default Items;