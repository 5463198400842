import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import './pantones.css';

// ENVIROMENT VARIABLES
//const ENV = require('../../env');

var hexToColorName = require("hex-to-color-name");

class PantoneModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null,
            filteredData: null,
        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.show !== this.props.show)
            this.getData();
    }

    close = () => {
        this.props.showModal(false);
    }

    getData = () => {
        // Get the pantone colors..
        axios.get(window.TBPM.PANTONE_ENDPOINT)
            .then(res => {
                this.setState({
                    data: res.data,
                    filteredData: res.data
                });
            })
            .catch(error => {
                console.log(error);
            })
    }

    handleClick = (pantone) => {
        this.props.select(pantone);
        this.close();
    }

    onInputChange = (event) => {
        let value = event.target.value;

        // Use the complete data set when filtering...
        let filtered = this.state.data.filter(pantone =>
            pantone.hex.includes(value) ||
            pantone.title.includes(value) ||
            hexToColorName(pantone.hex).includes(value)
        );

        this.setState({
            filteredData: filtered
        });
    }

    render() {
        return (
            <Modal
                size="lg"
                show={this.props.show}
                onHide={this.close.bind(this)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Choose a Pantone®</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <input type="text" onChange={this.onInputChange.bind(this)} className="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm"></input>
                    <div className="pantone-container">
                        <div className="pantone-group">
                            {(this.state.filteredData) ? this.state.filteredData.map((item, index) =>
                                <Pantone
                                    key={index}
                                    pantone={item}
                                    layer={this.props.layer}
                                    parent={this.props.parent}
                                    select={this.handleClick.bind(this)} />
                            ) : <div>loading...</div>}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.close.bind(this)}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }
};

export class Pantone extends React.Component {

    handleClick = () => {
        // Layer, MatGroup, Hex
        this.props.select(this);
    }

    render() {
        return (
            <div
                key={this.props.pantone.title}
                color={hexToColorName(this.props.pantone.hex)}
                className="pantone-color"
                style={{ backgroundColor: this.props.pantone.hex }}
                value={this.props.pantone.hex}
                parent={this.props.parent}
                layer={this.props.layer}
                title={this.props.pantone.title}
                onClick={this.handleClick.bind(this)}>
            </div>
        )
    }
}

export default PantoneModal;