import React from "react";
import "./reset.css";
import Analytics from "../../classes/analytics";
const { v1: uuidv1 } = require("uuid");

export default class Reset extends React.Component {
  reset = () => {
    this.Analytics = new Analytics(uuidv1());
    this.Analytics.log(ResetAnalytics(this.props, "RESET"));
    window.location.reload();
  };
  render() {
    return (
      <div
        className="control-option button reset"
        title="reset"
        onClick={this.reset}
      >
        <div>
          {/* <img className="reset-icon" src="/assets/reset.svg" alt=""></img> */}
          <img
            className="control-icon"
            width="25"
            height="25"
            src="/assets/reset.svg"
            alt=""
          />
          <span className="control-text">Reset</span>
        </div>
      </div>
    );
  }
}

const ResetAnalytics = (props, type) => {
  let item = {};
  item.sessionId = props.sessionId;
  item.type = type;
  item.context = "control";
  item.feature = "RESET";
  item.org = props.config.org;
  item.orgId = props.config.orgId;
  item.cfgId = props.config.cfgId;
  item.product = props.product;
  item.sku = props.sku;
  item.price = props.price ? props.price.grandTotal : null;
  item.currency = props.price ? props.price.currency : null;
  return item;
};
