import React from "react";
import Axios from "axios";
//import ThreeDReducer from "../MachineCore/3dReducer";
import MachineCoreAPI from "../MachineCore/machinecoreAPI";
import Analytics from "../../classes/analytics";
const { v1: uuidv1 } = require("uuid");
const vm = require("vm");

class ARCore extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: null,
      generating: false,
      modelUrl: null,
    };
    //  this.logAnalytics = this.logAnalytics.bind(this);
    this.viewInRoom = this.viewInRoom.bind(this);
    //this.launchViewer = false;
  }

  componentDidMount = () => {};

  componentDidUpdate = (prevProps) => {};

  logAnalytics = () => {
    const _mCoreData = this.MCORE.addNodes(this.props.config, null);
    this.Analytics = new Analytics(uuidv1());
    this.Analytics.log(ARAnalytics(this.props, _mCoreData));
  };

  viewInRoom() {
    this.setState({
      message: "Please wait..",
      generating: true,
      success: true,
      modelUrl: null,
    });

    // GET POST DATA
    this.MCORE = new MachineCoreAPI();
    // this.ThreeDReducer = new ThreeDReducer();
    const postData = this.MCORE.addNodes(
      this.props.config,
      this.props.productObj
    );

    Axios.get(
      `${window.TBPM.BLOB_URL}/${this.props.config.cfgId}/rules3d.js`,
      {}
    ).then((response) => {
      const rules3d = new vm.Script(response.data);
      rules3d.runInNewContext(postData);

      var imageUrl =
        this.props.images.length > 0 ? this.props.images[0].ImageURL : null;
      let objUrl = imageUrl
        ? imageUrl.replace("req=render", "req=3djsonkv")
        : null;
      // postData.scope = "ar";
      postData.objUrl = objUrl;
      //  console.log(postData);
      postData.outputFormat = "glb";
      Axios.post(window.TBPM.THREE_D_ENDPOINT, {
        data: postData,
        responseType: "json",
      })
        .then((response) => {
          let link = document.createElement("a");
          let linkId = uuidv1();
          link.id = linkId;
          link.className = "ar-link";
          /*         let href =
          "intent://arvr.google.com/scene-viewer/1.0?file=https://raw.githubusercontent.com/KhronosGroup/glTF-Sample-Models/master/2.0/Avocado/glTF/Avocado.gltf#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=https://developers.google.com/ar;end;"; */
          // let modelUrl = response.dat
          // console.log(modelUrl);
          //("https://uibuilderqa.blob.core.windows.net/temp/e607983a-3655-4b58-aa72-6f37e22a81c9.glb");
          //console.log(tmpUrl);
          let href = `intent://arvr.google.com/scene-viewer/1.1?file=${response.data.url}&mode=ar_preferred&resizable=false#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=https://developers.google.com/ar;end;`;
          // console.log(href);
          link.href = href;
          var linkText = document.createTextNode("click me");
          link.appendChild(linkText);
          document.body.appendChild(link);
          //  console.log(link);
          document.getElementById(linkId).click();
          this.setState({ generating: false });
          //   window.location = response.data.url;
          this.logAnalytics();
        })
        .catch((err) => {
          // console.log(err);
          this.setState({ message: "Error!!", generating: false });
        });
    });
  }

  /* generateLink() {
    console.log(this.state.modelUrl);
    let link = document.createElement("a");
    let linkId = uuidv1();
    link.id = linkId;
    link.className = "ar-link";
    let modelUrl = this.state.modelUrl;
    //("https://uibuilderqa.blob.core.windows.net/temp/e607983a-3655-4b58-aa72-6f37e22a81c9.glb");
    //console.log(tmpUrl);
    let href = `intent://arvr.google.com/scene-viewer/1.1?file=${modelUrl}#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=https://developers.google.com/ar;end;`;
    console.log(href);
    link.href = href;
    var linkText = document.createTextNode("click me");
    link.appendChild(linkText);
    document.body.appendChild(link);
    console.log(link);
    document.getElementById(linkId).click();
  } */

  render() {
    return (
      <div className="view-in-room">
        <div
          className="view-in-room-button"
          onClick={this.viewInRoom.bind(this)}
          title="View in your space"
        >
          <img
            className="sceneviewer-icon"
            src="/assets/android_ar.png"
            alt="AR"
          />
          &nbsp;&nbsp;View in your space
          {this.state.generating ? <span>&nbsp;&nbsp;Loading...</span> : ""}
        </div>
      </div>
    );
  }
}

const ARAnalytics = (props, data) => {
  let item = {};
  item.sessionId = props.sessionId;
  item.logContext = "CONTROL";
  item.logType = "AR";
  item.logItem = "AR";
  item.deviceType = "mobile";
  item.osName = props.ua.os.name;
  item.osVersion = props.ua.os.version;
  item.org = props.config.org;
  item.orgId = props.config.orgId;
  item.cfgId = props.config.cfgId;
  item.product = props.product;
  item.plid = data.plid;
  item.spid = data.spid;
  item.sku = props.sku;
  item.price = !props.price ? null : props.price.grandTotal;
  item.currency = !props.price ? null : props.price.currency;
  return item;
};

export default ARCore;
