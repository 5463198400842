import React from 'react';
import './slides.css';

class Slides extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            image: null
        };

    }

    changeSlide = (index) => {
        this.props.setImageIndex(index);
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.images !== this.props.images || prevProps.imageIndex !== this.props.imageIndex)
            this.setState({
                image: (this.props.images.length > 0) ? this.props.images[this.props.imageIndex].ImageURL : null
            })
    }


    slides = () => {
        return (<div className="slides col-12">
            {this.props.images.map((image, index) => (<div
                key={index}
                onClick={this.changeSlide.bind(this, index)}
                className="slides-slide">
                <Slide image={image.ImageURL} />
            </div>))}
        </div>);
    }

    render() {

        return (
            <div className="slides-container">
                <img src={this.state.image} alt="" />
                {this.slides()}
            </div>
        )
    }
}

class Slide extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            active: false
        }
    }


    render() {
        const cls = (this.state.active) ? 'slide' : "slide";
        return (<div className={cls}><img src={this.props.image} alt="" /></div>)
    }
}

export default Slides;