import React from "react";
import IO from "../IO/io";
import MachineCoreAPI from "../MachineCore/machinecoreAPI";
import Pagination from "react-js-pagination";
import { Filters } from "./filters";
import { UIMaterial, Material } from "./material";
// STYLING
import "./materials.css";
// REDUX
import { connect } from "react-redux";
import { addHistoryStep, addPathStep } from "../../Redux/actions";
import store from "../../Redux/store";
import { Accordion, AccordionItem } from "react-sanfona";

//const uuidv1 = require('uuid/v1');
const { v1: uuidv1 } = require("uuid");

class Materials extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      materials: [],
      default: null,
      loaded: false,
      activePage: 1,
      firstSwatch: 0, //Changed from 1 to 0 -MG
      lastSwatch: this.props.config.materials.swatchesPerPage,
      maxCount: 0,
      swatchesPerPage: this.props.config.materials.swatchesPerPage,
      pagesToDisplay: this.props.config.pagesToDisplay,
      filteredData: [],
      activeFilters: [],
      activeMaterialIndex: 0, //Changed from 1 to 0 -MG
      filters: [],
      materialFilters: [],
    };

    this._isMounted = false;
    this.io = new IO();
    this.mCore = new MachineCoreAPI();
    this.Id = uuidv1();
  }

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.filteredData !== this.state.filteredData) {
      this.renderFilters(this.state.materialFilters);
    }

    if (prevProps !== this.props) {
      //bug here with rendermaterials()
      this.renderMaterials(() => {
        if (this.state.materials.length === prevState.materials.length) {
          // MG CHECKING IF MATERIALS MATCH (LENGHT)
          //console.log("same lenght")

          this.state.materials.forEach((material) => {
            if (this.getLastMaterialInThisMatGroup()) {
              if (
                material.props.manCode ===
                  this.getLastMaterialInThisMatGroup().Name &&
                material.props.ParentName === this.props.parent
              ) {
                // handle the UI update..
                this.handleMaterialChange(material, null, false);
                // override the default material now with the updated material
                // for material persistency
                this.setState({
                  default: material,
                });
              }
            }
          });
        } else {
          //console.log("not the same lenght")

          // handle the UI update..
          this.handleMaterialChange(this.state.materials[0], null, false);
          // override the default material now with the updated material
          // for material persistency
          this.setState({
            default: this.state.materials[0],
          });
        }

        //can't find a match...reset to the last one selected.
      });

      //Inform the parent that i've been updated.
      this.props.registerLibrary();
    }
  };

  getLastMaterialInThisMatGroup = () => {
    // look for a material in the history that has the same parent
    return store
      .getState()
      .history.slice()
      .reverse()
      .find((material) => material.MatGroup === this.props.parent);
  };

  componentDidMount = () => {
    this.renderMaterials(() => {
      // If we're loading a file, run through the material data and look for a match to the current parent.
      this.state.materials.forEach((material) => {
        // history is not the REDUX history but the saved file history that's incoming.
        if (!this.getLastMaterialInThisMatGroup()) {
          this.props.history.forEach((el) => {
            if (
              material.props.manCode === el.Name &&
              el.MatGroup === this.props.parent
            ) {
              // Set the default material to the incoming...found a match
              this.setState({
                default: material,
              });
              // index to the page where the active swatch is.
              // if the swatch index is less than swatches per page, we need to go to the floor (0) because
              // there is no active pagination.
              let page =
                material.props.index /
                this.props.config.materials.swatchesPerPage;
              // If page is an exact match (i.e., returns an integer) then we need to advance
              // the page to the next one.  Math.ceil will not work correctly on an int.
              if (Number.isInteger(page)) page++;
              // Finally, index to the correct page in the material view.
              if (
                material.props.index >
                this.props.config.materials.swatchesPerPage
              )
                this.handlePageChange(Math.ceil(page));
            }
          });
        }

        // get the last material in history that matches this material group
        if (this.getLastMaterialInThisMatGroup()) {
          if (
            material.props.manCode ===
              this.getLastMaterialInThisMatGroup().Name &&
            material.props.ParentName === this.props.parent
          ) {
            // handle the UI update..
            this.handleMaterialChange(material, null, false);
            // override the default material now with the updated material
            // for material persistency
            this.setState({
              activeMaterialIndex: material.props.index,
              default: material,
            });
          }
        }
      });
    });

    this._isMounted = true;

    // Tell the parent that I'm loaded
    this.props.registerLibrary();
  };

  update = () => {
    // get the last element in the path store
    let _path = store.getState().path.slice(-1)[0]; // last path element
    // get the current store history.
    let _storeHistory = [...store.getState().history];
    // Reverse iterate through this to determine if the latest item in the store history
    // that matches the parent is present. if not, do nothing.  If it is, remove it from
    // the path and add the new material in.
    // remove all elements from the path with the same MatGroup
    _storeHistory.forEach((material) => {
      _path.forEach((el) => {
        if (this.props.parent === material.MatGroup) {
          _path = _path.filter((el) => el.MatGroup !== this.props.parent);
          _path.unshift(material);
          this.props.addPathStep(_path);
        }
      });
    });

    this.props.rebuild(null);
  };

  handleMaterialChange = (material, zones, clicked) => {
    if (!material) return;

    let _material = new Material(material.props);
    // This probably should be handled differently and assigned within the material itself.
    // this mutates the material adding in the selected zones as matGroups.  This primarily
    // handles multi-zoned objects.
    _material.MatGroups = zones;
    // set a prop to determine if the material was manually or dynamically added to history
    //_material.Clicked = clicked;
    _material.Clicked = clicked;
    // update the current matgroup
    // Add the material to REDUX...this will allow a best attempt to match a previous selection when
    // a new base model is selected.
    this.props.addHistoryStep(_material);
    // Now handle any matches
    if (_material.Match !== "") {
      _material.Match.split("-").forEach((match) => {
        let _matchedMaterial = new Material(material.props);
        _matchedMaterial.MatGroup = match;
        this.props.addHistoryStep(_matchedMaterial);
      });
    }

    this.setState(
      {
        // Need to convert this to an array because more than one material can no
        // be applied to a singular object.  In other words, there may be multiple active
        // materials within one expander.
        activeMaterialIndex: material.props.index,
        default: material, //commenting out Pete's updates to prevent certain configurators from crashing. We will need to address entry points bug in the future- mg
      },
      () => {
        this.update();
      }
    );
  };

  material = (item, index) => {
    //console.log(item)
    return (
      <UIMaterial
        // Application supplied props.
        // the update prop calls up to the product to inform that a change has
        // been made and request an update.
        key={index}
        default={this.state.default}
        index={index}
        active={false}
        activeIndex={this.state.activeMaterialIndex}
        mattype={this.props.mattype}
        config={this.props.config}
        useModal={this.props.useModal}
        matgroup={this.props.parent}
        ParentName={this.props.parent}
        ParentText={this.props.title}
        parentId={this.Id}
        sessionId={this.props.sessionId}
        match={this.props.match}
        path={this.props.path}
        product={this.props.product}
        zindex={this.props.zindex}
        sku={this.props.sku}
        groups={this.props.groups}
        type={this.props.type}
        handleMaterialChange={this.handleMaterialChange.bind(this)}
        // Data supplied props.
        layer={item.layer}
        filters={item.filterData}
        materialProperties={item.matProps}
        library={item.library}
        manCode={item.manCode}
        clientCode={item.clientCode}
        value={item.value}
        uiValue={item.uiValue}
        //type={item.type}
        uiType={item.uiType}
        searchString={item.searchString}
        pi={this.props.pi}
        mF={item.mF} //ar value for Metallic Factor
        rF={item.rF} //ar value for Roughness Factor
        shader={item.shader}
        diffuseType={item.diffuseType}
        gltfMaterial={item.gltfMaterial}
        gltfScale={item.gltfScale}
        flex1={this.props.flex1}
        categorize={this.props.categorize}
      />
    );
  };
  //bug here
  renderMaterials = (callback) => {
    //Why are we setting it false? Causes dual fabric bug.
    this.setState({
      loaded: false,
    });

    let _path = store.getState().path.slice(-1)[0];
    var data = this.props.config;
    data.guid = this.props.config.guid;
    data.modelMaterials = this.props.modelMaterials;

    //console.log(_path)

    let caller = {};
    caller.parent = this.props.parent;
    caller.moi = this.props.moi;
    caller.hiddenfilter = this.props.hiddenfilter;
    caller.layer = this.props.layer;
    caller.pi = this.props.pi; //product index
    caller.mattype = this.props.mattype;
    caller.start = this.state.firstSwatch;
    caller.end = this.state.lastSwatch;
    caller.type = this.props.type;
    caller.rF = this.props.rF; //ar value
    caller.mF = this.props.mF; //ar value
    caller.flex1 = this.props.flex1; //ar value

    if (this.props.pi === "" || this.props.pi === undefined) {
      caller.pi = "0";
    }

    //caller.categorizedMats = !this.props.categorize ? false : true;

    /*    if (this.props.categorize === "true") {
      caller.categorize = this.props.categorize;
    } else {
      caller.categorize = "false";
    } */

    //console.log(caller)

    this.io
      .getSwatches(
        caller,
        this.props.groups,
        this.mCore.addNodes(data, _path),
        this.props.config
      )
      .then((materialdata) => {
        //console.log(materialdata)
        // store the zones as an array.
        var materials = [];
        // Add filtering, if available
        this.renderFilters(materialdata.filters);
        // Load materials that are contextual to the caller
        materialdata.materials.forEach((item, index) => {
          var material = this.material(item, index);
          materials.push(material);
        });

        this._isMounted &&
          this.setState(
            {
              materials: materials,
              maxCount: this.props.config.materials.fragmented
                ? materialdata.maxCount
                : materialdata.materials.length,
              materialFilters: materialdata.filters,
              filteredData: materials,
              loaded: true,
            },
            () => {
              callback();
            }
          );
      })
      .catch(() => {
        return <div>NO DATA RETURNED</div>;
      });
  };

  renderFilters = (data) => {
    let filters = [];

    data.forEach((filter, i) => {
      // assign classes for filter position.  This is useful when they're inline
      var order;

      switch (i) {
        case 0: {
          order = "filters first";
          break;
        }
        case data.length - 1: {
          order = "filters last";
          break;
        }
        default: {
          order = "filters";
        }
      }

      filters.push(
        <AccordionItem
          key={i}
          title={filter.title}
          bodyClassName={order}
          expandedClassName="filter-expanded"
        >
          <Filters
            key={i}
            title={filter.title}
            filterData={filter}
            currentMaterials={this.state.filteredData}
            updateSwatches={this.handleFilterChange.bind(this)}
          />
        </AccordionItem>
      );
    });

    this.setState({ filters: <Accordion>{filters}</Accordion> });
  };

  handlePageChange(pageNumber) {
    this.setState(
      {
        activePage: pageNumber,
        firstSwatch:
          pageNumber * this.state.swatchesPerPage - this.state.swatchesPerPage,
        lastSwatch: pageNumber * this.state.swatchesPerPage,
      },
      () => {
        // Update the materials if using fragmented
        if (this.props.config.materials.fragmented)
          this.renderMaterials(() => {
            // Callback here..
            //console.log(this.state.filteredData)
          });
      }
    );
  }

  handleInputChange(e) {
    var arr = [];

    // reset to the first page.
    this.handlePageChange(1);

    // Use the complete filteredData set for filtering.
    this.state.materials.forEach((m) => {
      if (this.filterSwatchesByString(m.props.searchString, e)) arr.push(m);
    });

    this.setState({ filteredData: arr });
  }

  filterSwatchesByArray(input, e) {
    if (input.data.toUpperCase().includes(e.target.value.toUpperCase())) {
      return true;
    }

    return false;
  }

  filterSwatchesByString(input, e) {
    if (input.toUpperCase().includes(e.target.value.toUpperCase())) {
      return true;
    }
    if (input === e.target.value) return true;

    return false;
  }

  handleFilterChange(filter) {
    // we need to keep track of active filters.
    // remove any filters that belong to the same parent as the incoming filter
    let activeFilters = this.state.activeFilters;
    let _materials = [...this.state.materials];

    var arr = [];
    if (activeFilters.includes(filter)) {
      arr = activeFilters.filter((f) => f !== filter);
    } else {
      arr = activeFilters;
      arr.push(filter);
      // reset the swatches to defaults..
      this.setState({
        firstSwatch: 0,
        lastSwatch: this.props.config.materials.swatchesPerPage,
      });
    }

    let filteredMaterials = _materials.filter((material) =>
      arr.every((el) => {
        // if it matches, the length will be > 0 and that means the material is valid with the filtering.
        if (material.props.filters !== null) {
          //check that filters exist -MG 12/19/2019

          //f.value[0] was erroring out becuase it was sometimes null. Added f.value !== null to prevent this. -MG 12/19/2019
          if (
            material.props.filters.filter(
              (f) =>
                f.value !== null &&
                f.key === el.props.data.key &&
                f.value[0] === el.props.search
            ).length > 0
          ) {
            return true;
          }
          return false;
        }

        return false;
      })
    );

    // finally, set the states.
    this.setState({
      filteredData: filteredMaterials,
      activeFilters: arr,
      activePage: 1,
      // firstSwatch: 0
    });
  }

  updateSwatches = () => {
    var arr;

    // Check to see if this is using fragmented materials...if not:
    !this.props.config.materials.fragmented
      ? (arr = this.state.filteredData.slice(
          this.state.firstSwatch,
          this.state.lastSwatch
        ))
      : (arr = this.state.filteredData);

    // Because the materials are built in an array and manipulated, React needs to create a clone
    // of it and set the props manually.
    arr = React.Children.map(arr, (material, index) => {
      return React.cloneElement(material, {
        active:
          material.props.index === this.state.activeMaterialIndex ||
          (index === 0 &&
            material.props.index > 0 &&
            this.state.activeMaterialIndex ===
              0) /* set to the previously selected material */,
        default: this.state.default, //commenting out Pete's updates to prevent certain configurators from crashing We will need to address entry points bug in the future- mg
      });
    });

    return arr;
  };

  render() {
    // default --> waiting for swatches to load
    if (!this.state.loaded)
      // Probably should show some kind of enhanced loading graphic.  This just
      // shows some boring old text.
      return <div>loading...</div>;

    return (
      <div className="swatches-container">
        {
          // only show search box if there's more than one page of results, or if filters should display based on attr in model
          this.state.materials.length > this.state.swatchesPerPage &&
          this.props.hiddenfilter !== "true" ? (
            <input
              className="form-control"
              onChange={this.handleInputChange.bind(this)}
              placeholder="Search"
            />
          ) : null
        }
        {
          // only show filters if they should display based on attr in model
          this.props.hiddenfilter !== "true" ? (
            <div className="swatch-filters">{this.state.filters}</div>
          ) : null
        }

        <div className="swatch-container">{this.updateSwatches()}</div>

        <div className="pagination-container">
          {this.state.maxCount > this.state.swatchesPerPage ? (
            <Pagination
              activePage={this.state.activePage}
              itemsCountPerPage={this.state.swatchesPerPage}
              totalItemsCount={this.state.maxCount}
              pageRangeDisplayed={this.state.pagesToDisplay}
              onChange={this.handlePageChange.bind(this)}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default connect(null, { addHistoryStep, addPathStep })(Materials);
