import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import Checkbox from "../Checkbox/checkbox";
import ReactTooltip from "react-tooltip";
// Analytics
import Analytics from "../../classes/analytics";
import "./material.css";

// Store a unique GUID for this library.  This helps keep track of history and the
// decision path.

//const uuidv1 = require('uuid/v1');
const { v1: uuidv1 } = require("uuid");

// ENVIROMENT VARIABLES
//const ENV = require('../../env');

// UIMaterial is a React Component.
class UIMaterial extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active: false,
      showModal: false,
      clicked: false,
    };

    this.Analytics = new Analytics(uuidv1());
  }

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.uiValue !== this.props.uiValue &&
      this.props.uiType === "hex"
    ) {
      this.renderHexSwatch();
    }

    if (
      (this.props.index === this.props.activeIndex &&
        prevProps.matgroup !== this.props.matgroup) ||
      (prevProps.manCode !== this.props.manCode &&
        this.props.index === this.props.activeIndex) ||
      (prevProps.manCode === this.props.manCode &&
        prevProps.sku !== this.props.sku)
    ) {
      //-MG manCodes are the same but sku order is different, material change
      this.props.handleMaterialChange(this, null, false);
    }

    if (this.props.active) {
      // little bit of sorcery here to force a DOM update on the expander title (if they're shown).  This
      // is a HACK to override the non-react supported title feature in the accordion.  By convention, the
      // title is the first element, the body is the next.
      if (this.props.config.expander.showSelectionOnExpander)
        this.forceExpanderTitleChange();
    }
  };
  componentDidMount = () => {
    // set the default material.  This is an incoming value determined by the parent (materials)
    // once selected, the parent will continue to manage which material is active, however each material
    // callsback to the parent when changed to indicate a request has been called.
    if (this.props.active) {
      this.setState({ active: true }, () => {
        // tell the parent it's been changed and ready to process the updates.
        // load the default material.
        // need some logic to determine wether we show the default material or persist with the
        // most current selection in history
        // iterate through the store and see one exists with the same matgroup
        if (
          this.props.default &&
          this.props.ParentName === this.props.default.props.ParentName
        ) {
          this.props.handleMaterialChange(this.props.default, null, false);
        } else {
          this.props.handleMaterialChange(this, null, false);
        }
      });

      // little bit of sorcery here to force a DOM update on the expander title (if they're shown).  This
      // is a HACK to override the non-react supported title feature in the accordion.  By convention, the
      // title is the first element, the body is the next.
      if (this.props.config.expander.showSelectionOnExpander)
        this.forceExpanderTitleChange();
    }

    if (this.props.uiType === "hex") this.renderHexSwatch();
  };

  forceExpanderTitleChange = () => {
    var elems = document.getElementsByClassName("react-sanfona-item-title");
    var name = "";
    Object.entries(this.props.materialProperties).forEach((entry) => {
      if (entry[1] !== null) {
        //do not add to name if entry / description is null -MG 1/9/2020
        name = name + entry[1] + " ";
      }
    });
    elems.forEach((el) => {
      if (el.nextSibling.classList.contains(this.props.matgroup)) {
        var title = el.innerHTML.split("|");
        el.innerHTML = title[0] + "| " + name;
      }
    });
    //console.log(this.props.materialProperties)
  };

  renderHexSwatch = () => {
    // render an HEX color if needed.
    if (this.props.uiType === "hex") {
      const c = this.refs.canvas;
      const ctx = c.getContext("2d");
      ctx.fillStyle = this.props.uiValue;
      ctx.fillRect(
        0,
        0,
        this.props.config.materials.swatchWidth,
        this.props.config.materials.swatchHeight
      );
    }
  };

  toggle = (zones, clicked) => {
    // Invoke the modal
    if (this.props.useModal) {
      this.setState({
        showModal: !this.state.showModal,
      });
    }
    // Apply the modal
    if (this.props.useModal && this.state.showModal) {
      // Set the available zones using the modal then handle the materials...
      // Build the modal component here.
      // send the material change to the selected areas.  Will also need to add new functionality
      // to the function for multiple selected materials.  FUN!!!!

      this.setState(
        {
          active: !this.state.active,
          clicked: true,
        },
        () => {
          this.props.handleMaterialChange(this, zones, clicked);
        }
      );
    }
    // There is no modal, invoke the selection directly
    if (!this.props.useModal)
      this.setState(
        {
          active: !this.state.active,
          clicked: true,
        },
        () => {
          // no modal, click straight through...
          // clicked tells future features such as analytics it was not an automatic add.
          this.props.handleMaterialChange(this, zones, clicked);
          // little bit of sorcery here to force a DOM update on the expander title (if they're shown).  This
          // is a HACK to override the non-react supported title feature in the accordion.  By convention, the
          // title is the first element, the body is the next.
          if (this.props.config.expander.showSelectionOnExpander)
            this.forceExpanderTitleChange();
          // Analytics
          this.Analytics.log(MaterialData(this.props));
        }
      );
  };

  render() {
    var img;

    if (this.props.uiType !== "sicon")
      //img = ENV.machinecore.image +
      img =
        window.TBPM.MACHINECORE_IMAGE +
        this.props.uiValue.split(":")[0] +
        "&fmt=jpeg&mat=" +
        this.props.manCode +
        "&width=" +
        this.props.config.materials.swatchWidth +
        "&height=" +
        this.props.config.materials.swatchHeight +
        "&scale=" +
        this.props.config.materials.swatchScale;

    if (this.props.uiType === "sicon") {
      //img = ENV.blob.path + this.props.config.cfgId + "/assets/icons/" + this.props.uiValue;
      img =
        window.TBPM.BLOB_URL +
        "/" +
        this.props.config.cfgId +
        "/assets/icons/" +
        this.props.uiValue;
    }

    //const img2x = ENV.machinecore.image +
    const img2x =
      window.TBPM.MACHINECORE_IMAGE +
      this.props.uiValue.split(":")[0] +
      "&fmt=jpeg&mat=" +
      this.props.manCode +
      "&width=" +
      this.props.config.materials.swatchWidth * 2 +
      "&height=" +
      this.props.config.materials.swatchHeight * 2 +
      "&scale=" +
      this.props.config.materials.swatchScale;

    const cls = this.props.active ? "swatch active" : "swatch";

    let uuid = uuidv1();

    return (
      <div className="swatch-border">
        {this.state.showModal ? (
          <SwatchModal
            active={this.props.useModal}
            image={img2x}
            name={this.props.materialProperties.p1}
            zones={this.props.groups[0].zones}
            close={this.toggle.bind(this, null, false)}
            select={this.toggle.bind(this, null, true)}
          />
        ) : null}

        <div
          className={cls}
          onClick={this.toggle.bind(this, null, true)}
          data-tip
          data-for={uuid}
        >
          <div className="swatch-image">
            <div className="swatch-marker" />
            {
              // Determine if we're rendering an image as a material or HEX code.  Currently those are the
              // only supported formats.
              this.props.uiType !== "hex" && this.props.uiTYpe !== "sicon" ? (
                <img src={img} alt="" />
              ) : (
                <canvas
                  ref="canvas"
                  width={this.props.config.materials.swatchWidth}
                  height={this.props.config.materials.swatchHeight}
                />
              )
            }
          </div>

          {this.props.materialProperties.p2 !== null ? (
            <div className="swatch-label">
              {
                //standard fabrics caption (separated from model mats in order to have control of each field)
                Object.entries(this.props.materialProperties).map(
                  (entry, i) => (
                    <span className={"swatch-caption-" + i} key={uuidv1()}>
                      {entry[1]}{" "}
                    </span>
                  )
                )
              }
            </div>
          ) : (
            <div className="swatch-label">
              {
                //caption for model materials when they exists inside the same expander as dynamic fabrics
                Object.entries(this.props.materialProperties).map(
                  (entry, i) => (
                    <span
                      className={"swatch-model-caption-" + i}
                      key={uuidv1()}
                    >
                      {entry[1]}{" "}
                    </span>
                  )
                )
              }
            </div>
          )}
        </div>

        {this.props.config.tooltips ? (
          <ReactTooltip type={this.props.config.tooltips.type} id={uuid}>
            <span>
              {
                //setting the tooltip style to the client config if the field exists
                Object.entries(this.props.materialProperties).map(
                  (entry, i) => (
                    <span className={"swatch-tooltip-" + i} key={uuidv1()}>
                      {entry[1]}{" "}
                    </span>
                  )
                )
              }
            </span>
          </ReactTooltip>
        ) : (
          <ReactTooltip id={uuid}>
            <span>
              {
                //set tooltip style to default (black) if it does not exist in the client config
                Object.entries(this.props.materialProperties).map(
                  (entry, i) => (
                    <span className={"swatch-tooltip-" + i} key={uuidv1()}>
                      {entry[1]}{" "}
                    </span>
                  )
                )
              }
            </span>
          </ReactTooltip>
        )}
      </div>
    );
  }
}

const MaterialData = (props) => {
  // log the change to analytics -----------------------------------------------
  // ---------------------------------------------------------------------------
  // Set the properties...
  let item = {};
  item.logContext = "EXPANDER";
  item.logType = "MATERIAL";
  item.logItem = "MATERIAL";
  //item.sessionId = props.sessionId;
  item.parentId = props.parentId;
  item.org = props.config.org;
  item.orgId = props.config.orgId;
  item.cfgId = props.config.cfgId;
  item.product = props.product;
  item.parent = props.ParentName;
  item.title = props.ParentText;
  item.props = props.materialProperties;
  item.manCode = props.manCode;
  item.clientCode = props.clientCode;
  item.shader = props.shader;
  item.diffuseType = props.diffuseType;
  item.gltfMaterial = props.gltfMaterial;
  return item;
};

// Material is designed for file IO as well as rendering.  It contains only the required properties to keep it lightweight
// for save / load functionality.
class Material {
  constructor(props) {
    this.Layer = props.layer;
    this.UseModal = props.UseModal;
    this.Index = props.index;
    this.Name = props.manCode;
    this.Groups = props.groups;
    // Depredcate once matgroups has been implemented?
    this.MatGroup = props.matgroup;
    // basically this will become an array of MatGroups most likely deprecating the singular matgroup.
    this.MatGroups = [];
    this.Sku = props.sku + props.clientCode;
    this.Match = props.match;
    this.Text = "";
    //this.Description = props.description; // new...
    this.Value = props.value;
    this.UIValue = props.uiValue;
    this.Type = props.type;
    this.UIType = props.uiType;
    this.Pi = props.pi;
    this.MatType = props.mattype;
    this.ParentText = props.ParentText;
    this.rF = props.rF; //not being used
    this.mF = props.mF; //not being used
    this.shader = props.shader;
    this.diffuseType = props.diffuseType;
    this.gltfMaterial = props.gltfMaterial;
    this.gltfScale = props.gltfScale;
    this.Flex1 = props.flex1;
    this.Categorize = props.categorize;
    //Set material text for spec data / pdf 2/14/2020
    Object.entries(props.materialProperties).forEach((entry) => {
      if (entry[1] !== null) {
        this.Text = this.Text + entry[1] + " ";
      }
    });
  }
}

class SwatchModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selections: [],
      groupSelected: false,
      allSelected: false,
    };
  }

  toggle = (zone) => {
    // Remove it
    let removed = false;
    this.state.selections.forEach((selection) => {
      if (zone === selection) {
        this.removeSelection(selection);
        removed = true;
      }
    });
    // determine if we should add it
    if (!removed) {
      this.addSelection(zone);
    }
  };

  addSelection = (selection) => {
    let selections = this.state.selections;
    selections.push(selection);

    this.setState({
      selections: selections,
    });
  };

  removeSelection = (selection) => {
    let selections = this.state.selections;
    selections.forEach((el, index) => {
      if (el === selection) {
        selections.splice(index, 1);
      }
    });

    this.setState({
      selections: selections,
      allSelected: false,
    });
  };

  selectGroup = (checked) => {
    let selections = this.props.zones.slice(1, this.props.zones.length);
    this.setState({
      // Add the selection entries
      selections: checked ? selections : [],
      // Update the UI
      groupSelected: checked ? true : false,
      allSelected: true,
    });
  };

  render() {
    const zones = this.props.zones;

    if (this.props.active)
      return (
        <div className="material-modal">
          <div
            className="material-modal-close"
            onClick={() => {
              this.props.close();
            }}
          >
            <FontAwesomeIcon icon={faTimes} size="1x" />
          </div>
          <img className="material-modal-image" src={this.props.image} alt="" />
          <div className="material-modal-body">
            <h3>{this.props.name}</h3>
            {zones.map((zone, index) => (
              <div key={index} className="material-modal-zone">
                {index === 0 ? (
                  // Group checkbox --> first zone is always the parent.
                  <Checkbox
                    selected={this.state.allSelected}
                    toggle={this.selectGroup.bind(this)}
                  />
                ) : (
                  // Individual checkboxes
                  <Checkbox
                    selected={this.state.groupSelected}
                    toggle={this.toggle.bind(this, zone)}
                  />
                )}

                <h4>{zone.text}</h4>
              </div>
            ))}
          </div>
          <div className="material-modal-apply">
            <button
              className="form-control btn-primary btn"
              onClick={() => {
                this.props.select(this.state.selections);
              }}
            >
              APPLY
            </button>
          </div>
        </div>
      );

    return null;
  }
}

export { UIMaterial, Material };
