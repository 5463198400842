import React from "react";
import ARKit from "./ios-quicklook";
import ARCore from "./android-sceneviewer";
import Desktop from "./desktop";
import Bowser from "bowser";
import ls from "local-storage";
import Axios from "axios";
import { runInNewContext } from "vm";

//import ThreeDReducer from "../MachineCore/3dReducer";

class Routing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      spid: "",
      arShow: false,
      validated: false,
      //   viewerUrl: null,
      ua: Bowser.parse(window.navigator.userAgent),
    };
    this.checkRootNode = this.checkRootNode.bind(this);
  }

  componentDidMount = () => {
    if (this.props.productObj.hasOwnProperty("rootnode")) this.checkRootNode();
  };

  componentDidUpdate = () => {
    //console.log(this.props.config);
    if (!this.state.validated) {
      this.checkRootNode();
    }
  };

  checkRootNode = () => {
    if (!this.props.productObj.hasOwnProperty("rootnode")) return null;
    if (this.state.validated) return null;
    let spid = ls.get("tbpmSpid");
    let rn = this.props.productObj.rootnode.toLowerCase();
    try {
      Axios.get(
        window.TBPM.AR_VALIDATION_ENDPOINT +
          "/" +
          this.props.config.orgId +
          "/" +
          rn,
        {}
      ).then((response) => {
        if (!response) {
          this.setState({
            arShow: false,
            validated: true,
          });
        }
        //  console.log(response.data);
        if (response.data.valid === true) {
          this.setState({
            spid: spid,
            arShow: true,
            validated: true,
          });
        } else {
          this.setState({
            spid: spid,
            arShow: false,
            validated: true,
          });
        }
      });
    } catch (err) {
      this.setState({
        spid: spid,
        arShow: false,
        validated: true,
      });
    }
  };

  /*   showObject = () => {
    this.setState({ viewerUrl: null });
    this.ThreeDReducer = new ThreeDReducer();
    const _ThreeDReducerData = this.ThreeDReducer.addNodes(
      this.props.config,
      this.props.productObj
    );
    _ThreeDReducerData.ua = this.state.ua;
    console.log(_ThreeDReducerData);
    Axios.post(window.TBPM.THREE_D_ENDPOINT, {
      data: _ThreeDReducerData,
    }).then((response) => {
      console.log(response);
      this.setState({ viewerUrl: response.data.url });
      //   return response.data;
    });
  }; */

  render() {
    if (!this.state.arShow || !this.state.ua) return null;
    if (this.state.ua.platform.type.toLowerCase() === "desktop")
      return (
        <>
          <Desktop {...this.props} ua={this.state.ua}></Desktop>
        </>
      );

    if (
      this.state.ua.platform.type.toLowerCase() === "mobile" &&
      this.state.ua.os.name.toLowerCase() === "ios" &&
      parseInt(this.state.ua.os.version) >= 13
    )
      return (
        <>
          <ARKit {...this.props} ua={this.state.ua}></ARKit>
        </>
      );

    if (
      this.state.ua.platform.type.toLowerCase() === "mobile" &&
      this.state.ua.os.name.toLowerCase() === "android" &&
      parseInt(this.state.ua.os.version) >= 8
    )
      return (
        <>
          <ARCore {...this.props} ua={this.state.ua}></ARCore>
        </>
      );
  }
}

export default Routing;
