import React from 'react';
//import Button from 'react-bootstrap/Button';
import axios from 'axios';
import './decal.css';

class Decal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            file: null,
            name: "",
            selected: false
        }
    }

    //set the state to the selected file
    fileHandler = event => {
        this.setState({
            file: event.target.files[0],
            name: event.target.files[0].name,
            selected: true
        })

        //console.log(event.target.files[0].name)
    }

    fileUpload = (event) => {
        event.preventDefault();
        const fd = new FormData();
        fd.append('decal', this.state.file);

        axios({
            method: 'post',
            encType: 'multipart/form-data',
            url: 'https://api-dev.thebigpicturemachine.com/decalupload',
            data: fd
        })
            .then(res => {
               this.props.handleSelection(res.data.url);
            })
            .catch(err => {
                console.log(err);
            });
    }

    getFile = (file) => {
        this.props.handleSelection(file);
    }


    render() {

        return (
            <div className="decal-main">

                <form onSubmit={this.onSubmit}>
                    <label className="decal-label">
                        Select File
                        <input type="file" name="decal" id="decal" onChange={this.fileHandler} />
                        </label>
                        <input type="text" value={this.state.name} readOnly />
                        <input className="decal-upload" type="submit" value="Upload" name="submit" disabled={!this.state.selected} onClick={this.fileUpload.bind(this)} />
                </form>

            </div>
        )
    }
}

export default Decal;