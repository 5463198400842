import React from 'react';
import './sku.css';

class Sku extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isDefault: true
        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.sku !== this.props.sku) {
            // tracking
            /*
            if (this.props.config.analytics.includes("woopra")) {
                Woopra.identify("test@test.com").push();
                Woopra.track('tbpm-sku-view', {
                    sku: this.props.sku,
                    default: this.state.isDefault,
                    application: window.parent.location
                });
            }

            this.setState({
                isDefault: false
            })*/
        }
    }

    render() {
        return (<div className="sku">{(this.props.sku) ? this.props.sku : ""}</div>);
    };
}

export default Sku;