import React from "react";
import { Accordion, AccordionItem } from "react-sanfona";
import { Forest, Root, Branch } from "../App/forest";
import { Material } from "../Libraries/material";
import PantoneModal from "../Libraries/pantones";
import Decal from "./decal";
import { sortByOrderNumber, groupReactBy } from "../Utils/utils";
import MachineCore from "../MachineCore/machinecoreAPI";
import IO from "../IO/io";
import Materials from "../Libraries/materials";
import { SaveFile, LoadFile } from "../IO/file";
import axios from "axios";
import ReactTooltip from "react-tooltip";
// STYLE
import "./expander.css";
// REDUX
import { connect } from "react-redux";
import { addPathStep, addHistoryStep, addFile } from "../../Redux/actions";
import store from "../../Redux/store";
import { runInNewContext } from "vm";
// Analytics
import Analytics from "../../classes/analytics";
import ls from "local-storage";

const { v1: uuidv1 } = require("uuid");

// Logging
const hrtime = require("browser-process-hrtime");

class Expander extends React.Component {
  constructor(props) {
    super(props);

    this.start = hrtime();

    this.state = {
      items: [],
      history: [],
      org: "",
      ui: "",
      group: "",
      tree: null,
      product: null,
      updating: false,
      path: [],
    };

    // Initialize
    this.Forest = new Forest(this.props.data, this.props.config.cfgId);
    this.MachineCore = new MachineCore();
    this.IO = new IO();
    // We need to know how many dynamic libraries the expander has to know when they've all been loaded.
    this.NumberOfDynamicLibraries = 0;
    this.NumberOfDynamicLibrariesLoaded = 0;
    this.DynamicCount = 0;
    this.DynamicOffsetCount = 0;
    this.Id = uuidv1();
    // Keep track of groups
    this.Groups = this.props.config.groups.mapping;
    this.Groups.forEach((group) => {
      group.Expanders = [];
      group.Static = [];
    });
    this.AllNodes = [];
  }

  componentDidMount = () => {
    // we're loading a file, so we need to update the history with the incoming file properties then
    // invoke the buildAllExpanders function.
    if (this.props.file && this.props.group === this.props.file.file_group)
      this.setState(
        {
          history: this.props.file.file_data.nodes,
        },
        () => {
          // console.log(this.props.file)
          // send a null value through to tell the expander to not make a default
          // selection.  Without null, it will set the default.
          // for now, file can only be loaded to the same group it was saved from.
          // TODO --> ADD LOGIC TO ALLOW A SINGLE FILE TO LOAD
          this.buildAllExpanders(null, true, () => { });
        }
      );
    // No file, default state.
    else
      this.buildAllExpanders(null, false, () => {
        // The expanders are built but don't update the UI until all dynamic material groups have finished loading...
      });
    // update only if the number of dynamic libraries available equals the number of dynamic libraries loaded.  This
    // essentially tells anything displaying a product to hold until the final pass.
    if (this.NumberOfDynamicLibraries === 0)
      this.props.update(0, true, () => {
        this.writeFile();
        this.setState({ updating: false });
      });

    // reset the group expanders also if needed
    this.Groups.forEach((group) => {
      group.Expanders = [];
      group.Static = [];
    });

    // Log uistats if enabled in config
    if (
      this.props.config.analytics.uistats &&
      this.props.config.analytics.uistats.enabled
    ) {
      var log = {};
      log.EXPANDERinit = {};
      log.EXPANDERinit.timeToMountInMilliseconds =
        hrtime(this.start)[1] / 1000000;
      log.org = this.props.config.orgId;
      log.cfg = this.props.config.cfgId;
      log.product = this.props.data[0].grouping_name;

      //axios.post(ENV.api.performance, {
      axios.post(window.TBPM.PERFORMANCE_ENDPOINT, {
        log: log,
      });
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    //console.log(store.getState().path);
    // although we keep the path in REDUX, we also need to keep a local copy to know if it's been updated.  When
    // updated, this invokes a (re)render.  Child components can also update the path so they too let the
    // expander know when to update itself.
    // EXPANDERS WITH DYNAMIC MATERIALS > 0
    if (
      prevState.path !== this.state.path &&
      this.NumberOfDynamicLibraries === this.NumberOfDynamicLibrariesLoaded &&
      this.state.updating
    ) {
      this.setState(
        {
          path: store.getState().path,
        },
        () => {
          // invoke a re-render, this will updated the images, the pricing and the sku data.
          //console.log(this.state.path)
          this.props.update(
            this.NumberOfDynamicLibraries,
            this.NumberOfDynamicLibraries ===
            this.NumberOfDynamicLibrariesLoaded,
            () => {
              this.setState({ updating: false }, () => {
                this.writeFile();
              });
            }
          );
        }
      );
    }

    // //test mgg //KI entry point bug fix
    if (
      prevState.path !== this.state.path &&
      this.NumberOfDynamicLibraries === this.NumberOfDynamicLibrariesLoaded    ) {
      this.writeFile();
      //console.log("test")
    }

    // EXPANDERS WITH NO DYNAMIC MATERIALS
    if (
      prevState.path !== this.state.path &&
      this.NumberOfDynamicLibraries === 0
    ) {
      this.props.update(
        this.NumberOfDynamicLibraries,
        this.NumberOfDynamicLibraries === this.NumberOfDynamicLibrariesLoaded,
        () => {
          this.setState({ updating: false }, () => {
            //   console.log("no dynamic mats, path not same");
            this.writeFile();
          });
          // this.writeFile();
          // this.setState({ updating: false });
        }
      );
    }

    // //test mgg //KI entry point bug fix
    if (
      prevState.path !== this.state.path &&
      this.NumberOfDynamicLibraries === 0
    ) {
      this.writeFile();
      //console.log("renderable")
    }

    // reset the group expanders also if needed
    this.Groups.forEach((group) => {
      group.Expanders = [];
      group.Static = [];
    });

    // override the subexpander --> an issue with the react-sanfona maxHeight does not let it
    // dynamically resize because maxHeight is set inline.
    var subExpanders = document.querySelectorAll(
      ".sub-expander .react-sanfona-item-body"
    );
    subExpanders.forEach((element) => {
      element.style.maxHeight = "100%";
    });
  };

  writeFile = () => {
    // console.log("writing file..");
    // add the file to REDUX store.  This keeps track of all file related data for save / load / share.
    // build the file to store...currently this is also being processed exactly the same way
    // in the file.js in IO.  This needs to be moved to a singular method shared between the
    // two components.
    var data = store.getState().path.slice(-1)[0];
    // Format the incoming data for storage.
    // Main array to hold the sub arrays.
    var dataObject = {};
    // store all of the material objects.
    var materialArray = [];
    // store all of the node (expander) objects.
    var nodeArray = [];
    var file = {};
    var product = {};
    var groups = [];
    var materials = [];

    data.forEach((element) => {
      if (element instanceof Material) {
        // store material data as-is.  It's not recursively built.
        materialArray.push(element);
        let material = {};
        material.parent = element.MatGroup;
        material.Text = element.Text;
        materials.push(material);
      }
      if (element instanceof Root) {
        // root data needs to be deconstructed to main elements.imageUrl
        product.product = element.Text;
        product.productLine = element.Product;
        let item = {};
        item.Name = element.Name;
        nodeArray.push(item);
      }

      if (element instanceof Branch) {
        let item = {};
        let group = {};
        group.title = element.Nodes.toNode.ParentText;
        group.option = element.Nodes.toNode.Text;
        group.parent = element.Nodes.toNode.ParentName;
        if (element.Nodes.toNode.Hidden !== "true") groups.push(group);
        item.ParentName = element.Nodes.toNode.ParentName;
        item.Name = element.Nodes.toNode.Name;
        nodeArray.push(item);
      }
    });

    product.groups = groups;
    product.groups.forEach((group) => {
      materials.forEach((material) => {
        if (group.parent === material.parent) group.option = material.Text;
      });
    });

    product.materials = materials;

    dataObject.materials = materialArray;
    dataObject.nodes = nodeArray;

    file.file_uid = uuidv1();
    file.file_name = "";
    file.file_org = this.state.org;
    file.file_group = this.state.group;
    file.file_ui = this.props.config.cfgId;
    file.file_data = dataObject;
    file.file_comments = "";
    file.file_tags = "";
    file.file_sku = this.props.sku;
    //file.file_price = this.props.price.asConfiguredPrice;
    file.file_imageUrl = this.props.imageUrl;

    /*     var payload = {};
    payload.file = file;
    payload.product = product; */

    this.props.addFile(file);
    ls.set("tbpmFileData", file);
    //console.log(file)
    //this.setState({ materials: materials, nodes: nodeArray });
    //this.logSku(nodeArray, product.materials);
  };

  // History is critical to "BEST GUESS".  This means that when a node is selected it registers itself with the
  // history state so it looks for a best match when rebuilding on a new selection.
  updateHistory = (selection, callback) => {
    let history = this.state.history;

    history.forEach((h, index) => {
      //fix for file in undefined parentname DH
      if (h.ParentName === undefined && h.ParentName !== null) {
        h.ParentName = null;
      }
      if (h.ParentName === selection.props.parentName) history.splice(index, 1);
    });

    let historyItem = {};
    historyItem.ParentName = selection.props.parentName;
    historyItem.Name = selection.props.name;
    history.push(historyItem);

    this.setState(
      {
        history: history,
      },
      () => {
        callback();
      }
    );
  };

  // this is called each time a library is mounted.  This lets the UI know when the last dynamic library has been loaded
  // and then invokes the update.
  registerLibrary = () => {
    this.NumberOfDynamicLibrariesLoaded++;
  };

  // rebuild is called whenever any node is clicked.  This will force the expander to rebuild itself
  // replacing obsolete components with new ones.
  rebuild = (selection) => {
    // we update the history as every selection is made because it's keeping track of all selections.
    // updatePath is noe used here because it needs to hold only active selections AKA the path.
    // if there's a selection made, that means it's local to the expander and needs to update the expander.
    // NODE
    if (selection)
      this.updateHistory(selection, () => {
        this.buildAllExpanders(selection, false, () => {
          // do something after the expanders have loaded..
          this.setState({ updating: true });
          //console.log("rebuild1");
          this.writeFile(); //KI entry point bug fix
        });
      });
    else {
      // if there's no selection, then it's coming from somewhere else and we just need to rebuild
      // update the index
      this.setState({
        path: store.getState().path,
        updating: true,
      });
      //console.log("Rebiuld2");
      this.writeFile(); //KI entry point bug fix
    }
  };

  buildAllExpanders = (selection, initFromLoad, callback) => {
    // reset
    this.NumberOfDynamicLibraries = 0;
    this.NumberOfDynamicLibrariesLoaded = 0;
    this.DynamicCount = 0;
    this.DynamicOffsetCount = 0;

    var product;
    // initialize the path every time through.  This will be build progressively as each
    // pass through the recursion.
    let path = [];
    // an array that will contain all of the accordion (expander) items.
    var items = [];
    // an array that will contain all of the nodes per accordion item.  This
    // will mutate througout the loop.
    var nodes = [];
    // trees --> we need to create a copy of this to add additional properties.
    var trees = this.Forest.Trees; //these are subproducts -MG
    var firstExpanderTitle;
    // ROOT DEFINITION
    //console.log(this.state.history);
    trees.forEach((tree, index) => {
      firstExpanderTitle = tree.Title;

      let node = {};
      node.key = index;
      node.visible = true;
      node.text = tree.Root.Text;
      node.product = tree.Product;
      node.plid = tree.Plid;
      node.spid = tree.Spid;
      node.name = tree.Root.Name;
      node.id = tree.Root.Id;
      node.sku = tree.Root.Sku;
      node.type = tree.Root.Type;
      node.uiType = tree.Root.UIType;
      node.value = tree.Root.Value;
      node.infoId = tree.Root.InfoId;
      node.uiValue = tree.Root.UIValue;
      node.index = index;
      node.rootNode = tree.Root.Id;
      node.class = tree.Root.Class;
      node.parentName = null;
      node.pi = tree.Root.Pi; //product index

      // reset defaults.
      node.active = false;
      tree.active = false;

      // Build some logic here to determine if we're using the index (default) for
      // this is the default / startup values.
      if (!selection) {
        node.active = index === 0;
        tree.active = index === 0;
      }

      if (initFromLoad) {
        node.active = false;
        tree.active = false;
      }

      // set the node to active when the id matches the selection
      if (selection)
        if (selection.props.id === node.id) {
          node.active = true;
          tree.active = true;
        }

      // finally, check the history to see if the root node needs to be reselected
      // when a descendant has been called.
      this.state.history.forEach((h) => {
        if (h.Name === node.name) {
          node.active = true;
          tree.active = true;
        }
      });

      if (node.active) {
        path.push(tree.Root);
      }
      nodes.push(node);
    });

    // Build the expander for the other nodes.  If there's not a title explicitly assigned then just use the text
    // from the forest object.
    items.push(
      this.expanderItem(
        this.Forest.Text,
        firstExpanderTitle ? firstExpanderTitle : this.Forest.Text,
        0,
        nodes,
        false,
        false
      )
    ); //Top expander/accordion (forest) that will hold subproducts (trees) -MG

    // NODE TREES DEFINITION
    items.push(
      trees.forEach((tree) => {
        // Enter recursion.  This will build all expanders.  Only build from the
        // active tree.  To do this, iterate through the trees and get the tree
        // to get the active index.
        if (tree.active) {
          product = tree.Product;
          // Keep track of Organization and the UI for file save purposes.  This will later be used, amongst other things,
          // to regenerate the state of the application.  The tree contains all of the data needed to rebuild the expander.
          this.setState({
            tree: tree,
            group: this.props.group,
            org: tree.OrgId,
            ui: this.props.config.cfgId,
          });

          // Enter the recursion.
          // The prepass
          this.buildExpander(
            tree.Root.Limbs,
            items,
            selection,
            path,
            product,
            true,
            () => {
              // Build the UI components]
              // only keep the root, the other ones are stored as groups if needed.
              items = items.slice(0, 1);

              this.buildExpander(
                tree.Root.Limbs,
                items,
                selection,
                path,
                product,
                false,
                () => {
                  // Add the groups if specified and don't build them if there's nothing
                  // to insert...
                  this.Groups.forEach((group, index) => {
                    // Only add expanders if there's content inside of the expander
                    if (group.Expanders.length > 0) {
                      items.push(
                        <AccordionItem
                          expandedClassName="group-expanded"
                          key={index}
                          title={group.title}
                          order={group.id} //ordering group expander by group id (ie 0 or 1)
                          onExpand={this.handleExpand.bind(this)}
                        >
                          <Accordion
                            allowMultiple={true}
                            className="sub-expander"
                          >
                            {group.Expanders}
                          </Accordion>
                        </AccordionItem>
                      );

                      group.Expanders.sort(sortByOrderNumber); //sorting subexpanders -MG
                    }
                  });

                  items.sort(sortByOrderNumber);
                  // register the path to REDUX store
                  this.props.addPathStep(path);
                }
              );
            }
          );
        }
      })
    );

    this.setState(
      {
        items: items,
        path: path,
        product: product,
      },
      () => {
        // This will tell the caller that the cursion has completed and the expanders are built.  This is useful
        // for informing the UI to begin any subsequent processes.
        callback();
      }
    );
  };

  // Build expander is a recursion so this one function will handle any expander and any
  // node that is presented to it.
  //limbs- all forest to and from nodes (everything), items-accordions, selection- click(to and from),
  //path- path for all limbs on current tree/subproduct (slections add to path. Can come back to previous state)-MG
  buildExpander = (
    limbs,
    items,
    selection,
    path,
    product,
    isPrePass,
    callback
  ) => {
    limbs.forEach((limb) => {
      let nodes = [];
      var activeBranch;
      limb.Branches.forEach((branch, index) => {
        let node = {};
        // Visible -> this can be set to alternative not show a node in the expander.
        node.visible = true;
        // props
        node.props = this.state.path;
        // Unique key per child
        node.key = index;
        // Product
        node.product = product;
        // Readable name / text
        node.text = branch.Nodes.toNode.Text;
        // Rootnode
        node.rootNode = branch.Nodes.toNode.RootNode;
        // Unique ID for comparer
        node.id = branch.Nodes.toNode.Id;
        // name for comparer -- mainly for history.
        node.name = branch.Nodes.toNode.Name;
        // Layer
        node.layer = branch.Nodes.toNode.Layer;
        // FLEX
        node.flex1 = branch.Nodes.toNode.Flex1;
        node.flex2 = branch.Nodes.toNode.Flex2;
        node.flex3 = branch.Nodes.toNode.Flex3;
        // Categorize 
        node.categorize = branch.Nodes.toNode.Categorize;
        //AR attributes
        node.geo = branch.Nodes.toNode.Geo;
        node.mgroup = branch.Nodes.toNode.Mgroup;
        // Match
        node.match = branch.Nodes.toNode.Match;
        // Value
        node.value = branch.Nodes.toNode.Value;
        // UI Value
        node.uiValue = branch.Nodes.toNode.UIValue;
        // MOI --> material index
        node.moi = limb.Branches.Moi ? limb.Branches.Moi.split(",") : undefined;
        //Hidden Filters
        node.hiddenfilter = limb.Branches.HiddenFilter;
        // 3D static geomoetry
        node.geo = branch.Nodes.toNode.geo;
        //Product Index
        node.pi = branch.Nodes.toNode.Pi;
        // Filter --> material index
        node.filter = branch.Nodes.toNode.Filter;
        // Library
        node.library = branch.Nodes.toNode.Library;
        // SKU
        node.sku = branch.Nodes.toNode.Sku;
        // Src
        node.src = branch.Nodes.toNode.Src;
        // Src
        node.srcType = branch.Nodes.toNode.SrcType;
        // Info
        node.infoId = branch.Nodes.toNode.InfoId;
        // Class
        node.class = branch.Nodes.toNode.Class;
        // Type
        node.type = branch.Nodes.toNode.Type;
        // UI Type
        node.uiType = branch.Nodes.toNode.UIType;
        // Parent for comparer
        node.parentName = branch.Nodes.toNode.ParentName;
        // Parent name for specifications..
        node.parentText = branch.Nodes.toNode.ParentText;
        // Material type
        node.mattype = branch.Nodes.toNode.MatType;
        // node.layerType = branch.Nodes.toNode.layerType;
        //zone index
        node.zindex = branch.Nodes.toNode.Zindex;
        // Keep track of the index to set state
        node.index = index;
        // set to inactive
        node.active = false;
        // If the index is 0 this will set node active to true
        // this is the default state.
        if (selection) {
          if (selection.props.id === node.id) {
            node.active = true;
            activeBranch = branch;
          }
        }
        // check history to see if there's a match, otherwise, set the first
        // node to active.  This is only looking for a name match, not an identical
        // parent or exact id.
        // This is for nodes only because materials DO NOT have branches.
        this.state.history.forEach((h) => {
          if (h.ParentName === node.parentName && h.Name === node.name) {
            node.active = true;

            // The branch and the node need to be overridden by an incoming value.  Typically, this is
            // set from a modal for custom functionality.
            if (selection)
              if (
                selection.props.value !== node.value &&
                selection.props.class === node.class &&
                selection.props.parentName === node.parentName
              ) {
                node.value = selection.props.value;
                branch.Nodes.toNode.Value = selection.props.value;
              }

            activeBranch = branch;
          }
        });

        nodes.push(node);

        // finally, lets make sure that at least the first node selected if all other attempts
        // to activate a node fail.
        if (limb.Branches.length - 1 === index) {
          var found = false;
          nodes.forEach((n) => {
            if (n.active) found = true;
          });
          if (!found) {
            nodes[0].active = true;
            activeBranch = limb.Branches[0];
          }

          // Only add to the path if it's not the prepass.
          if (!isPrePass) path.push(activeBranch);
        }
      });

      // the last node in a tree will not have any limbs so we're done with
      // the recursion.  This tells the routine to continue with the recursion.
      if (activeBranch && activeBranch.Nodes.toNode.Limbs) {
        this.buildExpander(
          activeBranch.Nodes.toNode.Limbs,
          items,
          selection,
          path,
          product,
          isPrePass,
          () => {
            // Callback if needed.
          }
        );
      }

      // Build a little logic to determine whether we add a subgroup by group property.
      if (isPrePass) {
        this.AllNodes.push(nodes);

        this.Groups.forEach((group) => {
          if (limb.Branches.Group === group.id) {
            // Consolidate the grouping into a modal --> this is for dynamic groups only.
            if (this.props.config.groups.groupToModal) {
              // the first zone in the collection will become the parent zone for the others...
              group.zones.unshift(this.groupToSwatch(limb));
              // console.log(group)
            }
            // Add the expander as a child to another expander..
            else {
              group.Expanders.push(
                this.expanderItem(
                  limb.Branches.Id,
                  limb.Branches.Text,
                  limb.Branches.Order,
                  nodes,
                  path,
                  true,
                  false // even though this is a prepass, we need to tell it to build the expander item to store.
                )
              );
            }
          }
        });
      }

      // Build the expander
      // don't add hidden expanders...these are pass through entities set in the model.  And, if a group has not been
      // specificied then this is the main expander.  Otherwise, like groups need to be, well, grouped.
      if (!limb.Branches.Hidden) {
        // it's not grouped so build it.
        if (limb.Branches.Group === "") {
          items.push(
            this.expanderItem(
              limb.Branches.Id,
              limb.Branches.Text,
              limb.Branches.Order,
              nodes,
              path,
              false,
              isPrePass
            )
          );
        }

        // Ok, it is grouped, so we only want to build the first one in the first zone as a parent.
        // and match the group mapping to the incoming value.  This will set the expander title.
        this.Groups.forEach((group) => {
          //console.log(group)
          group.zones.forEach((zone, index) => {
            if (zone.name === limb.Branches.Name && index === 0) {
              // console.log(limb.Branches)
              items.push(
                this.expanderItem(
                  limb.Branches.Id,
                  //limb.Branches.Text,
                  this.Groups[parseInt(group.id)].title,
                  limb.Branches.Order,
                  nodes,
                  path,
                  false,
                  isPrePass
                )
              );
            }
          });
        });
      }
    });

    callback();
  };

  materials = (node, modelMaterials, index, title) => {
    return (
      <Materials
        key={index}
        sessionId={this.props.sessionId}
        config={this.props.config}
        rules={this.props.rules}
        title={title}
        sku={node.sku}
        layer={node.layer}
        library={node.library}
        parent={node.parentName}
        mattype={node.mattype}
        moi={node.moi}
        hiddenfilter={node.hiddenfilter}
        pi={node.pi}
        product={node.product}
        match={node.match}
        type={node.type}
        uiType={node.uiType}
        value={node.value}
        uiValue={node.uiValue}
        layerType={node.layerType}
        modelMaterials={modelMaterials}
        zIndex={node.zIndex}
        registerLibrary={this.registerLibrary.bind(this)}
        groups={this.Groups}
        useModal={this.props.config.groups.groupToModal}
        flex1={node.flex1}
        categorize={node.categorize}
        // if there's incoming data, set it.  Otherwise, send a blank array to the materials.
        history={this.props.file ? this.props.file.file_data.materials : []}
        rebuild={this.rebuild.bind(this)}
      ></Materials>
    );
  };

  handleExpand = (e) => {
    // HACK to override the max height set by the parent expander.  What happens is the sub expander doesn't recalulate the
    // parents maxHeight style property.  Ultimately, this logic needs to be incorporated into the react-sanfona expander
    // component, not an override such as this.  We're assuming also that no sub expander will ever be greater than 1000px
    // Finally, this does impact the close speed of the expander because it thinks now the maxHeight is 1000 px greater
    // than it really is.
    var doc = document.querySelectorAll(".react-sanfona-item-expanded");
    // Main Expander.
    doc[0].childNodes.forEach((node) => {
      if (node.classList.contains("react-sanfona-item-body")) {
        node.style.maxHeight = "100%";
      }
    });
  };

  groupToSwatch = (limb) => {
    let zone = {};

    zone.name = limb.Branches.Name;
    zone.moi = limb.Branches.Moi;
    zone.text = limb.Branches.Text;

    return zone;
  };

  expanderItem = (key, title, order, nodes, path, isSub, isPrePass) => {
    // determine what kind of expander item we're building.  Is it a node or a material?
    var output = [];

    // look at the rules and make sure we should be building this expander.  This acts as an override...
    var buildExpander = () => {
      /*
            if (this.props.rules && obj.nodes) {
                runInNewContext(this.props.rules, { obj });
            }
            */
      // do the rules thing here...
      // if false,
      // return false.
      return true;
    };

    let baseTitle = title;
    // optionally, add the selection to the title if configured
    if (this.props.config.expander.showSelectionOnExpander) {
      nodes.forEach((node) => {
        title = node.active ? title + " | " + node.text : title;
      });
    }

    // Make an array of all static and renderable materials to POST with the placeholder for dynamic materials.
    var modelMaterials = [];
    var parent;
    // A node can override the expander in the rules engine.  This allows us to control very specific
    /// functionality per client by using a handle on the expander.
    nodes.forEach((node, i) => {
      // this will inform the database what materials to retrieve when they're loaded dynamically...
      if (node.type === "static" || node.type === "renderable") {
        modelMaterials.push(node);
      }

      if (i === nodes.length - 1) {
        //console.log(nodes)
        nodes.forEach((node, j) => {

          //Dynamic Materials
          if (node.type === "dynamic") {

            parent = node.parentName;
            output.push(this.materials(node, modelMaterials, j, baseTitle));

            //START of grouped expander fix 3/17/22 -mg

            //console.log(node)
            //console.log(this)
            //console.log(this.NumberOfDynamicLibrariesLoaded)
            //console.log("libraries : " + this.NumberOfDynamicLibraries)
            //console.log("count : " + this.DynamicCount)
            //console.log(isPrePass)

            this.DynamicCount++; //Add a count for dynamic libraries -mg 3/17/22

            //Non grouped materials are prepass and also come through again as prepass false
            if (isPrePass) {
              this.NumberOfDynamicLibraries++; //add to libraries if prepass is true -mg 3/17/22
              this.DynamicOffsetCount++; //keep track of the number of prepass dynamics to offset the prepass false dynamics -mg 3/17/22
            }

            //identify prepass false dynamic materials -mg 3/17/22
            else if (nodes.length === 1 && nodes[0].type === "dynamic") {

              //this.DynamicCount--;
              // console.log("libraries : " + this.NumberOfDynamicLibraries)
              // console.log("count : " + this.DynamicCount)
              // console.log("Grouped Count : " + this.DynamicGroupedCount)
              // console.log("Total Count : " + (this.DynamicCount - this.DynamicGroupedCount))

              //-mg 3/17/22
              //The first material is prepass false, so no libraries are loaded yet, but the dynamic count is 1 from dynamicCount++ above
              if (this.NumberOfDynamicLibraries == 0 && this.DynamicCount == 1) {
                this.NumberOfDynamicLibraries++;
              }

              //-mg 3/17/22
              //If libraries are less than dynamicCount - offset, we need to add the library
              else if (this.NumberOfDynamicLibraries < (this.DynamicCount - this.DynamicOffsetCount)) {
                this.NumberOfDynamicLibraries++;
              }

            }

            //END of grouped expander fix 3/17/22 -mg

            // //OLD WORKING CODE before 3/17/2022

            // if (isPrePass) {
            //   this.NumberOfDynamicLibraries++;
            // }

            // //END OF OLD WORKING CODE 
          }

          //Nodes
          else if (j === nodes.length - 1) {
            //console.log(nodes)
            let obj = {};
            obj.nodes = nodes;
            obj.allNodes = this.AllNodes;
            // Apply any business rules supplied
            if (this.props.rules && obj.nodes) {
              runInNewContext(this.props.rules, { obj });
            }
            // Push to the expander...
            output.push(this.expanderNodes(nodes, i));
          }
        });
      }
    });

    const cls = "expander-nodes " + parent;

    if (!isPrePass) {
      // Define how the expander responds...these are values set in the config.
      var expanded = false; // default
      // sub-expanders may behave differently
      if (isSub && !this.props.config.groups.collapsible) {
        expanded = true;
      }
      // main expanders and/or grouped expanders
      if (!isSub) {
        expanded = false;
      }

      // disable expander flag.  Currently, the expander will be disabled only if it's a ROOT with no subs
      // and, there's a flag in the config file for configurator level customization.
      let disabled = false;
      if (
        output[0].props.style === "ROOT" &&
        output[0].props.data.undefined.length === 1 &&
        this.props.config.expander.disableRootWithOneOrNone
      )
        disabled = true;

      return buildExpander() ? (
        <AccordionItem
          bodyClassName={cls}
          disabled={disabled}
          key={key}
          title={title}
          expanded={expanded}
          order={order}
          onExpand={this.handleExpand.bind(this)}
        >
          {output}
        </AccordionItem>
      ) : null;
    }
  };

  createNode = (node) => {
    return (
      <Node
        // non-function props
        product={node.product}
        plid={node.plid}
        spid={node.spid}
        visible={node.visible}
        rules={this.props.rules}
        active={node.active}
        class={node.class}
        path={window.TBPM.BLOB_URL + "/" + this.props.config.header.guid}
        orgId={this.props.config.orgId}
        id={node.id}
        index={node.index}
        rootNode={node.rootNode}
        key={node.key}
        layer={node.layer}
        moi={node.moi}
        hiddenfilter={node.hiddenfilter}
        pi={node.pi}
        filter={node.filter}
        match={node.match}
        name={node.name}
        parentName={node.parentName}
        parentText={node.parentText}
        parentId={this.Id}
        sessionId={this.props.sessionId}
        style={this.props.config.nodes.style}
        sku={node.sku}
        flex1={node.flex1}
        flex2={node.flex2}
        flex3={node.flex3}
        src={node.src}
        srcType={node.srcType}
        infoId={node.infoId}
        text={node.text}
        type={node.type}
        uiType={node.uiType}
        value={node.value}
        uiValue={node.uiValue}
        zindex={node.zindex}
        geo={node.geo}
        mgroup={node.mgroup}
        // inherited functions
        updateHistory={this.updateHistory.bind(this)}
        rebuild={this.rebuild.bind(this)}
        update={this.props.update}
        config={this.props.config}
      ></Node>
    );
  };

  expanderNodes = (nodes, i) => {
    let output = [];
    let style = null;
    //let logic = Object.entries(this.props.logic);
    //console.log(logic)
    nodes.forEach((node) => {
      // Don't show a dynamic type...they're replaced by external sources.
      if (node.type !== "dynamic") {
        // apply class specific logic...in most cases this will not be a factor
        if (node.class === "ROOT") {
          output.push(this.createNode(node));
        }
        if (node.class === "NODE") {
          output.push(this.createNode(node));
        }
        if (node.class === "MATERIAL") {
          output.push(this.createNode(node));
        }
      }

      style = node.class;
    });

    return (
      <Groups
        key={i}
        style={style}
        data={groupReactBy(output, (output) => output.props.filter)}
      />
    );
  };

  render() {
    const cls = this.state.updating ? "expander-updating" : "expander";
    return (
      <div className={cls}>
        {this.props.io ? (
          <Accordion>
            <AccordionItem title="Configuration IO">
              <SaveFile
                file={store.getState().path.slice(-1)[0]}
                group={this.state.group}
                org={this.state.org}
                ui={this.state.ui}
                sku={this.props.sku}
                price={this.props.price}
                imageUrl={this.props.imageUrl}
              />
              <LoadFile />
            </AccordionItem>
          </Accordion>
        ) : null}
        <Accordion allowMultiple={this.props.config.expander.allowMultiple}>
          {this.state.items}
        </Accordion>
      </div>
    );
  }
}

function arrayEquals(a, b) {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  );
}

const OptionData = (props) => {
  // log the change to analytics -----------------------------------------------
  // ---------------------------------------------------------------------------
  // Set the properties...
  let item = {};
  item.logContext = "EXPANDER";
  item.logType = "OPTION";
  item.logItem = "OPTION";
  // item.sessionId = props.sessionId;
  item.org = props.config.org;
  item.parentId = props.parentId;
  item.orgId = props.config.orgId;
  item.cfgId = props.config.cfgId;
  item.clientCode = props.name;
  item.product = props.product;
  item.parent = props.parentName;
  item.title = props.parentText;
  item.text = props.text;
  return item;
};

class Groups extends React.Component {
  buildGroups = () => {
    let output = [];
    let items = Object.entries(this.props.data);
    let cls = "group-content " + this.props.style;

    items.forEach((item) => {
      // console.log(item)
      let key = item[0];
      let value = item[1];

      if (key !== "undefined") {
        output.push(
          <div key={key}>
            <div className="group-title">{key}</div>
            <div className={cls}>{value}</div>
          </div>
        );
      } else {
        output.push(
          <div key={key}>
            <div className={cls}>{value}</div>
          </div>
        );
      }
    });
    return output;
  };

  render() {
    var groups = this.buildGroups();
    return groups;
  }
}

class NodeInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      info: null,
      show: false,
    };
  }

  show = () => {
    //axios.get(ENV.api.path + "/infotag", {
    axios
      .get(window.TBPM.UIB_API_URL + "/infotag", {
        params: {
          orgId: this.props.orgId,
          infoTagId: this.props.infoTagId,
        },
      })
      .then((response) => {
        this.setState({
          info: response.data,
          show: true,
        });
      });
  };

  hide = () => {
    this.setState({
      show: false,
    });
  };

  render() {
    if (this.state.show)
      return (
        <div onClick={() => this.hide()} className="node-before info active">
          <div>
            <div className="info-tag-header">
              {this.state.info.infoTagHeader}
            </div>
            <div className="info-tag-text">{this.state.info.infoTagText}</div>
          </div>
        </div>
      );

    return <div onClick={() => this.show()} className="node-before info"></div>;
  }
}

class Node extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active: false,
      data: null,
      modalShow: false,
    };

    this.MachineCore = new MachineCore();
    this.Analytics = new Analytics(uuidv1());
    this.IO = new IO();
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.active !== this.props.active) {
      this.setState({ active: this.props.active });
    }
  };

  componentDidMount = () => {
    // this is set by the expander on startup and will mutate based
    // on the what is selected.
    this.setState({ active: this.props.active });
    // register the node as part of the history with the expander.
    if (this.props.active) {
      this.props.updateHistory(this, () => {
        // Update history callback.
      });
    }
  };

  handleClick = () => {
    if (this.props.type === "modal") {
      this.showModal(true);
    } else {
      this.Analytics.log(OptionData(this.props));
      this.props.rebuild(this);
    }
  };

  showModal = (show) => {
    this.setState({
      modalShow: show,
    });
  };

  handleModalSelection = (selection) => {
    // Update the object to include the selected value.  This will tell the expander to both update
    // the selected node AND the branch from the forest.
    let node = React.cloneElement(this, {
      value: selection.props.pantone.hex,
    });
    this.props.rebuild(node);
  };

  //Decal Selection
  handleDecalSelection = (selection) => {
    let node = React.cloneElement(this, {
      value: "{" + selection + "}",
    });
    this.props.rebuild(node);

    //console.log(node);
  };

  render() {
    if (!this.props.visible) return null;

    let uuid = uuidv1();

    // Modify the render style for active / not active.
    var cls = this.state.active
      ? "expander-node active " + this.props.class
      : "expander-node " + this.props.class;
    switch (this.props.uiType) {
      case "sicon": {
        let file = this.props.path + "/assets/icons/" + this.props.uiValue;

        // MODALS
        if (this.props.type === "modal") {
          return (
            <div className={cls}>
              <div className="icon-border">
                <div
                  data-tip
                  data-for={uuid}
                  onClick={this.handleClick.bind(this)}
                  style={{ backgroundColor: this.props.value }}
                  className="sIcon modal-icon"
                >
                  <img src={file} alt="" />
                </div>

                {this.props.config.tooltips ? (
                  <ReactTooltip
                    type={this.props.config.tooltips.type}
                    id={uuid}
                  >
                    <span>{this.props.text}</span>
                  </ReactTooltip>
                ) : (
                  <ReactTooltip id={uuid}>
                    <span>{this.props.text}</span>
                  </ReactTooltip>
                )}
              </div>

              <PantoneModal
                parent={this.props.parentName}
                layer={this.props.layer}
                show={this.state.modalShow}
                showModal={this.showModal.bind(this)}
                select={this.handleModalSelection.bind(this)}
              />
            </div>
          );
        }
        // DECAL
        else if (this.props.type === "decal") {
          return (
            <div>
              <Decal handleSelection={this.handleDecalSelection} />
            </div>
          );
        }
        // EVERYTHING ELSE
        else {
          return (
            <div className={cls}>
              <div className="icon-border">
                <div
                  data-tip
                  data-for={uuid}
                  onClick={this.handleClick.bind(this)}
                  className="sIcon"
                >
                  <img src={file} alt="" />
                </div>
              </div>

              {this.props.config.tooltips ? (
                <ReactTooltip type={this.props.config.tooltips.type} id={uuid}>
                  <span>{this.props.text}</span>
                </ReactTooltip>
              ) : (
                <ReactTooltip id={uuid}>
                  <span>{this.props.text}</span>
                </ReactTooltip>
              )}

              <div className="node-title-text">{this.props.text}</div>
            </div>
          );
        }
      }

      case "hex": {
        return (
          <div className={cls}>
            <div className="icon-border">
              <div
                data-tip
                data-for={uuid}
                onClick={this.handleClick.bind(this)}
                className="hexIcon"
                style={{ backgroundColor: this.props.uiValue }}
              />
            </div>

            {this.props.config.tooltips ? (
              <ReactTooltip type={this.props.config.tooltips.type} id={uuid}>
                <span>{this.props.text}</span>
              </ReactTooltip>
            ) : (
              <ReactTooltip id={uuid}>
                <span>{this.props.text}</span>
              </ReactTooltip>
            )}

            <div className="node-title-text">{this.props.text}</div>
          </div>
        );
      }

      default: {
        //let nodeBeforeCls = (this.props.infoId) ? "node-before info" : "node-before";

        return (
          <div className={cls}>
            {this.props.infoId ? (
              <NodeInfo
                orgId={this.props.orgId}
                infoTagId={this.props.infoId}
              />
            ) : null}
            <div className="node-content" onClick={this.handleClick.bind(this)}>
              {this.props.text}
            </div>
            <div className="node-after"></div>
          </div>
        );
      }
    }
  }
}

export default connect(null, { addHistoryStep, addPathStep, addFile })(
  Expander
);
