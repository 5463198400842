import React from "react";
import { Switch, Route } from "react-router";
import Product from "../Product/product";
import {
  AllProductsByUI,
  AllGroupingsByUI,
  AllGroupingsByUIAdmin,
} from "../Product/index";
import AllOrganizations from "../Organizations/index";
import Merchandiser from "../Merchandiser/merchandiser";
import Orbit from "../Orbit/orbit";
//import Demo from '../Demo/demo';

class Main extends React.Component {
  render() {
    return (
      <Switch>
        <Route
          path="/Merchandiser"
          render={(props) => <Merchandiser {...props} />}
        />
        <Route path="/products/:uiGUID" component={AllProductsByUI} />
        <Route
          path="/groupings/ui/:uiGUID/admin"
          component={AllGroupingsByUIAdmin}
        />
        <Route path="/groupings/ui/:uiGUID" component={AllGroupingsByUI} />
        <Route
          path="/group/:uiGUID/:groupId/file/:fileId"
          exact
          render={(props) => <Product {...props} />}
        />
        <Route
          path="/group/:uiGUID/:groupId/file/sku/:sku"
          exact
          render={(props) => (
            <Product {...props} sessionId={this.props.sessionId} />
          )}
        />
        <Route
          path="/group/:uiGUID/:groupId/share/:shareId"
          exact
          render={(props) => (
            <Product {...props} sessionId={this.props.sessionId} />
          )}
        />
        <Route
          path="/group/:uiGUID/:groupId/:admin"
          render={(props) => (
            <Product {...props} sessionId={this.props.sessionId} />
          )}
        />
        <Route
          path="/group/:uiGUID/:groupId"
          render={(props) => (
            <Product {...props} sessionId={this.props.sessionId} />
          )}
        />
        <Route exact path="/organizations" component={AllOrganizations} />
        <Route exact path="/ar" component={Orbit} />
        {/* <Route exact Path='/Demo' render={(props) => <Demo {...props} updateClient={this.props.updateClient} hostY={this.props.hostY}/>}/> */}
      </Switch>
    );
  }
}

export default Main;
