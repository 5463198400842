
// Utility function to decode incoming attributes by key and value.
export const loadAttributes = (attr, attrs) => {
    var n = "";

    // look for a match
    attrs.map(x => {
        if (attr === x.Nm) {
            n = x.Val;
        }

        return n;
    });

    return n;
}

export const sortByOrderNumber = (a, b) => {
    var x = a.props.order;
    var y = b.props.order;
    return ((x < y) ? -1 : ((x > y) ? 1 : 0));
}

export const sortByPosition = (a, b) => {
    var x = a.props.position;
    var y = b.props.position;
    return ((x < y) ? -1 : ((x > y) ? 1 : 0));
}

export const sortByStringLength = (a, b) => {
    return a.length - b.length;
}

export const pickByFirstElement = (elements) => {
    return elements[0];
}

export const sortChronologicallyByFirstWord = (a, b) => {
    return a - b;
}

export const sortChronologicallyBySecondWord = (a, b) => {
    var splitA = a.split(" ");
    var splitB = b.split(" ");
    var lastA = splitA[splitA.length - 1];
    var lastB = splitB[splitB.length - 1];
    return lastA - lastB;
}

export const sortBySecondWord = (a, b) => {
    var splitA = a.split(" ");
    var splitB = b.split(" ");
    var lastA = splitA[splitA.length - 1];
    var lastB = splitB[splitB.length - 1];

    if (lastA < lastB) return -1;
    if (lastA > lastB) return 1;
    return 0;
}

export const sortByName = (a, b) => {
    var x = a.name;
    var y = b.name;
    return ((x < y) ? -1 : ((x > y) ? 1 : 0));
}

export const groupReactBy = (arr, criteria) => {
    return arr.reduce((obj, item) => {
        // Check if the criteria is a function to run on the item or a property of it
        //var key = typeof criteria === 'function' ? criteria(item) : item[criteria];
        var key = criteria(item);
        // If the key doesn't exist yet, create it
        if (!obj.hasOwnProperty(key)) {
            obj[key] = [];
        }
        // Push the value to the object
        obj[key].push(item);
        // Return the object to the next item in the loop
        return obj;

    }, {});
};
