import React from "react";
import Zoom from "../Controls/zoom";
import axios from "axios";
//import { GridLoader } from 'react-spinners';
import { Line } from "rc-progress";
import "./spinner.css";

//import Gears from '../Gears/gears';
// You can play with this to adjust the sensitivity
// higher values make mouse less sensitive
const pixelsPerDegree = 3;
// Logging
const hrtime = require("browser-process-hrtime");

// ENVIROMENT VARIABLES
//const ENV = require('../../env');

class Spinner extends React.Component {
  constructor(props) {
    super(props);

    this.handleTouchStart = this.handleTouchStart.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);

    this.state = {
      images: [],
      dragging: false,
      panning: false,
      imageIndex: 0,
      dragStartIndex: 0,
      show: false,
      update: true,
      loading: true,
      percent: 0,
      zooming: false,
      indexedImageLoaded: false,
    };

    this.Width = 0;
    this.Height = 0;
  }

  shouldComponentUpdate = () => {
    return this.props.librariesLoaded;
  };

  componentDidUpdate = (prevProps) => {
    //reset image index to 1 to prevent image dispearing (happens when user makes a selection and spins while image is loading)
    if (isNaN(this.state.imageIndex)) {
      this.setState({
        imageIndex: 1,
      });
    }

    var doc = document.getElementById("heroImage");

    if (doc) {
      this.Width = document.getElementById("heroImage").offsetWidth;
      this.Height = document.getElementById("heroImage").offsetHeight;
    }

    if (
      this.props.images !== prevProps.images ||
      (this.state.images.length === 0 && this.props.images.length !== 0)
    ) {
      var log = {};

      //console.clear();
      //console.log(this.props.images)

      //only update images state if they exist in props (initial load bug fix)
      if (this.props.images.length !== 0) {
        this.setState(
          {
            images: this.props.images,
            indexedImageLoaded: false,
            loading: true,
          },
          () => {}
        );
      }

      this.setState(
        {
          //images: this.props.images,
          indexedImageLoaded: false,
          loading: true,
        },
        () => {
          const start = hrtime();
          // reload images --> this will cache them end update user on progress.
          let step = 100 / this.state.images.length;
          let counter = 0;

          this.state.images.forEach((image, index) => {
            var img = new Image();
            img.src = image.ImageURL;

            img.onload = () => {
              if (index === this.state.imageIndex)
                this.setState({
                  indexedImageLoaded: true,
                });

              counter++;
              if (counter === this.state.images.length && !this.props.zoomed) {
                this.setState(
                  {
                    percent: 0,
                    loading: false, //,
                    //imageIndex: this.props.imageIndex, //Commented this out to fix starting frame on 24 framed images. Don't think this is needed -MG
                  },
                  () => {
                    if (
                      this.props.config.analytics.uistats &&
                      this.props.config.analytics.uistats.enabled
                    ) {
                      log.IMAGESupdate = {};
                      log.IMAGESupdate.timeToUpdateInMilliseconds =
                        hrtime(start)[1] / 1000000;
                      log.org = this.props.config.orgId;
                      log.cfg = this.props.config.cfgId;
                      log.product = this.props.product;

                      //axios.post(ENV.api.performance, {
                      axios.post(window.TBPM.PERFORMANCE_ENDPOINT, {
                        log: log,
                      });
                    }
                  }
                );
              } else this.setState({ percent: this.state.percent + step });
            };
          });
        }
      );
    }
  };

  componentDidMount = () => {
    // Starting position.
    if (this.props.framecount === "24") {
      this.setState({
        imageIndex: this.props.config.spinner.startingIndex + 1,
        show: true,
      });
    } else {
      this.setState({
        imageIndex: this.props.config.spinner.startingIndex,
        show: true,
      });
    }

    document.addEventListener("mousemove", this.handleMouseMove, false);
    document.addEventListener("mouseup", this.handleMouseUp, false);
    document.addEventListener("touchstart", this.handleTouchStart, false);
    document.addEventListener("touchmove", this.handleTouchMove, false);
  };

  componentWillUnmount = () => {
    document.removeEventListener("mousemove", this.handleMouseMove, false);
    document.removeEventListener("mouseup", this.handleMouseUp, false);
    document.removeEventListener("touchstart", this.handleTouchStart, false);
    document.removeEventListener("touchmove", this.handleTouchMove, false);
  };

  handleMouseDown = (e) => {
    if (!this.props.zoomed) {
      e.persist();
      this.setState((state) => ({
        dragging: true,
        dragStart: e.screenX,
        dragStartIndex: state.imageIndex,
      }));
    }
  };

  handleMouseUp = () => {
    this.setState({ dragging: false });
  };

  handleTouchStart = (e) => {
    const touch = e.touches[0];
    if (!this.props.zoomed) {
      this.setState((state) => ({
        dragging: true,
        dragStart: touch.screenX,
        dragStartIndex: state.imageIndex,
      }));
    }
  };

  updateImageIndex = (currentPosition) => {
    //use props images if they exist, otherwise use state images. This fixes the initial load bug
    var populatedImages;

    if (this.props.images.length > 0)
      populatedImages = this.props.images.length;
    else populatedImages = this.state.images.length;

    let numImages = populatedImages;
    const pixelsPerImage = pixelsPerDegree * (360 / numImages);

    const { dragStart, imageIndex, dragStartIndex } = this.state;
    // pixels moved
    let dx = (currentPosition - dragStart) / pixelsPerImage;
    let index = Math.floor(dx) % numImages;

    if (index < 0) {
      index = numImages + index; //got rid of -1 -DH
    }
    index = (index + dragStartIndex) % numImages;

    if (index !== imageIndex) {
      this.setState({ imageIndex: index }, () => {
        // tell the product what frame the spinner is on
        this.props.setImageIndex(index);
      });
    }
  };

  handleMouseMove = (e) => {
    if (this.state.dragging && !this.state.loading) {
      this.updateImageIndex(e.screenX);
    }
  };

  handleTouchMove = (e) => {
    if (e.changedTouches && e.changedTouches.length) {
      const touch = e.changedTouches[0];
      if (this.state.dragging) {
        this.updateImageIndex(touch.screenX);
      }
    }
  };

  preventDragHandler = (e) => {
    e.preventDefault();
  };

  zoom = () => {
    this.props.onZoom();
    this.setState({
      zooming: !this.state.zooming,
    });
  };

  render() {
    // Show the 360.
    if (!this.props.zoomed && this.state.images) {
      return (
        <>
          <div
            className="spinner"
            id="heroImage"
            onMouseDown={this.handleMouseDown}
            onDragStart={this.preventDragHandler}
            onTouchStart={this.handleTouchStart}
          >
            {this.state.images.map((image, index) => (
              <SpinnerImage
                key={index}
                loading={this.state.loading}
                index={index}
                numberOfImages={this.state.images.length}
                imageIndex={this.state.imageIndex}
                src={image.ImageURL}
              />
            ))}
          </div>
          {this.props.config.controls.zoom ? (
            <div
              className="control-option button zoom"
              onClick={this.zoom.bind(this)}
              title="zoom"
            >
              <img
                className="control-icon"
                width="25"
                height="25"
                src="/assets/zoom.svg"
                alt=""
              />
              {this.state.zooming ? (
                <span className="zoom-active control-text">zoom</span>
              ) : (
                <span className="zoom control-text">zoom</span>
              )}
            </div>
          ) : null}
          {this.state.loading ? (
            <div id="spinner-loader">
              <Line
                percent={this.state.percent}
                strokeWidth=".25"
                strokeColor="#3FC7FA"
                strokeLinecap="butt"
                className="spinner-loader"
              />
            </div>
          ) : null}
          {this.props.config.controls.dragAndRotate && !this.state.loading ? (
            <div className="control-option rotate">
              {!this.props.zooming && !this.state.loading ? (
                <div>
                  <img
                    className="control-icon"
                    width="25"
                    height="25"
                    src="/assets/rotate.svg"
                    alt=""
                  />
                  {this.props.config.controls.dragAndRotateText}
                </div>
              ) : (
                <div>click and drag to pan...</div>
              )}
            </div>
          ) : null}
        </>
      );
    }
    // Show the zoom.
    if (this.props.zoomed && this.state.images) {
      var imageA;
      var imageB;
      // Handle single frame images (no 360)
      if (this.state.images.length === 1) {
        imageA = this.state.images[0].ImageURL;
        imageB = this.state.images[0].LargeImageURL;
      } else {
        imageA = this.state.images[this.state.imageIndex].ImageURL;
        imageB = this.state.images[this.state.imageIndex].LargeImageURL;
      }

      return (
        <>
          <Zoom
            imageA={imageA}
            imageB={imageB}
            width={this.Width}
            height={this.Height}
          />
          {this.props.config.controls.zoom ? (
            <div
              className="control-option button zoom"
              onClick={this.zoom.bind(this)}
              title="zoom"
            >
              <img
                className="control-icon"
                width="25"
                height="25"
                src="/assets/zoom.svg"
                alt=""
              />
              {this.state.zooming ? (
                <span className="zoom-active control-text">zoom</span>
              ) : (
                <span className="zoom control-text">zoom</span>
              )}
            </div>
          ) : null}
        </>
      );
    }

    return null;
  }
}

class SpinnerImage extends React.Component {
  render() {
    // Set the active image to the active image index.  Also handle the unique scenario where there might only be (1) image.
    const cls =
      this.props.index === this.props.imageIndex ||
      this.props.numberOfImages === 1
        ? "spinner-image active"
        : "spinner-image";
    const loading = this.props.loading ? " loading" : "";
    return (
      <div className={cls + loading}>
        <img src={this.props.src} alt="" />
      </div>
    );
  }
}

export default Spinner;
