import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import Items from './items';
import axios from 'axios';
import Product from '../Product/product';
// ENVIROMENT VARIABLES
//const ENV = require('../../env');

class Merchandiser extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null,
            item: 0,
            selection: null,
            path: ''
        }

        var urlParams = new URLSearchParams(window.location.search);
        // The configurator Id
        var id = urlParams.get('id');

        // Send a POST request
        axios({
            method: 'post',
           // url: ENV.api.merchandising,
            url: window.TBPM.MERCHANDISER_ENDPOINT,
            params: {
                id: id,
            }
        })
            .then(response => {
                // It's important that the first entry in the incoming JSON is the entry point.  All
                // additional entries do not rely on location, but they're individual order property.
                this.setState({
                    data: response.data,
                    path: response.data.items[0].path,
                    selection: response.data.items[0]
                })
            })
    }

    select = (selection) => {
        this.setState({
            item: selection.props.item.id,
            path: selection.props.item.path,
            selection: selection.props.item
        });
    }

    render() {

        // Make sure the data's loaded...
        if (!this.state.data) return null;

        // This is the leaf, reroute to the product page..
        // The route is looking specifically for a saved file through the config UI function.  This defines the 
        // entry point for a leaf.
        let path = '/group/:uiGUID/:groupId/file/:fileId';

        if (this.state.selection.isLeaf) return (
            <Router>
                <Route path={path} render={(props) =>
                    <Product {...props} updateClient={this.props.updateClient} hostY={this.props.hostY} />} />
            </Router>
        )

        return (
            <div>
                <div className="merchandiser-title">Choose a {this.state.path.split('/')[1]}</div>
                <Router>
                    <Route path={this.state.path}>
                        <Items
                            select={this.select.bind(this)}
                            items={
                                this.state.data.items
                                    .filter(item =>
                                        this.state.data.items[this.state.item].children.ids
                                            .includes(item.id))
                                    .sort((a, b) => {
                                        return a.order - b.order;
                                    })
                            }
                        />
                    </Route>
                </Router>
            </div>)
    }
}

export default Merchandiser;