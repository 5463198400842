import React from "react";
import IO from "../IO/io";
import axios from "axios";
import Slab from "../Slab/slab";
import { Root } from "../App/forest";
import { Material } from "../Libraries/material";
import MachineCoreAPI from "../MachineCore/machinecoreAPI";
import { Grid } from "svg-loaders-react";
import queryString from "query-string";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { GridLoader } from "react-spinners";

//import ErrorBoundary from '../ErrorBoundary/errorboundary';
// STYLE
import "./product.css";
// REDUX
import store from "../../Redux/store";

import Analytics from "../../classes/analytics";

// ENVIROMENT VARIABLES
//const ENV = require('../../env');

import getSkuArray from "../../logic/sku";

const { v1: uuidv1 } = require("uuid");
var Woopra = require("woopra");
// Performance Logging
const hrtime = require("browser-process-hrtime");

// MOBILE DETECTION
var MobileDetect = require("mobile-detect");
var mobileDetect = new MobileDetect(window.navigator.userAgent);

// ANALYTICS
let logId = uuidv1();
let prevSku = [];
let productObj = {};

class Product extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dataLoading: true,
      configLoading: true,
      images: [],
      // imageIndex is the currently selected image.  This is stored as an index and useful
      // in other components that require the current view.  This value can be overriden by
      // the spinnerStartingIndex value in the config file.
      imageIndex: 0,
      pricingData: null,
      product: null,
      sku: [], //sku: null,
      materials: [],
      data: [],
      title: "",
      swatches: {},
      //stylePath: ENV.blob.path + this.props.match.params.uiGUID + "/style.css?" + new Date().getTime(),
      stylePath:
        window.TBPM.BLOB_URL +
        "/" +
        this.props.match.params.uiGUID +
        "/style.css?" +
        new Date().getTime(),
      //  rulesPath: ENV.blob.path + this.props.match.params.uiGUID + "/rules.js?" + new Date().getTime(),
      //scriptPath: ENV.blob.path + this.props.match.params.uiGUID + "/script.js?" + new Date().getTime(),
      scriptPath:
        window.TBPM.BLOB_URL +
        "/" +
        this.props.match.params.uiGUID +
        "/script.js?" +
        new Date().getTime(),
      stylesheets: [],
      config: null,
      rules: null,
      isDefault: true,
      host: "",
      downloading: false,
      downloadType: null,
      downloadData: null,
      io: false,
      file: null,
      zooming: false,
      showModal: false,
      librariesLoaded: false,
      init: true,
    };
    this.IO = new IO();
    this.mCore = new MachineCoreAPI();
    this.woopra = null;
    this.compareArray = this.compareArray.bind(this);
  }

  componentDidMount = () => {
    // Set to admin mode.  This will allow the admins to save file configurations for both entry point and external functions.
    this.setState({
      io: this.props.match.params.admin === "io" ? true : false,
    });

    // file/fileId --> this tells the application to load a specific file as an entrypoint.
    let fileId = this.props.match.params.fileId;
    let shareId = this.props.match.params.shareId; //ADDED BY MT FOR SHARE FUNCTIONALITY - 2019.08.14
    let sku = this.props.match.params.sku;
    // load search strings from the incoming URL
    let searchValues = queryString.parse(this.props.location.search);

    if (fileId) {
      axios
        .get(window.TBPM.UIB_API_URL + "/file/load/" + fileId)
        .then((res) => {
          this.setState({
            file: res.data,
            host: searchValues.host,
          });
        });
    }

    if (sku) {
      axios
        .get(window.TBPM.UIB_API_URL + "/file/load/sku/" + sku)
        .then((res) => {
          //console.log(res)
          this.setState({
            file: res.data,
            host: searchValues.host,
          });
        });
    }

    //ADDED BY MT FOR SHARE FUNCTIONALITY - 2019.08.14
    if (shareId) {
      axios
        .get(window.TBPM.UIB_API_URL + "/share/load/" + shareId)
        .then((res) => {
          this.setState({
            file: res.data,
            host: searchValues.host,
          });
        });
    }

    const start = hrtime();
    var log = {};
    //axios.get(ENV.api.modeldata + this.props.match.params.uiGUID + "/" + this.props.match.params.productGUID)
    // axios.get(ENV.service.expanders + ENV.api.grouping + this.props.match.params.groupId)

    // get grouping
    axios
      .get(
        window.TBPM.GROUPING_ENDPOINT + "/" + this.props.match.params.groupId
      )
      .then((url) => {
        axios
          .get(url.data.dataUrl)
          .then((data) => {
            this.setState({ data });

            const delta = hrtime(start);
            log.JSONinit = {};
            log.JSONinit.timeToLoadInMilliseconds = delta[1] / 1000000;

            this.setState({
              //  data: data,
              host: searchValues.host,
            });
          })

          .then(() => {
            const start = hrtime();
            // this.IO.getLogic(ENV.blob.path + this.props.match.params.uiGUID + '/rules.js?cachebuster=' + new Date().getTime())
            //     axios.get(ENV.blob.path + this.props.match.params.uiGUID + '/rules.js?cachebuster=' + new Date().getTime())
            axios
              .get(
                window.TBPM.BLOB_URL +
                  "/" +
                  this.props.match.params.uiGUID +
                  "/rules.js?cachebuster=" +
                  new Date().getTime()
              )
              .then((rules) => {
                // console.log(rules.data)
                const delta = hrtime(start);
                log.RULESinit = {};
                log.RULESinit.timeToLoadInMilliseconds = delta[1] / 1000000;
                this.setState({ rules: rules.data });
              });
          })

          .then(() => {
            const start = hrtime();
            axios
              .get(
                window.TBPM.CONFIG_ENDPOINT +
                  "/" +
                  this.props.match.params.uiGUID
              )
              .then((config) => {
                // only log performance data if enabled in config
                if (
                  config.data.analytics.uistats &&
                  config.data.analytics.uistats.enabled
                ) {
                  const delta = hrtime(start);
                  log.CONFIGinit = {};
                  log.CONFIGinit.timeToLoadInMilliseconds = delta[1] / 1000000;
                  log.org = config.data.orgId;
                  log.cfg = config.data.cfgId;
                  log.product = this.state.data.data[0].grouping_name;
                  axios.post(window.TBPM.PERFORMANCE_ENDPOINT, {
                    log: log,
                  });
                }

                this.setState({ config: config.data }, () => {
                  // Style data
                  const head = document.getElementsByTagName("head")[0];
                  // Load external add ins..
                  config.data.header.stylesheets.forEach((style) => {
                    const link = document.createElement("link");
                    link.rel = "stylesheet";
                    link.type = "text/css";
                    link.href = style;
                    link.crossorigin = "anonymous";
                    head.appendChild(link);
                  });

                  const link2 = document.createElement("link");
                  link2.rel = "stylesheet";
                  link2.type = "text/css";
                  link2.href = this.state.stylePath;
                  head.appendChild(link2);

                  // WOOPRA TRACKING
                  if (config.data.analytics.woopra) {
                    this.woopra = new Woopra(config.data.analytics.woopra.id);
                    this.woopra.config({
                      domain: config.data.analytics.woopra.id,
                    });
                  }

                  // starting image index
                  if (config.data.spinner.startingIndex)
                    this.setState({
                      imageIndex: config.data.spinner.startingIndex,
                    });
                });
              });
          });
      });
    this.setState({
      isDefault: false,
    });
  };

  update = (libs, finishedLoading, callback) => {
    let _path = store.getState().path.slice(-1)[0];
    // console.log(_path);
    let materialCount = 0;

    if (_path)
      _path.forEach((p) => {
        // keep track of how many materials are in the path...this should equal the total number of libraries
        // available.
        if (p instanceof Material) {
          materialCount++;
        }

        if (p instanceof Root) {
          this.setState(
            {
              title: p.Text,
              product: p.Product,
            },
            () => {
              this.IO.getImages(
                this.mCore.addNodes(this.state.config, this.state.rules),
                this.state.config
              ).then((images) => {
                // Remove any images that are omitted in the configuration.
                if (
                  this.state.config.frames.omitFromRotate.length > 0 &&
                  images &&
                  images.length <= 13 //Do not hide if more than 13 frames
                ) {
                  images.splice(this.state.config.frames.omitFromRotate, 1);
                }

                // only set the images if the material count is equal to the number of libraries.
                // if it's not, it will prematurarely display unloaded images...
                //console.log(finishedLoading);
                this.setState(
                  {
                    images: materialCount === libs ? images : [],
                    librariesLoaded: finishedLoading,
                  },
                  () => {
                    productObj = this.mCore.addNodes(
                      this.state.config,
                      this.state
                    );

                    // get sku object from logic
                    var skuObj = getSkuArray(productObj);

                    // if sku is valid and pricing disabled
                    // then log product render without pricing
                    if (
                      skuObj.validSku &&
                      !this.state.config.pricer.pricingEnabled
                    ) {
                      // assign a new log ID if sku array changes between renders
                      // this prevents dupe logging by overwriting the dupe in the DB with same log ID
                      if (!this.compareArray(prevSku, skuObj.skuArray))
                        logId = uuidv1();
                      this.Analytics = new Analytics(logId);
                      this.Analytics.logProductRender(
                        skuObj,
                        this.props.match.params.groupId,
                        this.state.config,
                        productObj,
                        null
                      );
                      // store prev sku array for comparison next time around
                      prevSku = skuObj.skuArray;
                    }
                    // if sku is valid and pricing enabled
                    // then log product render after pricing returns
                    if (skuObj.validSku && this.state.config.pricer.enabled) {
                      // Pricing
                      this.IO.getPrice(productObj, this.state.config).then(
                        (data) => {
                          let skuArr = [];
                          data.prices.forEach((p) => {
                            skuArr.push(p.sku);
                          });
                          this.setState(
                            {
                              pricingData: data,
                              // sku comes back with the price
                              //sku: data.sku
                              //sku: data.prices[0].sku
                              sku: skuArr,
                            },
                            () => {
                              // assign a new log ID if sku array changes between renders
                              // this prevents dupe logging by overwriting the dupe in the DB with same log ID
                              if (!this.compareArray(prevSku, skuObj.skuArray))
                                logId = uuidv1();
                              this.Analytics = new Analytics(logId);
                              this.Analytics.logProductRender(
                                skuObj,
                                this.props.match.params.groupId,
                                this.state.config,
                                productObj,
                                data
                              );
                              // store prev sku array for comparison next time around
                              prevSku = skuObj.skuArray;
                              try {
                                // WOOPRA track skus
                                let host = this.state.host;
                                let init = this.state.init;
                                let sku = "";

                                //loop through sku array and combine into a string with comma delim
                                data.prices.forEach((s) => {
                                  if (sku === "") sku = s.sku;
                                  else sku += "," + s.sku;
                                });
                                //only track if sku is accurate / pricing was successful and host is not undefined
                                if (
                                  this.state.pricingData.success &&
                                  host !== undefined
                                ) {
                                  this.woopra
                                    .identify("skuTracker")
                                    .track("configurator-sku-view", {
                                      sku,
                                      init,
                                      application: host,
                                    });
                                }
                              } catch {}
                              // finally, set the init to false
                              this.setState(
                                {
                                  init: false,
                                },
                                () => {
                                  callback();
                                }
                              );
                            }
                          );
                        }
                      );
                    }
                  }
                );
              });
            }
          );
        }
      });
  };

  compareArray = (arr1, arr2) => {
    // console.log(arr1);
    // console.log(arr2);
    return JSON.stringify(arr1) === JSON.stringify(arr2) ? true : false;
  };

  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  onToggleDownload = (type, data) => {
    this.setState({
      downloading: !this.state.downloading,
      downloadType: type,
    });

    //try catch, for clients that do not use woopra
    try {
      // WOOPRA track downloads
      let sku = "";
      let host = this.state.host;
      let type = this.state.downloadType;

      //loop through sku array and combine into a string with comma delim
      this.state.sku.forEach((s) => {
        if (sku === "") sku = s;
        else sku += "," + s;
      });

      //do not track if null and host is undefined
      if (type !== null && host !== undefined) {
        this.woopra
          .identify("downloadTracker")
          .track("configurator-downloadbutton", {
            sku,
            type,
            application: host,
          });
      }
    } catch {}

    // invoke a download...
    if (data)
      this.setState(
        {
          downloadData: data,
        },
        () => {
          this.toggleModal();
        }
      );
  };

  onToggleZoom = () => {
    this.setState({ zooming: !this.state.zooming });
  };

  setImageIndex = (index) => {
    this.setState({ imageIndex: index });
  };

  render() {
    //   console.log(productObj);
    if (!this.state.config || !this.state.data || !this.state.images) {
      const override = `
            position:absolute;
            display: block;
            margin: 0 auto;
            top:50%;
            left:50%;
            margin-left:-30px;
            border-color: red;
        `;
      return (
        <GridLoader
          css={override}
          sizeUnit={"px"}
          size={30}
          color={"#eaeaea"}
          loading={true}
        />
      );
    }

    const cls = this.state.downloading ? "processing-overlay" : null;
    // JSON data
    const data = this.state.data.data;

    const hostY = this.props.hostY;

    return (
      <div id="product">
        {
          // If processing, add an overlay to show the user something is going on...
          this.state.downloading ? (
            <Processing
              processType={this.state.downloadType}
              downloading={this.state.downloading}
            />
          ) : null
        }
        <div className={cls}></div>
        <Slab
          data={data}
          mobileDevice={mobileDetect.phone()} // will be null if it's not a phone.
          group={this.props.match.params.groupId}
          config={this.state.config}
          rules={this.state.rules}
          slab={this.state.config.product.slab}
          images={this.state.images}
          setImageIndex={this.setImageIndex.bind(this)}
          imageIndex={this.state.imageIndex}
          update={this.update.bind(this)}
          title={this.state.title}
          swatches={this.state.swatches}
          sessionId={this.props.sessionId}
          pricingData={this.state.pricingData}
          host={this.state.host}
          sku={this.state.sku}
          product={this.state.product}
          io={this.state.io}
          file={this.state.file}
          onDownload={this.onToggleDownload}
          librariesLoaded={this.state.librariesLoaded}
          onZoom={this.onToggleZoom.bind(this)}
          zoomed={this.state.zooming}
          hidePrice={productObj.hideprice}
          productObj={productObj}
        />
        <DownloadModal
          type={this.state.downloadType}
          show={this.state.showModal}
          onHide={this.toggleModal.bind(this)}
          downloaddata={this.state.downloadData}
          voffset={hostY}
        />
      </div>
    );
  }
}

function DownloadModal(props) {
  /*
    let modal = document.getElementsByClassName("modal")[0];
    if(modal)
        modal.style.top = props.voffset + "px";
        */
  return (
    <Modal {...props} show={props.show}>
      <Modal.Header>YOUR {props.type} IS READY FOR DOWNLOAD</Modal.Header>
      <Modal.Footer>
        {" "}
        <Button
          onClick={props.onHide}
          href={props.downloaddata}
          target="_blank"
        >
          Download
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

class Processing extends React.Component {
  render() {
    const cls = this.props.downloading
      ? "processing-status show col-12 text-center"
      : "processing-status col-12 text-center";
    return (
      <div className={cls}>
        <Grid fill="#333333" />
        <div className="pulse"></div>
      </div>
    );
  }
}

export default Product;
