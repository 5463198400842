// import React from "react";
// import "./orbit.css";
// import * as THREE from 'three';
// import {OBJLoader} from 'three/examples/jsm/loaders/OBJLoader.js'
// import { faThList } from "@fortawesome/free-solid-svg-icons";
// const scene = new THREE.Scene();

// //https://threejs.org/docs/index.html#manual/en/introduction/Loading-3D-models
// //https://threejs.org/docs/#examples/en/loaders/OBJLoader

// export default class Orbit extends React.Component {

//     componentDidMount = () => {

//         var loader = new OBJLoader();

//         //loader.load('/Users/marcogarcia/Desktop/J30CLV3_root-outsidearms-ofan_fram1.obj',
//         loader.load('http://machinecore.thebigpicturemachine.com/Ziing3Server/Ziing3.aspx?req=obj\u0026Sedia-Auditorium-J30CLV3\u0026obj=root-outsidearms-ofan_fram1',
//         function(object){
//             scene.add(object);
//             //console.log(object)
//         })
// //console.log(loader)

//       };

//     render() {
//       return (
//         <div className="orbit-button" title="orbit">
//           <div> 
//               Hello
//           </div>
//         </div>
//       );
//     }
//   }


import React from "react";
import "./orbit.css";

class Orbit extends React.Component {

  render() {
    return (
      <div>
        <a href="https://tbpmdatastore.blob.core.windows.net/assets/hon/nodes/astir/ar/astir_haslb3.usdz">Astir USDZ</a>
        <br></br>
        <a href="https://tbpmdatastore.blob.core.windows.net/assets/hon/nodes/astir/ar/astir_gltf/astir_haslb3.gltf">Astir glTF</a>
        <br></br>
        <a href="intent://arvr.google.com/scene-viewer/1.0?file=https://tbpmdatastore.blob.core.windows.net/assets/hon/nodes/astir/ar/astir_gltf/astir_haslb3.gltf#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=https://developers.google.com/ar;end;">Astir Formated glTF</a>
      </div>
    );
  }
}

export default Orbit;
