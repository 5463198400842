import axios from "axios";
import ls from "local-storage";
const { v1: uuidv1 } = require("uuid");

// ENVIROMENT VARIABLES
//const ENV = require('../env');

class Analytics {
  constructor(logId) {
    this.logId = logId;
    //    this.skuObj = skuObj;
    //    this.productObj = productObj;
    //    this.pricingObj = pricingObj;
  }

  /*     log = (items) => {       
        //axios.post(ENV.api.analytics, {
        axios.post(window.TBPM.ANALYTICS_ENDPOINT, {
            data: items,
            id: this.id
        });
    } */

  log = (items) => {
    // set ID to track unique user across all configurators
    let uid = ls.get("tbpmUserId");
    if (!uid) {
      uid = uuidv1();
      ls.set("tbpmUserId", uid);
    }
    items.cfgSessionId = ls.get("tbpmCfgSessionId");
    items.logId = this.logId;
    items.uid = uid;
    axios.post(window.TBPM.UIHISTORY_ENDPOINT, items);
  };

  logProductRender = (
    skuObj,
    groupingId,
    configObj,
    productObj,
    pricingObj
  ) => {
    let uid = ls.get("tbpmUserId");
    if (!uid) {
      uid = uuidv1();
      ls.set("tbpmUserId", uid);
    }
    ls.set("tbpmPlid", productObj.plid);
    ls.set("tbpmSpid", productObj.spid);

    // console.log("logging render");
    // console.log(skuObj);
    // console.log(groupingId);
    // console.log(configObj);
    // console.log(productObj);
    // console.log(pricingObj);

    // let product = {};
    // let root = {};
    // let nodes = [];
    // let materials = [];
    // let doLog = true;

    let logObj = {};
    logObj.logId = this.logId;
    logObj.type = "SKU";
    logObj.uid = uid;
    logObj.sessionId = null;
    logObj.org = configObj.org;
    logObj.orgId = configObj.orgId;
    logObj.cfgId = configObj.cfgId;
    logObj.productLine = productObj.product;
    logObj.plid = productObj.plid;
    logObj.spid = productObj.spid;
    logObj.sku = skuObj.skuArray;
    logObj.groupingId = groupingId;
    logObj.matsVersion = configObj.apiParams.matsVersion;
    logObj.pricingVersion = configObj.apiParams.pricingVersion;
    logObj.pricing = pricingObj;
    logObj.productObj = productObj;
    axios.post(window.TBPM.PRODUCTVIEW_ENDPOINT, logObj);
  };
}

export default Analytics;
