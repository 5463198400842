import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareFull as faSquareUnchecked } from '@fortawesome/pro-light-svg-icons';
import { faSquareFull as faSquareChecked } from '@fortawesome/pro-solid-svg-icons';
import './checkbox.css';

class Checkbox extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            checked: false
        }
    }

    componentDidMount = () => {
        this.setState({
            checked: this.props.checked
        })
    }

    componentDidUpdate = (prevProps) => {
        if(prevProps.selected !== this.props.selected){
            this.setState({
                checked: this.props.selected
            })
        }
    }

    toggle() {
        this.setState({ checked: !this.state.checked }, () => {
            this.props.toggle(this.state.checked);
        });
    }

    render() {
        if (this.state.checked)
            return (<div className="checkbox" onClick={this.toggle.bind(this)}><FontAwesomeIcon icon={faSquareChecked} size="1x" /></div>);

        return (<div className="checkbox" onClick={this.toggle.bind(this)}><FontAwesomeIcon icon={faSquareUnchecked} size="1x" /></div>);
    }
}

export default Checkbox;